<template>
  <div class="modal-register-cdb">
    <div class="content-register-cdb" v-if="step === 0" :style="{'padding': screen > 500 ? '40px 60px !important' : '8px !important'}">
      <span class="material-symbols-outlined close-icon-small-register" @click="closeModalRegister()">close</span>
      <div class="header-register-cdb">
        <h4 class="name-register-cdb">{{ name }}</h4>
        <h2 class="title-register-cdb">RESPONSÁVEL PELO CIRCO</h2>
      </div>
      <div class="form-register-cdb">
        <input type="text" class="form-register-input" v-model="formRegister.email" placeholder="Digite seu e-mail">
        <input type="password" class="form-register-input" v-model="formRegister.password" placeholder="Senha" @keyup.enter="nextStep()">
      </div>
      <div class="send-info-register">
        <button class="btn-send-register" @click="nextStep()">Entrar</button>
        <button class="btn-send-register" @click="showModalRegister = true">Cadastrar</button>
        <button class="btn-send-register" @click="$root.$emit('open-modal-forgot-password')">Esqueci minha senha</button>
      </div>
      <p class="label-info-register">Mantenha sua localização SEMPRE atualizada!!</p>
    </div>
    <div class="content-localization-cdb" v-if="step === 1" :style="{'padding': screen > 500 ? '40px 60px !important' : '8px !important'}">
      <span class="material-symbols-outlined close-icon-small-register" @click="closeModalRegister()">close</span>
      <div class="header-localization-cdb">
        <h4 class="name-localization-cdb">{{ name }}</h4>
        <h2 class="title-localization-cdb">NOVA LOCALIZAÇÃO</h2>
      </div>
      <div class="form-localization-cdb">
        <div class="state-and-city-localization">
          <v-select
            class="input-state-localization-cdb"
            :options="optionsStates"
            label="uf"
            placeholder="UF"
            :reduce="name => name.uf"
            v-model="formLocalization.state"
            @input="setState(formLocalization.state)"
          >
          </v-select>
          <v-select
            class="input-city-localization-cdb"
            :options="optionsCitys"
            :disabled="!showInputCity"
            label="city"
            placeholder="Cidade"
            :reduce="name => name.city"
            v-model="formLocalization.city"
          >
          </v-select>
        </div>
        <input type="text" class="form-localization-input" v-model="formLocalization.address" placeholder="Endereço de referência">
        <input type="text" class="form-localization-input" v-model="formLocalization.neighborhood" placeholder="Bairro">
        <input type="text" class="form-localization-input" v-model="formLocalization.reference" placeholder="Detalhes/Referência">
        <div class="period-localization">
          <p class="title-period">Período de permanência:</p>
          <div class="date-pickers-cdb">
            <Datepicker
              input-class="input-date-cdb"
              calendar-class="calendar-date-cdb-initial"
              v-model="dateInitial"
              :clear-button="false"
              :language="ptBR"
              placeholder="Inicio"
              :bootstrap-styling="true"
            />
            <p class="title-period">até</p>
            <Datepicker
              input-class="input-date-cdb"
              calendar-class="calendar-date-cdb-final"
              v-model="dateFinal"
              :clear-button="false"
              :language="ptBR"
              placeholder="Final"
              :bootstrap-styling="true"
            />
          </div>
        </div>
      </div>
      <div class="send-info-localization">
        <button class="btn-send-localization" @click="sendInfos()">Enviar</button>
        <button class="btn-localization-cdb" @click="updateInfo()">Atualizar outras informações</button>
      </div>
      <p class="label-info-register">Mantenha sua localização SEMPRE atualizada!!</p>
    </div>
    <div class="content-others-cdb" v-if="step === 2" :style="{'padding': screen > 500 ? '40px 60px !important' : '8px !important'}">
      <span class="material-symbols-outlined close-icon-small-register" @click="closeModalRegister()">close</span>
      <div class="header-others-cdb">
        <h4 class="name-others-cdb">{{ name }}</h4>
        <h2 class="title-others-cdb">ATUALIZE AS INFORMAÇÕES SUBSTITUINDO AS EXISTENTES</h2>
      </div>
      <div class="form-others-cdb">
        <input type="text" class="form-others-input" v-model="formRegister.nome" placeholder="Nome">
        <input type="text" class="form-others-input" v-model="formRegister.razao_social" placeholder="Razão Social">
        <input type="text" class="form-others-input" v-model="formRegister.site" placeholder="Site">
        <input type="text" class="form-others-input" v-model="formRegister.telefone_equipamento" placeholder="Telefone">
        <input type="text" class="form-others-input" v-model="formRegister.email_equipamento" placeholder="E-mail">
        <input type="text" class="form-others-input" v-model="formRegister.whatsapp" placeholder="Whatsapp">
        <input type="text" class="form-others-input" v-model="formRegister.instagran" placeholder="Instagram">
        <input type="text" class="form-others-input" v-model="formRegister.facebook" placeholder="Facebook">
        <input type="text" class="form-others-input" v-model="formRegister.link_canal_youtube" placeholder="Youtube">
        <input type="text" class="form-others-input" v-model="formRegister.link_tiktok" placeholder="Tiktok">
        <input type="text" class="form-others-input" v-model="formRegister.link_video1" placeholder="Vídeo 1">
        <input type="text" class="form-others-input" v-model="formRegister.link_video2" placeholder="Vídeo 2">
        <input type="text" class="form-others-input" v-model="formRegister.link_ingresso_online" placeholder="Link ingresso online">
        <input type="text" class="form-others-input" v-model="formRegister.qtde_integrantes" placeholder="Quantidade de integrantes">
        <input type="text" class="form-others-input" v-model="formRegister.responsavel" placeholder="Nome responsável pelo circo">
         <input type="text" class="form-others-input" v-model="formRegister.cpf_cnpj_responsavel" placeholder="CPF/CNPJ responsável pelo circo">
        <input type="text" class="form-others-input" v-model="formRegister.email_responsavel" placeholder="E-mail responsável">
        <input type="password" class="form-others-input" v-model="formRegister.senha_responsavel" placeholder="Senha responsável, não informar mantém a mesma">
        <input type="text" class="form-others-input" v-model="formRegister.celular_responsavel" placeholder="Celular responsável">
        <input type="text" class="form-others-input" v-model="formRegister.telefone_fixo_responsavel" placeholder="Telefone fixo responsável">
        <input type="text" class="form-others-input" v-model="formRegister.cep" placeholder="CEP da sede">
        <input type="text" class="form-others-input" v-model="formRegister.endereco" placeholder="Endereço da sede do circo">
        <input type="text" class="form-others-input"  v-model="formRegister.cidade" placeholder="Cidade da sede do circo">
        <input type="text" class="form-others-input"  v-model="formRegister.estado" placeholder="Estado da sede do circo">
        <input type="text" class="form-others-input"  v-model="formRegister.banco" placeholder="Banco">
        <input type="text" class="form-others-input"  v-model="formRegister.agencia" placeholder="Agência">
        <input type="text" class="form-others-input"  v-model="formRegister.conta" placeholder="Conta">
        <input type="text" class="form-others-input"  v-model="formRegister.pix" placeholder="PIX">
        <input type="text" class="form-others-input"  v-model="formRegister.detalhes" placeholder="Detalhes">
      </div>
      <div class="send-info-others">
        <button class="btn-send-others" @click="sendOthersInfos()">Enviar</button>
        <p class="title-suport">Falar com o suporte: 11 94221 2107</p>
        <p class="label-info-register">Mantenha sua localização SEMPRE atualizada!!</p>
      </div>
    </div>
    <ModalRegisterCirco v-if="showModalRegister" :idCirco="id" :name="name" @close-modal-register="showModalRegister = false" />
  </div>
</template>

<script>
import { VueSelect } from "vue-select"
import { cdbService } from "@/components/common/services/cdb_service"
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import moment from 'moment'
import ModalRegisterCirco from './ModalRegisterCirco.vue';

export default {
  name: 'ModalRegisterCdb',
  components: {
    VueSelect,
    Datepicker,
    ModalRegisterCirco
  },
  props: ['name','id'],
  data () {
    return {
      showModalRegister: false,
      dateInitial: '',
      dateFinal: '',
      ptBR: ptBR,
      step: 0,
      showModal: false,
      nameCirco: '',
      optionsStates: [],
      stateSelected: null,
      optionsCitys: [],
      citySelected: null,
      showInputCity: false,
      formRegister: {
        name: '',
        email: '',
        cpf_cnpj: '',
        phone: '',
        password: '',
        confirm_password: '',
        confirm_email: '',
        id:''
      },
      formLocalization: {
        id:'',
        state: '',
        city: '',
        address: '',
        neighborhood: '',
        reference: '',
        period: {
          initial: '',
          final: ''
        }
      }
    }
  },
  async mounted () {
    const states = await cdbService.getState()

    this.optionsStates = states.map(state => { return { uf: state.sigla, name: state.nome } })
    this.optionsStates.sort((a, b) => {
      if(a.uf < b.uf) { return -1 }
      if(a.uf > b.uf) { return 1 }
    return 0
    })

  },
  computed: {
    screen () { return screen.availWidth }
  },
  methods: {
    formatDates () {
      this.dateInitial = moment(this.formLocalization.period.initial, 'DD/MM/YYYY').format('ddd MMM DD YYYY')
      this.dateFinal = moment(this.formLocalization.period.final, 'DD/MM/YYYY').format('ddd MMM DD YYYY')
    },
    formatDateToSave (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async setState (uf) {
      const citys = await cdbService.getCity(uf)
      this.optionsCitys = citys.map(c => { return { city: c.nome } })
      this.optionsCitys.length > 0 ? this.showInputCity = true : false
    },
    registerUser () {
      this.step = 4
    },
    nextStep () {
      //fazer login 
       this.formRegister.id=this.id;
       if(this.formRegister.email == '' || this.formRegister.password == '') {
        this.$swal({
          title: "É necessário informar os dados de acesso",
          type: "error",
          customClass: 'swal-alert-to-cdb',
          background: '#f4e9da',
          showConfirmButton: true,
          timer: 4000
        })
      } else {
       
        cdbService.siginCirco(this.formRegister).then(
          response => {
            if(response.result==true){
              this.formRegister=response.form;
              this.formLocalization.id=response.form.id;
              this.formLocalization.state=response.form.localizacao_estado;
              this.setState(this.formLocalization.state)
              this.formLocalization.city=response.form.localizacao_cidade;
              this.formLocalization.address=response.form.localizacao;
              this.formLocalization.neighborhood=response.form.localizacao_bairro;
              this.formLocalization.reference=response.form.localizacao_referencia;
              this.formLocalization.period.initial=response.form.localizacao_data_inicio;
              this.formLocalization.period.final=response.form.localizacao_data_fim;

              // fix para alterar as datas vindas do banco com 01/01/1900, para a data atual.

              if (response.form.localizacao_data_inicio === '01/01/1900') {
                this.formLocalization.period.initial = moment().format('DD/MM/YYYY')
              }

              if (response.form.localizacao_data_fim === '01/01/1900') {
                this.formLocalization.period.final = moment().format('DD/MM/YYYY')
              }

              this.$swal({
                title: "Bem vindo, revise  as informações e clique em enviar.",
                type: "success",
                customClass: 'swal-alert-to-cdb',
                background: '#f4e9da',
                showConfirmButton: false,
                timer: 4000,
              });
              this.step++
              this.formatDates()
           }else{
              this.$swal({
                title: "Não foi possível gravar com os dados informados, tente novamente!",
                type: "error",
                customClass: 'swal-alert-to-cdb',
                background: '#f4e9da',
                showConfirmButton: false,
                timer: 4000,
              })
           }
          },
          error => {
            this.$swal({
              title: "Ocorreu um erro, tente novamente",
              type: "error",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          }
        )
      }
     
    },
    sendInfos () {
      this.formLocalization.id=this.id;
      this.formLocalization.period.initial = this.formatDateToSave(this.dateInitial)
      this.formLocalization.period.final = this.formatDateToSave(this.dateFinal)

      cdbService.sendCircoLocalizacao(this.formLocalization).then(
        response => {
          if(response.result==true){
            

            this.$swal({
              title: "Obrigado, registramos sua solicitação e está em análise para aprovação.",
              type: "success",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            });
            setTimeout(() => { this.$root.$emit('close-modal-register') }, 4000)
          }else{
            this.$swal({
              title: "Não foi possível gravar os dados",
              type: "error",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          }
        },
        error => {
          this.$swal({
            title: "Ocorreu um erro, tente novamente",
            type: "error",
            customClass: 'swal-alert-to-cdb',
            background: '#f4e9da',
            showConfirmButton: false,
            timer: 4000,
          })
        }
      )
    },
    updateInfo () {
      this.step++
    },
    sendOthersInfos () {
      
      this.formRegister.id=this.id;
            cdbService.sendCircOutrasinformacoes(this.formRegister).then(
          response => {
           if(response.result==true){
              
              this.$swal({
                title: "Obrigado, registramos sua solicitação e está em análise para aprovação.",
                type: "success",
                customClass: 'swal-alert-to-cdb',
                background: '#f4e9da',
                showConfirmButton: false,
                timer: 4000,
              });
              this.$root.$emit('close-modal-register')
              this.step = 0
           }else{
              this.$swal({
                title: "Não foi possível gravar os dados",
                type: "error",
                customClass: 'swal-alert-to-cdb',
                background: '#f4e9da',
                showConfirmButton: false,
                timer: 4000,
              })
           }
          },
          error => {
            this.$swal({
              title: "Ocorreu um erro, tente novamente",
              type: "error",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          }
        )
     
    },
    closeModalRegister () {
      this.$root.$emit('close-modal-register')
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap');
.modal-register-cdb {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42424280;
  .content-localization-cdb {
    font-family: 'Alegreya Sans', sans-serif;
    padding: 40px 60px !important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    position: relative;
    z-index: 10;
    width: 750px;
    min-height: 60%;
    background: #F4E9DA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #212425;
    .header-localization-cdb {
      width: 100%;
      height: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .title-localization-cdb {
        font-size: 48px;
      }
      .name-localization-cdb {
        width: 90%;
        text-align: center;
        font-size: 26px;
        background: #eba943;
        color: #FFFFFF;
        border-radius: 8px;
        padding: 0 10px !important;
      }
    }
    .form-localization-cdb {
      width: 90%;
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      gap: 8px;
      .state-and-city-localization {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .vs__dropdown-toggle {
          height: 40px;
          border-radius: 8px;
          border: none;
          .vs__selected-options {
            padding-left: 10px !important;
            .vs__selected {
              font-size: 21px;
            }
          }
          .vs__actions {
            padding-right: 10px !important;
            .vs__clear {
              display: none;
            }
          }
        }
        .input-state-localization-cdb {
          width: 150px;
        }
        .input-city-localization-cdb {
          width: 360px;
        }
      }
      .form-localization-input {
        font-size: 21px;
        font-family: 'Alegreya Sans', sans-serif;
        width: 100%;
        height: 40px;
        padding-left: 10px !important;
        outline: none;
        border-radius: 8px;
        border: none;
      }
      .period-localization {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-period {
          font-size: 21px;
        }
        .date-pickers-cdb {
          padding: 0 5px !important;
          display: flex;
          flex: 1;
          justify-content: space-between;
          .input-date-cdb {
            width: 150px;
            font-size: 21px;
            border-radius: 8px;
            border: none;
            color: #000000;
            background: #ffffff;
            text-align: center;
            cursor: pointer;
          }
          .calendar-date-cdb-initial, .calendar-date-cdb-final {
            background: #ffffff;
            border-radius: 8px;
            left: -50%;
            border-color: #eba943;
            .selected {
              background: #eba943;
              border-radius: 8px;
            }
            .cell:hover {
              border: 1px solid #eba943;
              border-radius: 8px;
            }
          }
        }
      }
    }
    .send-info-localization {
      width: 90%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 10px 0 !important;
      .btn-send-localization, .btn-localization-cdb {
        width: 200px;
        height: 35px;
        border: none;
        border-radius: 8px;
        font-size: 21px;
        background: #eba943;
        cursor: pointer;
        color: #FFFFFF;
        outline: none;
      }
      .btn-localization-cdb {
        width: 280px;
      }
    }
  }
  .content-others-cdb {
    font-family: 'Alegreya Sans', sans-serif;
    padding: 40px 60px !important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    position: relative;
    z-index: 10;
    width: 750px;
    min-height: 540px;
    max-height: 95%;
    background: #F4E9DA;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #212425;
    .header-others-cdb {
      width: 100%;
      height: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .title-others-cdb {
        text-align: center;
        font-size: 38px;
      }
      .name-others-cdb {
        width: 90%;
        text-align: center;
        font-size: 26px;
        background: #eba943;
        color: #FFFFFF;
        border-radius: 8px;
        padding: 0 10px !important;
      }
    }
    .form-others-cdb {
      width: 90%;
      height: 600px;
      overflow-y: auto;
      padding-top: 10px !important;
      .form-others-input {
        width: 100%;
        height: 30px;
        font-size: 16px;
        padding-left: 10px !important;
        border-radius: 8px;
        outline: none;
        border: none;
        margin-bottom: 5px !important;
      }
      .current-period-form {
        height: 60px;
        .title-period-form {
          padding-left: 5px !important;
          font-size: 17px;
          color: rgb(77, 77, 77);
        }
        .period-form {
          display: flex;
          justify-content: space-between;
          .form-others-input-period {
            width: 49%;
            height: 30px;
            font-size: 16px;
            padding-left: 10px !important;
            border-radius: 8px;
            outline: none;
            border: none;
            margin-bottom: 5px !important;
          }
        }
      }
    }
    .send-info-others {
      width: 90%;
      height: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .btn-send-others {
        width: 200px;
        height: 35px;
        border: none;
        border-radius: 8px;
        font-size: 21px;
        background: #eba943;
        cursor: pointer;
        color: #FFFFFF;
      }
      .title-suport {
        font-size: 21px;
      }
    }
  }
  .label-info-register {
    color: black;
    font-size: 35px;
    font-weight: 600;
    margin-top: 10px !important;
    text-align: center;
  }
  .close-icon-small-register {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    color: #BF3D26;
    border: 2px solid #BF3D26;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .content-localization-cdb {
    .header-localization-cdb {
      justify-content: center !important;
      .title-localization-cdb {
        font-size: 25px !important;
        margin-bottom: 10px !important;
      }
      .name-localization-cdb {
        font-size: 20px !important;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-localization-cdb {
      gap: 10px;
      .state-and-city-localization {
        .input-state-localization-cdb {
          width: 60px !important;
          margin-right: 5px !important;
        }
        .input-city-localization-cdb {
          width: 200px !important;
        }
      }
      .period-localization {
        flex-direction: column !important;
        align-items: flex-start !important;
        height: 90px !important;
        .date-pickers-cdb {
          width: 100% !important;
          align-items: center;
          .input-date-cdb {
            width: 130px !important;
            font-size: 17px !important;
          }
          .calendar-date-cdb-initial {
            left: 23px !important;
          }
          .calendar-date-cdb-final {
            left: -195px !important;
          }
        }
      }
    }
    .send-info-localization {
      width: 100% !important;
      .btn-send-localization {
        width: 90px !important;
        font-size: 17px !important;
      }
      .btn-localization-cdb {
        width: 180px !important;
        font-size: 15px !important;
      }
    }
  }
  .content-others-cdb {
    height: 95vh;
    .header-others-cdb {
      height: 13% !important;
      justify-content: center !important;
      .title-others-cdb {
        font-size: 20px !important;
        margin-bottom: 10px !important;
      }
      .name-others-cdb {
        font-size: 20px !important;
        height: 30px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-others-cdb {
      overflow-y: auto !important;
      height: 74% !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: space-between !important;
      padding-top: 5px !important;
      .form-others-input {
        margin-bottom: 5px !important;
      }
    }
    .send-info-others {
      height: 13% !important;
      .btn-send-others {
        width: 130px !important;
        font-size: 17px !important;
      }
      .title-suport {
        font-size: 17px !important;
      }
    }
  }
  .label-info-register {
    font-size: 20px !important;
    margin-top: 15px !important;
  }
}
</style>