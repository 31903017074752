<template>
  <div class="v-suggestions">




            <div class="topBar">


    <ul class="ul_header">

        <li>

            <form id="form_busca" method="GET" action="/busca">
                <input type="hidden" class="busca" name="idPartner" id="idPartner" value="">
                <div class="box_busca">
                  <ul>
                    <li>
                         <input type="text" :class="extendedOptions.inputClass"
           v-bind="$attrs"
           v-on:keydown="onKeyDown"
           v-on:blur="hideItems"
           v-on:focus="showItems = true"
           v-model="query"
           :autocomplete="Math.random()"
           :placeholder="extendedOptions.placeholder">
                      <hr>
                    </li>
                    <li>
                        <select name="genre" @change="selectGenre">
                            <option value="">Gênero?</option>
                            <option v-for="(item, index) in genresList" :key="index" :value="item.id">{{ item.name | capitalize() }}</option>
                        </select>
                        <hr>
                    </li>
                    <li>
                        <select name="municipio" @change="selectMunicipio">
                            <option value="">Onde?</option>
                            <option v-for="(item, index) in cityList" :key="index" :value="item.id_municipio">{{ item.ds_municipio | capitalize() }}</option>                   
                        </select>         
                      <hr>
                    </li>
                    <li>
                        <select name="date_when" @change="selectDateWhen">
                            <option value="">Quando?</option>
                            <option :value="date.value" v-for="(date, index) in dateWhen" :key="index">{{date.name}}</option>                    
                        </select>              
                        <hr>
                    </li>
                    <li>
                        <select name="amount" @change="selectAmount">
                            <option value="">Quanto?</option>
                            <option value="1000">Tanto Faz</option>
                            <option value="15">até R$ 15,00</option>
                            <option value="25">até R$ 25,00</option>
                            <option value="30">até R$ 30,00</option>
                            <option value="50">até R$ 50,00</option>                      
                        </select>          
                        <hr>
                    </li>

                    <li style="width: 20%;" class="busca_btn" id="busca_btn"><input type="button" value="Buscar" id="btn_buscar" @click.prevent="$emit('btnSubmit')"></li>

                     </ul>
                </div> <!-- busca -->

                <div id="busca_mobile">

                    <ul class="busca_barra">
                        <li><input type="text" class="busca" name="search" id="busca_fieldAvancada" placeholder="Buscar..."></li>
                        <li class="busca_btn" id="busca_btn" ><input type="button" value="Buscar" id="btn_buscarMobile"  @click.prevent="$emit('btnSubmit')"></li>
                    </ul>

                    <ul class="busca_avancada" id="busca_avancada" style="display: none;">
                    <p id="close_search">Fechar</p>

                        <li>

                      <select name="genero">
                        <option value="">Gênero?</option>
                        <option v-for="(item, index) in genresList" :key="index" :value="item.id">{{ item.name | capitalize() }}</option>                      
                        </select>

                    </li>
                    <li>
                      <select name="onde">
                        <option value="">Onde?</option>
                        <option v-for="(item, index) in cityList" :key="index" :value="item.id_municipio">{{ item.ds_municipio | capitalize() }}</option>                 
                      </select>         

                    </li>
                    <li>
                      <select name="quando" value="">
                        <option value="">Quando?</option>
                        <option :value="date.value" v-for="(date, index) in dateWhen" :key="index">{{date.name}}</option>                    
                      </select>              

                    </li>
                    <li>
                      <select name="quanto">
                          <option value="">Quanto?</option>
                          <option value="1000">Tanto Faz</option>
                          <option value="15">até R$ 15,00</option>
                          <option value="25">até R$ 25,00</option>
                          <option value="30">até R$ 30,00</option>
                          <option value="50">até R$ 50,00</option>                      
                      </select>          

                    </li>

                    </ul>
                </div>
            </form>
        </li>

    </ul>


</div>






    <div class="suggestions">
      <ul class="items" v-show="items.length > 0 && showItems === true">
        <li class="item"
            :key="index"
            v-for="(item, index) in items"
            @click.prevent="selectItem(index)"
            v-bind:class="{ 'is-active': index === activeItemIndex }">
          <slot name="item"
                :item="item">
            {{item}}
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import debounce from 'debounce'

  export default {
    inheritAttributes: true,
    props: {
      options: {
        type: Object,
        default: {}
      },
      onInputChange: {
        type: Function,
        required: true
      },
      onItemSelected: {
        type: Function
      },
      value: {
        type: String,
        required: true
      }
    },
    data () {
      const defaultOptions = {
        debounce: 0,
        placeholder: '',
        inputClass: 'input'
      }
      const extendedOptions = Object.assign({}, defaultOptions, this.options)
      return {
        extendedOptions,
        query: this.value,
        lastSetQuery: null,
        items: [],
        activeItemIndex: -1,
        showItems: false
      }
    },
    beforeMount () {
      if (this.extendedOptions.debounce !== 0) {
        this.onQueryChanged = debounce(this.onQueryChanged, this.extendedOptions.debounce)
      }
    },
    watch: {
      'query': function (newValue, oldValue) {
        if (newValue === this.lastSetQuery) {
          this.lastSetQuery = null
          return
        }
        this.onQueryChanged(newValue)
        this.$emit('input', newValue)
      },
      'value': function (newValue, oldValue) {
        this.setInputQuery(newValue)
      }
    },
    computed:{
      cityList(){ return this.$store.state.cityList },
      genresList(){ return this.$store.state.genresList },
      dateWhen(){
          const dates = []
          for(let i=0; i < 15; i++){
            var date = new Date();
            date.setDate(date.getDate() + i);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day_week = date.toLocaleDateString('pt-br', { weekday: 'long' })
            let month_name = date.toLocaleString('pt-br', { month: 'long' });
            month_name = month_name.charAt(0).toUpperCase() + month_name.slice(1);
            dates.push({ name: `${day} de ${month_name} (${day_week})`, value: `${date.getFullYear()}-${month}-${day}` })
          }
          return dates
      }
    },
    created(){
      this.dateWhen
    },
    methods: {
      selectGenre(event){
        const id_genre = event.target.value
        this.$emit('idGenre', id_genre)
      },
      selectMunicipio(event){
        const id_municipio = event.target.value
        this.$emit('idMunicipio', id_municipio)
      },
      selectDateWhen(event){
        const date_when = event.target.value
        this.$emit('dateWhen', date_when)
      },
      selectAmount(event){
        const amount = event.target.value
        this.$emit('amount', amount)
      },
      onItemSelectedDefault (item) {
        if (typeof item === 'string') {
          this.$emit('input', item)
          this.setInputQuery(item)
          this.showItems = false
          // console.log('change value')
        }
      },
      hideItems () {
        setTimeout(() => {
          this.showItems = false
        }, 300)
      },
      showResults () {
        this.showItems = true
      },
      setInputQuery (value) {
        this.lastSetQuery = value
        this.query = value
      },
      onKeyDown (e) {
        switch (e.keyCode) {
          case 40:
            this.highlightItem('down')
            e.preventDefault()
            break
          case 38:
            this.highlightItem('up')
            e.preventDefault()
            break
          case 13:
            this.selectItem()
            e.preventDefault()
            break
          case 27:
            this.showItems = false
            e.preventDefault()
            break
          default:
            return true
        }
      },
      selectItem (index) {
        let item = null
        if (typeof index === 'undefined') {
          if (this.activeItemIndex === -1) {
            return
          }
          item = this.items[this.activeItemIndex]
        } else {
          item = this.items[index]
        }
        if (!item) {
          return
        }
        if (this.onItemSelected) {
          this.onItemSelected(item)
        } else {
          this.onItemSelectedDefault(item)
        }
        this.hideItems()
      },
      highlightItem (direction) {
        if (this.items.length === 0) {
          return
        }
        let selectedIndex = this.items.findIndex((item, index) => {
          return index === this.activeItemIndex
        })
        if (selectedIndex === -1) {
          // nothing selected
          if (direction === 'down') {
            selectedIndex = 0
          } else {
            selectedIndex = this.items.length - 1
          }
        } else {
          this.activeIndexItem = 0
          if (direction === 'down') {
            selectedIndex++
            if (selectedIndex === this.items.length) {
              selectedIndex = 0
            }
          } else {
            selectedIndex--
            if (selectedIndex < 0) {
              selectedIndex = this.items.length - 1
            }
          }
        }
        this.activeItemIndex = selectedIndex
      },
      setItems (items) {
        this.items = items
        this.activeItemIndex = -1
        this.showItems = true
      },
      onQueryChanged (value) {
        const result = this.onInputChange(value)
        this.items = []
        if (typeof result === 'undefined' || typeof result === 'boolean' || result === null) {
          return
        }
        if (result instanceof Array) {
          this.setItems(result)
        } else if (typeof result.then === 'function') {
          result.then(items => {
            this.setItems(items)
          })
        }
      }
    },
    filters: {
      capitalize: function (value) {
        if (value == null || value == undefined) return;

        if (!value) return ''

        return value.replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
    }
  }
</script>

<style scoped>
.v-suggestions{
    width: 90% !important;
}

.v-suggestions input[type=text] {
    border-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;    
    box-shadow: -4px 0px 4px rgb(0 0 0 / 20%), -4px 0px 4px rgb(0 0 0 / 20%);
}

.ul_header_menu {
  padding: 0;
}

.ul_header_menu li {
  display: block;
}

.ul_header_menu span {
  line-height: 50px;
}

#busca_mobile {
    display: none;
}

#busca_mobile > .busca_barra {
    display: table;
    margin: 0 auto;
    padding: 0;
}


#busca_mobile > .busca_barra > li:nth-of-type(1) {
    display: table-cell;
    width: 80%;
}

#busca_mobile > .busca_barra > li:nth-of-type(2) {
    display: table-cell;
    border-radius: 5px;
    padding: 0;
}

#busca_mobile > .busca_avancada {
    max-width: 400px;
    margin: 0 auto;
    padding: 0;
    margin-top: 10px;
    display: none;
}

#busca_mobile > .busca_avancada > li{
    margin-bottom: 5px;
}

#busca_mobile > .busca_barra > li:nth-of-type(2) {
    max-width: 100px;
    display: block;
    padding: 5px;
}

.topBar {
    font-size: 13px;
}

.topBar select {
    border: none;
    width: 100%;
    text-align: center;
    text-align-last: center;
    padding: 5px;
    cursor: pointer;
}

.topBar > ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

#btn_buscar {
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    font-weight: 800;
    border: none;
    color: white
}

.busca {
    margin: 0;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.box_busca {
    color: black;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0px 4px rgb(0 0 0 / 20%), 0 0px 4px rgb(0 0 0 / 20%);
}

.box_busca ul {
    display: table;
    padding: 0;
    width: 100%;
    margin: 0;
}

.box_busca li:nth-child(1) {
    display: table-cell;
    width: 30%;
    position: relative;
}

.box_busca li:nth-child(1) hr{
    width: 1px;
    height: 70%;
    position: absolute;
    top: 10%;
    right: 0;
    background-color: #adacac;
    border-radius: 100%;
    padding: 0;
    margin: 0;
}

.busca_btn {
    color: white;
  }

.box_busca li hr{
    width: 1px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: 0;
    background-color: #adacac;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    display: none;
}


.box_busca li {
    display: table-cell;
    width: 15%;
    cursor: pointer;
}

.box_busca li:not(:last-of-type) {
    background: #FFF;
}

.box_busca li:last-of-type {
    border: 1px solid #FFF;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: bold;
}

.busca {
    text-align: left !important;
    width: 100%;
    border: none;
    border-radius: 5px;
}

.busca_avancada {
	text-decoration: underline;
	cursor: pointer;
}

.box_parceiro_mobile ul {
    padding: 0;
}

.box_parceiro_mobile {
    width: 100%;
    background-color: white;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.box_parceiro_mobile img {
    display: block;
    margin: 0 auto;
    max-height: 100px;
}

.bar_login {
  display: none;
}

.bar_login a:before {
  margin-right: 10px;
  margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
 
   .busca {
    width: 100%;
  }

  .bar_login {
    display: block;
  }



}

@media screen and (max-width: 1100px) {
 
   .bar_contaUsuario {
    color: white;
    float: right;
    margin-top: 10px;
    margin-right: 20px;
    display: none;
  }


  .box_busca ul li:nth-of-type(2), .box_busca ul li:nth-of-type(3), .box_busca ul li:nth-of-type(4), .box_busca ul li:nth-of-type(5) {
    display: none !important;
  }

    .logo {
      width: 80%;
    }

    .box_busca {
        display: none !important;
    }

    #busca_mobile {
        display: block;
    }
    

}

</style>