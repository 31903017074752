<template>
  <div class="container-modal-cdb-img" v-if="showModal">
    <div class="section-modal-cdb-img" :key="count">
      <img v-if="count === 0 || count === 1" class="current-image-modal-cdb" :src="currentImage">
      <iframe
        v-if="count === 2 || count === 3"
        width="90%"
        height="95%"
        :src="currentImage"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen>
      </iframe>
      <span class="material-symbols-outlined arrow-icon-small" @click="nextOrbackImage('next')">arrow_forward_ios</span>
      <span class="material-symbols-outlined close-icon-small-gallery" @click="showModal = false">close</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalGallery',
  components: {},
  props: [],
  data () {
    return {
      count: 0,
      currentImage: '',
      images: [],
      showModal: false,
    }
  },
  mounted () {
    this.$root.$on('open-modal-gallery', payload => {
      if (payload.length > 0) {
        this.showModal = true
        this.images = payload
        this.currentImage = this.images[0]
      }
    })
  },
  computed: {

  },
  methods: {
    nextOrbackImage (e) {
      if (this.count === this.images.length - 1) {
        this.count = 0
        this.currentImage = this.images[this.count]
        return
      }

      this.count++
      this.currentImage = this.images[this.count]
    }
  }
}
</script>

<style lang="scss">
.container-modal-cdb-img {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4242428b;
  .section-modal-cdb-img {
    position: relative;
    z-index: 10;
    width: 80%;
    height: 90%;
    background: #1f1f1f;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .current-image-modal-cdb {
      width: 90%;
      height: 95%;
      object-fit: cover;
      border-radius: 8px;
    }
    .arrow-icon-small {
      color: #ffffff;
      cursor: pointer;
      font-size: 35px;
      font-weight: bold;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-icon-small-gallery {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #ffffff;
      font-size: 30px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-modal-cdb-img {
    width: 95% !important;
    height: 90% !important;
    .current-image-modal-cdb {
      width: 85%;
      height: 95%;
      object-fit: contain !important;
    }
    .arrow-icon-small {
      font-size: 25px !important;
    }
    .close-icon-small-gallery {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #ffffff;
      font-size: 30px;
      cursor: pointer;
    }
  }
}
</style>