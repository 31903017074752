<template>
  <div class="swiper col-12"  style="padding: 0px">
    <div
      :style="{
        display: getEventsWithGenre(genre).length > 0 ? 'block' : 'none',
      }"
      v-for="(genre, index) in getGenreNames"
      :key="genre"
    >
      <div
        class="titulo-eventos col-12 col-sm-12 text-left mt-2 mb-2"
        style="display: flex; justify-content: space-between"
      >
        <div
          style="display: inline-block"
          class="col-6 col-md-2 col-sm-2 p-0 mt-0"
        >
          <div>
            <div
              alt="image"
              class="img-fluid rounded card__home"
              style="margin: 0 !important; height: 40px; cursor: default"
            >
              <span
                class="txt-filtro-cat genre__ingressaria"
                style="cursor: default"
                >{{ genre }}</span
              >
            </div>
          </div>
        </div>
        <div class="container__arrows" style="margin: 0px">
          <div
            style="padding: 0; margin: 0; height: 26px; width: 28px"
            :class="'swiper-button-prev swiper-button-prev' + index"
            slot="button-prev"
          ></div>
          <div
            style="padding: 0; margin: 0; height: 26px; width: 28px"
            :class="'swiper-button-next swiper-button-next' + index"
            slot="button-next"
          ></div>
        </div>
      </div>
      <swiper :options="returnDynamicOptions(index)">
        <swiper-slide
          v-for="(item, idx) in getEventsWithGenre(genre)"
          :key="idx"
          class="col-12 col-xl-4 col-md-6 text-left"
          style="padding: 0.4rem"
        >
          <card-event
            style="padding: 0"
            :classes="'col-12 text-left'"
            :key="idx"
            :item="item"
          ></card-event>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import CardEvent from "@/components/Card-event.vue";

export default {
  name:"Card-swiper-dynamic",
  props: ["getGenreNames", "filteredData"],
  components: { CardEvent },
  data() {
    return {};
  },
  methods: {
    getEventsWithGenre(genre) {
      return this.filteredData.filter((item) => item.genreName === genre);
    },
    returnDynamicOptions(index) {
      return {
        loopFillGroupWithBlank: true,
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 1,
        pagination: {
          el: `.swiper-pagination${index}`,
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next" + index,
          prevEl: ".swiper-button-prev" + index,
        },
        breakpoints: {
          1174: {
            slidesPerGroup: 1,
            slidesPerView: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerGroup: 1,
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerGroup: 1,
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerGroup: 1,
            slidesPerView: 1,
            spaceBetween: 0,
          },
          320: {
            slidesPerGroup: 1,
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      };
    },
  },
};
</script>
