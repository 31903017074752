import Vue from 'vue'
import config from '@/config'
import axios from 'axios';

export const cdbService = {
  getState,
  getCity,
  getAllCitys,
  getAllCircos,
  searchCirco,
  searchCircoForCity,
  siginCirco,
  sendCircoLocalizacao,
  sendCircoCadatroResponsavel,
  sendCircOutrasinformacoes,
  forgotPassword
}

async function getState () {
  let url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/'
  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.error(error)
  }
}

async function getCity (uf) {
  if (uf) {
    let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
    try {
      const { data } = await axios.get(url)
      return data
    } catch (error) {
      console.error(error)
    }
  }
}

async function getAllCitys () {
  let url = 'https://servicodados.ibge.gov.br/api/v1/localidades/municipios'
  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.error(error)
  }
}

async function getAllCircos () {
  let url = 'https://api.circosdobrasil.com/v1/place/equipamento_list'
  try {
    const { data } = await axios.get(url)
    return data.equipamentos
  } catch (error) {
    console.error(error)
  }
}
function   sendCircOutrasinformacoes(obj) {
  let url = config.api + `/v1/help/sendCircoOutrasinformacoes?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}
function   sendCircoCadatroResponsavel(obj) {
  let url = config.api + `/v1/help/sendResponsavelcirco?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}

function forgotPassword(obj) {
  let url = config.api + `/v1/help/forgot-password?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}

function   sendCircoLocalizacao(obj) {
  let url = config.api + `/v1/help/sendLocalcirco?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}
function   siginCirco(obj) {
  let url = config.api + `/v1/help/logincirco?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, { email: obj.email, password: obj.password, equipamento_id: obj.id }, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}
async function searchCirco (payload) {
  let url = `https://api.circosdobrasil.com/v1/place/equipamento_query?search=${payload}`
  try {
    const { data } = await axios.get(url)
    return data.equipamentos
  } catch (error) {
    console.error(error)
  }
}

async function searchCircoForCity (payload) {
  let url = `https://api.circosdobrasil.com/v1/place/equipamento_cidade?search=${payload}`
  try {
    const { data } = await axios.get(url)
    return data.equipamentos
  } catch (error) {
    console.error(error)
  }
}