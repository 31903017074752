import { render, staticRenderFns } from "./ModalErrorSearch.vue?vue&type=template&id=06d0f332&"
import script from "./ModalErrorSearch.vue?vue&type=script&lang=js&"
export * from "./ModalErrorSearch.vue?vue&type=script&lang=js&"
import style0 from "./ModalErrorSearch.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ModalErrorSearch.vue"
export default component.exports