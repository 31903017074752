import Vue from 'vue';
import Vuex from 'vuex';
const { func } = require('@/functions');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    cityList: [],
    genresList: [],
    categoriesList: [
      { name_cat: "TEATROS", id: 1 },
      { name_cat: "TEATROS INFANTIS", id: 2 },
      { name_cat: "CIRCOS", id: 3 },
      { name_cat: "CINEMAS", id: 4 },
      { name_cat: "PARQUES", id: 5 },
      { name_cat: "EXCURSÕES", id: 6 },
      { name_cat: "ECO TURISMO", id: 7 },
      { name_cat: "MUSEUS", id: 8 },
      { name_cat: "GAMES", id: 9 },
      { name_cat: "AVENTURAS", id: 10 },
      { name_cat: "SHOWS", id: 11 },
      { name_cat: "STAND UP", id: 13 },
      { name_cat: "OUTROS", id: 12 },
   ],
  },
  mutations: {
    storeUser (state, userData) {
      state.token = userData.token;
      state.user = userData.user;
    },
    clearAuthData (state) {
      state.token = null
      state.user= null
    },
    storeCities(state, data){
      state.cityList = data
    },
    storeGenres(state, data){
      state.genresList = data
    },
    storeCategorys(state, data) {
      state.categoryList = data
    }
  },
  actions: {
    setLogoutTimer ({commit}, expirationTime) {
      //setTimeout(() => {
      //  commit('clearAuthData')
      //}, expirationTime * 1000)
    },
    login ({commit, dispatch}, auth) {
        const now = new Date()
        const expirationDate = new Date(auth.tokenValidUntil);
        
        func.methods.ls_add('token', auth.token);
        func.methods.ls_add('id', auth.id);
        func.methods.ls_add('expirationDate', expirationDate);
        commit('storeUser', {
          token: auth.token,
          user: auth
        });
        //dispatch('setLogoutTimer', 30)
    },
    logout ({commit}) {
      commit('clearAuthData');
      func.methods.ls_remove("base");
      func.methods.ls_remove("operation");
      func.methods.ls_remove("expirationDate");
      func.methods.ls_remove("token");
      func.methods.ls_remove("id");
      func.methods.ls_remove("client");
      func.methods.ls_remove("fb_connect");
      func.methods.ls_remove("add_user");
      window.location = "/";

      //router.replace('/');
    },
    setCities({commit}, data){
      commit('storeCities', data);
    },
    setGenres({commit}, data){
      commit('storeGenres', data);
    },
    setCategorys({commit}, data){
      commit('storeCategorys', data)
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    isAuthenticated (state) {
      return state.token !== null
    },
    getCategories(state){
      return state.categoriesList
    }
  }
})
