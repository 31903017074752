import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const usersiteService = {
    add,
    save,
    reset,
    newpass,
    documenttype,
    resetvalidatecode,
    get,
}

function documenttype() {
    let url = config.api + `/v1/user/documenttype`;

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.get(url).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
function resetvalidatecode(code) {
    let url = config.api + `/v1/user/resetvalidatecode`;

    let obj = {
        code
    }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, {
                emulateJSON: true
            }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
function get(token) {
    let url = config.api + `/v1/user/get`;

    let obj = {
        token
    }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, {
                emulateJSON: true
            }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
function reset(email) {
    let url = config.api + `/v1/user/resetemail`;

    let isadd = 1;

    let obj = {
        email
    }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, {
                emulateJSON: true
            }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
function newpass(code, pass) {
    let url = config.api + `/v1/user/newpass`;

    let obj = {
        pass, code
    }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, {
                emulateJSON: true
            }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function add(firstname, lastname, gender, birthdate, document, documenttype, brazilian_rg, phone_ddd, phone_number, zipcode, city_state, city, neighborhood, address, address_number, address_more, login, pass, newsletter, agree, fb, isforeign, code, loggedtoken, isShortForm) {
    const domains = require('./../../../jsons/domains.json');
    const domain = domains[window.location.hostname];
    let key = domain;

    // let urlService = 'https://services.bilheteria.com.br/bilheteria/public/api/v1/codes/' + key + '/' + code;
    const params = {
        key,
        code
    };
    
    const queryString = new URLSearchParams(params).toString();
    let urlService = config.api + '/v1/user/codacesso?' + queryString;

    let ret = Vue.http.get(urlService).then(
        res => {
            res.body = res.body[0]; //removendo objeto do array e gravando em res.body

            return res;
        }, err => {
            return {
                error: true,
                msg: err.body.msg,
                service: true
            };
        }
    ).then(
        res => {
            if (res.service) {

                return {
                    error: true,
                    msg: res.msg,
                    service: true
                };
            } else {
                console.log('res.bodyText');
                console.log(res.bodyText);
                var respostaApi = JSON.parse(res.bodyText);
                console.log('respostaApi:');
                console.log(respostaApi.status);
                console.log(respostaApi.msg);
                console.log(respostaApi.code);
                console.log(respostaApi);
                // return false;
                if (respostaApi.status == "error") {
                    return {
                        error: true,
                        msg: respostaApi.msg,
                        service: true
                    };
                }
                let url = config.api + `/v1/user/save`;
                let isadd = 1;

                let obj = {
                    firstname, lastname, gender, birthdate, document, documenttype, brazilian_rg, phone_ddd, phone_number, zipcode, city_state, city, neighborhood, address, address_number, address_more, login, pass, newsletter: newsletter ? 1 : 0, agree: agree ? 1 : 0, fb, isforeign: isforeign ? 1 : 0,
                    type: isadd, loggedtoken, code, validateShortFlow: isShortForm ? 1 : 0
                }

                console.log(obj);

                var ret = new Promise(
                    function (resolve, reject) {
                        Vue.http.post(url, obj, {
                            emulateJSON: true
                        }).then(res => {
                            resolve(res.body);
                        }, err => {
                            reject({
                                error: true,
                                msg: err
                            });
                        });
                    }
                );
                return ret;
            }
        }, err => {
            console.log(err);
            return {
                error: true,
                msg: err.body.msg,
                service: true
            };
            //return res;
        }
    )
    return ret;


    // let url = config.api + `/v1/user/save`;

    // let isadd = 1;

    // let obj = {
    //   firstname, lastname, gender, birthdate, document, documenttype, brazilian_rg, phone_ddd, phone_number, zipcode, city_state, city, neighborhood, address, address_number, address_more, login, pass, newsletter: newsletter ? 1 : 0, agree: agree ? 1 : 0, fb, isforeign: isforeign ? 1 : 0,
    //   type: isadd, loggedtoken
    // }

    // var ret = new Promise(
    //     function (resolve, reject) {
    //         Vue.http.post(url, obj, {
    //             emulateJSON: true
    //         }).then(res => {
    //             resolve(res.body);
    //         }, err => {
    //             reject({
    //                 error: true,
    //                 msg: err
    //             });
    //         });    
    //     }
    // );
    // return ret;
}
function save(firstname, lastname, gender, birthdate, document, documenttype, brazilian_rg, phone_ddd, phone_number, zipcode, city_state, city, neighborhood, address, address_number, address_more, login, pass, newsletter, agree, fb, isforeign) {
    let url = config.api + `/v1/user/save`;

    let isadd = 0;

    let obj = {
        firstname, lastname, gender, birthdate, document, documenttype, brazilian_rg, phone_ddd, phone_number, zipcode, city_state, city, neighborhood, address, address_number, address_more, login, pass, newsletter, agree, fb, isforeign,
        isadd
    }

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, {
                emulateJSON: true
            }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}
