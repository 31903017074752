<template>
<div id="app">
  <default-layout></default-layout>
  <Messager v-if="showMessage" :message="messageAlert.message" :title="messageAlert.title"  @closed='showMessage = false'/>
  <Messager v-if="showOfflineMessage" :message="offlineMessage" :title="offlineTitle" :showClose='false' :allowUserContinueScrolling='false'/>
  <whats-app v-if="siteName !== 'ingressoparatodos.com.br'"></whats-app>
</div>
</template>

<script>
import DefaultLayout from "@/views/Main.vue"
import vueAlert from 'vue-sweetalert2';
import config from "@/config";
import Vue from "vue"
import WhatsApp from './views/WhatsApp.vue'
import vSelect from "vue-select"
import Messager from "@/components/Messager";
import { partnerService } from "@/components/common/services/partner";
Vue.component("v-select", vSelect)
import "vue-select/dist/vue-select.css";

export default {
  name: 'App',

  components: {
    DefaultLayout,
    WhatsApp,
    Messager
  },
  data() {
    return {
      showMessage: false,
      showOfflineMessage: false,
      offlineTitle: 'Sem conexão com a internet',
      offlineMessage: 'Por favor, verifique sua conexão com a internet e tente novamente.',
      messageAlert: {
        title: '',
        message: ''
      },
      refreshing: false,
      registration: null,
      updateExists: false,
      siteName: config.info.siteName
    };
  },
  computed: {
    
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // window.location.reload();
    });
    // some function to handlw event offline and online from navigator
    window.addEventListener('offline', () => {
      this.showOfflineMessage = true
    })
    window.addEventListener('online', () => {
      this.showOfflineMessage = false
    })
    if (config.info.siteName === 'corporativo.bilheteria.com.br') {
      const dinamize = document.createElement('script')
      dinamize.setAttribute('src', '//receiver.posclick.dinamize.com/forms/js/329412_4127.js')
      document.head.appendChild(dinamize)
    }
  },
  beforeDestroy() {
     this.$root.$off('send-meta-pixel-event')
  },
  mounted() {
    this.$root.$on('message-alert', (message) => {
      this.messageAlert = message
      this.showMessage = true
    })
    // importando google icons
    const googleIcon = document.createElement('link')
    googleIcon.setAttribute('rel', 'stylesheet')
    googleIcon.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0')
    document.head.appendChild(googleIcon)
    //0E72R17TZ6
    this.$root.$on('send-meta-pixel-event', async (manyAnalyticsTriggers) => {
      const { name, fb_appid, ga_id, data } = manyAnalyticsTriggers;
      const partnerInfo = await partnerService.ga();

      const processIds = (...ids) => ids
        .flatMap(id => id ? id.trim().split(',') : [])
        .filter(Boolean);

      const analyticsIds = processIds(ga_id, partnerInfo.ga_id);
      const facebookIds = processIds(fb_appid, partnerInfo.fb_appid);

      facebookIds.forEach(id => {
        initFbq(id);
        emitFbq(name, data, id);
      });

      analyticsIds.forEach(id => this.checkGTagScript({ id, data }));
    });
    function emitFbq(name, eventData, pixelId) {
      eventData.currency = 'BRL';
      eventData.value = eventData.value ? parseFloat(String(eventData.value).replace(/[^\d.-]+/g, '')) : 0;
      window.fbq('trackSingleCustom', pixelId, name, eventData);
    }
    function initFbq(pixelId) {
      Vue.analytics.fbq.init(pixelId);
    }
  },
  methods: {
    checkGTagScript(gtagSetup) {
        const existingScript = document.querySelector(`script[src*="${gtagSetup.id}"]`);
        if (!existingScript) {
          this.setupGTagScript(gtagSetup);
        } else {
          this.trackEvent(gtagSetup);
        }
    },
    setupGTagScript(gtagSetup) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagSetup.id}`;
      document.head.appendChild(script);

      script.onload = () => {
        this.initializeGTag(gtagSetup);
      };
    },
    initializeGTag(gtagSetup) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      
      gtag('config', gtagSetup.id);
    },
    trackEvent(gtagSetup) {
      gtag('event', 'screen_view', gtagSetup.data);
    },
    toastSuccess(message, timer = 4000, showbutton = false) {
      this.$swal({
        type: 'success',
        toast: true,
        text: message,
        position: 'top-end',
        customClass: 'alert__tixsme alert__sucess',
        background: '#333',
        timer: timer,
        showConfirmButton: showbutton,
      });
    },
    showRefreshUI (e) {
      this.registration = e.detail;
      this.updateExists = true;
      // this.$swal({
      //   title: 'Nova versão',
      //   text: "Realizamos atualizações no site, iremos atualizar agora.",
      //   type: 'success',
      //   toast: true,
      //   position: 'top-end',
      //   customClass: 'alert__tixsme alert__sucess',
      //   background: '#333',
      //   showConfirmButton: false,
      //   timer: 4000,
      // }).then((result) => {
      //   this.refreshApp();
      // });
      this.refreshApp();
      //this.toastSuccess("Realizamos atualizações no site, iremos atualizar agora.");
    },
    refreshApp () {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
  computed: {
    component() {
      return this.$store.state.common.layout
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 500px) {
  .wrapper_login { // prevent zoom on mobile while typing in input fields
    input[type='text'],
    input[type='number'],
    input {
      font-size: 16px !important;
    }
  }
}
</style>
