<template>
  <div
    :key="defaultBannerKey"
    class="container-fluid container__select"
    v-if="getCorporate.id"
  >
    <div class="container p-0">
      <div class="row">
        <div class="col-12 col-xl-12 text-left">
          <div class="p-3">
            <div class="">
              <div
                class="mainBannerContainer"
                style="
                  background-image: url('../../../assets/corporate-banner-default.jpeg');
                "
              >
                <div class="mainBannerTextsContainer">
                  <div class="container p-0">
                    <div class="row">
                      <div
                        class="col-12 col-xl-12 text-left"
                      >
                        <div
                          class="mainBannerBackgroundlogo p3"
                          v-lazy:background-image="
                            getCorporate.imageURIOriginal
                          "
                        ></div>
                        <p class="mainBannerTitle">
                          TEMOS UMA NOVIDADE PARA VOCÊ!
                        </p>
                        <p class="mainBannerDescription">
                          <span
                            v-html="
                              getCorporate.messagepromo.replace(
                                /(?:\r\n|\r|\n)/g,
                                '<br>'
                              )
                            "
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mainBannerCompanyContainer">
                <div class="container p-0">
                  <div class="row">
                    <div
                      class="col-12 col-xl-12 text-left"
                    >
                      <h3 class="mainBannerCompanyName">
                        {{ getCorporate.name }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { corporateService } from "./common/services/corporate";
import { func } from "@/functions";

export default {
  name: "CorporateDefaultBanner",
  mixins: [func],
  data() {
    return {
      defaultBannerKey: 0,
      getCorporate: {},
    };
  },
  created() {
    const item = localStorage.getItem("ticket-office-corporate");
    this.getCorporate = item ? JSON.parse(item) : {};
  },
  async mounted() {
    if (this.getCorporate.code) {
      corporateService
        .validarCodigo(this.getCorporate.code)
        .then((response) => {
          this.hideWaitAboveAll();
          if (response.id) {
            localStorage.setItem(
              "ticket-office-corporate",
              JSON.stringify(response)
            );
            this.getCorporate = response;
          } else {
            this.toastError("Código inválido.");
          }
          this.defaultBannerKey += 1;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.$router.push("askCode");
    }
  },
};
</script>
<style lang="scss">
.mainBannerBackgroundlogo {
  background-size: cover;
  background-position: center;
  background-color: black;
  margin-left: 9px;
  z-index: 2;
  background-repeat: no-repeat;
  width: calc(100vw - 100vw + 134px);
  height: calc(100vw - 100vw + 134px);
  position: absolute;
  border: 4px solid white;
}
.mainBannerCompanyContainer {
  width: 100%;
  height: 40px;
  background: white;
  margin-top: -10px;
  position: relative;
}
.mainBannerDescription {
  height: 78px;
  margin-left: 161px !important;
}
.mainBannerCompanyName {
  line-height: 35px !important;
  font-size: 1.2em;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 185px;
}
.mainBannerTextsContainer {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1vw;
  max-height: 129px;
  padding: 10px;
}
.mainBannerContainer {
  position: relative;
  height: 134px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.mainBannerTextsContainer > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
  position: absolute;
}

@media screen and (max-width: 930px) {
  .mainBannerBackgroundlogo {
    position: relative;
    margin: 13px auto;
  }
  .mainBannerDescription {
    height: auto;
    margin-left: 0px !important;
    text-align: center;
    display: block;
    padding: 10px;
  }

  .mainBannerCompanyName {
    margin-left: 0;
  }

  .mainBannerTextsContainer {
    max-height: 100%;
  }

  .mainBannerContainer {
    height: auto;
  }

  .mainBannerTitle {
    text-align: center;
    margin-left: 0px !important;
    white-space: normal !important;
    background: linear-gradient(90deg, #fff 35%, #fdc80a 9%) !important;
    -webkit-background-clip: text !important;;
  }

  .mainBannerCompanyName {
    max-width: 100%;
    text-align: center;
  }

  .mainBannerCompanyContainer > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    position: relative;
  }

  .mainBannerTextsContainer > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    position: relative;
  }
}
</style>