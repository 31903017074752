import { render, staticRenderFns } from "./Messager.vue?vue&type=template&id=8f964f88&scoped=true&"
import script from "./Messager.vue?vue&type=script&lang=js&"
export * from "./Messager.vue?vue&type=script&lang=js&"
import style0 from "./Messager.vue?vue&type=style&index=0&id=8f964f88&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f964f88",
  null
  
)

component.options.__file = "Messager.vue"
export default component.exports