<template>
  <div class="container-modal-error-cdb" v-if="showModal">
    <div class="section-modal-error-cdb">
      <div class="header-modal-error-cdb">
        <h2 class="title-modal-error-cdb">Nenhum circo da sua cidade e região está registrado no Circos do Brasil.</h2>
      </div>
      <div class="info-modal-error-cdb">
        <p class="msg-error-search">Se você souber de algum ou outro circo por ai, favor informar em <strong>Indique um circo</strong>, para que seus amigos e familiares possam curtir um divertimento sadio e inesquecível ai na sua região.</p>
      </div>
      <div class="confirm-error-cdb">
        <button class="btn-error-cdb" @click="showModal = false">OK</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalErrorSearchCdb',
  components: {},
  props: [],
  data () {
    return {
      showModal: false,
      formContact: {
        name: '',
        email: '',
        subject: '',
        phone: '',
        content: ''
      }
    }
  },
  mounted () {
    this.$root.$on('open-modal-error-search', payload => {
      this.showModal = payload
    })
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.container-modal-error-cdb {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42424240;
  .section-modal-error-cdb {
    position: relative;
    z-index: 10;
    border-top: 10px solid #BF3D26;
    width: 30%;
    height: 40%;
    background: #FAF5E4;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .header-modal-error-cdb {
      width: 100%;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .title-modal-error-cdb {
        font-size: 1.8em;
        text-align: center;
      }
    }
    .info-modal-error-cdb {
      width: 100%;
      height: 40%;
      padding: 10px 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .msg-error-search {
        font-size: 1.5em;
        text-align: center;
      }
    }
    .confirm-error-cdb {
      width: 100%;
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-error-cdb {
        background: #BF3D26;
        color: #FFFFFF;
        border: none;
        height: 60%;
        width: 15%;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1.1em;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-modal-error-cdb {
    width: 95% !important;
    height: 60% !important;
    .info-modal-error-cdb {
      .form-input-cdb {
        width: 90% !important;
      }
    }
    .confirm-error-cdb {
      .btn-error-cdb {
        width: 25% !important;
        height: 70% !important;
      }
    }
  }
}
</style>