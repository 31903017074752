import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';
import axios from 'axios';

Vue.use(VueResource);

//config.setapikey();

export const externalService = {
  cep,
  codec,
  validateCep,
  saveContactOnDinamize
}

function cep(text) {
  let url = `https://api.postmon.com.br/v1/cep/${text}`;

  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        });
      });
    }
  );
  return ret;
}

function validateCep(cep) {
  let url = `https://viacep.com.br/ws/${cep}/json/`
  return Vue.http.get(url);
}

function saveContactOnDinamize(data) {
  const corsProxyUrl = 'https://proxy-server.glitch.me/'; // this project is like a cors-anywhere, to bypass the dinamize policy
  const targetUrl = 'https://receiver.webhook.dinamize.com/75b02bfc-1732-4909-b9fc-d6ee7384bd95';

  var config = {
    method: 'post',
    url: corsProxyUrl + targetUrl,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  return axios(config);
}

//teste
function codec(key, value) {
  let url = `https://services.bilheteria.com.br/bilheteria/public/api/v1/codes/${key}/${value}`;

  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        })
      })
    }
  );
  return ret;
}
//teste
