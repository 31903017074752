<template>
  <div class="container-main-cdb">
    <div class="section-media-cdb">
      <div class="text-introductory-cdb">
        <p class="labels-introductory-cdb">Respeitável público,</p>
        <p class="labels-introductory-cdb">Aqui você encontra os circos instalados na sua cidade ou próximas, onde sua familía pode curtir um divertimento sadio e inesquecível: palhaços, malabaristas, acrobatas, trapezistas, mágicos, bailarinas e shows diversos. Veja o caso do Erik no vídeo ao lado.</p>
      </div>
      <div class="video-introductory-cdb">
        <video controls class="video-cdb">
          <source src="./assets/video_circos_do_brasil.mp4" type="video/mp4">
          <object>
            <embed src="./assets/video_circos_do_brasil.mp4" type="application/x-shockwave-flash" 
            allowfullscreen="false" allowscriptaccess="always">  		
          </object>
          Formato não suportado  
        </video>
      </div>
    </div>
    <div class="found-circos" :style="{'background': researched.length > 0 ? '#eba943' : 'transparent'}">
      <div class="found-title-cdb" v-if="researched.length > 0">
        <h2 class="h2-title-cdb">CIRCOS ENCONTRADOS</h2>
        <p class="p-title-cdb">Encontramos {{ researched.length }} circos próximos à você</p>
      </div>
      <div class="list-found-items" v-if="researched.length > 0">
        <div class="item-found-circo">
          <div class="item-found" v-for="(item, idx) in researched" :key="idx">
            <div class="info-item-cdb">
              <p class="item-found-name" @click="goEvent(item)">{{ item.nome }}</p>
              <p class="item-found-address" v-if="item.localizacao_cidade && item.localizacao_estado">{{ item.localizacao_cidade }} / {{ item.localizacao_estado }}</p>
              <p class="item-found-address">{{ item.localizacao || item.endereco }}</p>
              <p class="item-found-phone-site">{{ item.telefone_equipamento }} <span v-if="item.site">|</span> {{ item.site }}</p>
            </div>
            <div class="item-found-purchase">
              <div class="buy-tickets-cdb">
                <button class="online-tickets" v-if="item.link_ingresso_online"><a class="link-ticket-cdb" :href="item.link_ingresso_online" target="_blank">INGRESSOS ONLINE</a></button>
              </div>
              <button class="know-more-cdb" @click="goEvent(item)">
                <p class="know-more-label">SAIBA MAIS</p>
                <span class="material-symbols-outlined know-more-icon">arrow_forward</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="search-cdb">
        <p class="title-search-cdb">BUSQUE PELA CIDADE OU NOME DO CIRCO.</p>
        <label for="circo-name-input" class="circo-name-input-class">
          <input type="text" name="" id="circo-name-input" v-model="searchCirco" @keyup.enter="searchItem()">
          <button class="confirm-search-cdb" @click="searchItem()">Buscar</button>
        </label>
        <div class="star-icons-circos-cdb">
          <span class="material-symbols-outlined star-icon-small">star</span>
          <span class="material-symbols-outlined star-icon-large">star</span>
          <span class="material-symbols-outlined star-icon-small">star</span>
        </div>
      </div>
    </div>
    <div class="indicate-circo-cdb">
      <div class="indicate-cdb">
        <div class="indicate-labels">
          <h2 class="indicate-title">INDIQUE UM CIRCO</h2>
          <p class="indicate-text">Se você sabe de algum circo instalado na sua cidade e que não conste no CIRCOS DO BRASIL, favor <span style="font-weight: bold">informar os dados ao lado</span> e o circo e a sua cidade serão incluídos no site.</p>
        </div>
        <div class="indicate-fields-cdb">
          <label for="city-state" class="label-cdb">
            <p>Estado/Cidade</p>
            <div class="city-state-select">
              <v-select
                class="input-state-cdb"
                :options="optionsStates"
                label="uf"
                placeholder="UF"
                :reduce="name => name.uf"
                v-model="formContact.uf"
                @input="setState(formContact.uf)"
              >
              </v-select>
              <v-select
                class="input-city-cdb"
                :options="optionsCitys"
                :disabled="!showInputCity"
                label="city"
                placeholder="Cidade"
                :reduce="name => name.city"
                v-model="formContact.cidade"
              >
              </v-select>
            </div>
          </label>
          <label for="circo-nameCirco" class="label-cdb">
            <p>Nome do circo *</p>
            <input type="text" v-model="formContact.nameCirco"  id="circo-nameCirco">
          </label>
          <label for="circo-name" class="label-cdb">
            <p>Seu nome *</p>
            <input type="text" v-model="formContact.name"  id="circo-name">
          </label>
          <label for="phone-cdb"  class="label-cdb">
            <p>Seu celular *</p>
            <input type="text" v-model="formContact.phone"  id="phone-cdb">
          </label>
          <label for="email-cdb" class="label-cdb">
            <p>Seu e-mail *</p>
            <input type="text" v-model="formContact.email"  id="email-cdb">
          </label>
          <button @click="sendIndique" class="indicate-send-btn">ENVIAR</button>
        </div>
      </div>
      <div class="advertence-cdb">
        <p class="advertence-title">DONOS E RESPONSÁVEIS PELOS CIRCOS</p>
        <p class="advertence-label">Este site só tem o objetivo de divulgar a localização do seu circo. Se ele nao consta neste site você poderá inclui-lo <span class="span-open-modal-cdb" @click="showModalRegister()">clicando aqui</span> e entraremos em contato para confirmações.</p>
        <p class="advertence-title">ATENÇÃO</p>
        <p class="advertence-label">Mantenha a sua localização sempre atualizada.</p>
      </div>
    </div>
    <detail-event />
    <modal-cdb />
    <modal-error-search />
    <modal-register v-if="showRegister" :name="''" />

  </div>
</template>

<script>
import { VueSelect } from "vue-select"
import { cdbService } from "@/components/common/services/cdb_service"
import { sendService } from "@/components/common/services/send"
import DetailEvent from './components/DetailEvent.vue'
import ModalErrorSearch from './components/ModalErrorSearch.vue'
import ModalCdb from './components/ModalCdb.vue'
import ModalRegister from './components/ModalRegister.vue'

export default {
  name: 'EventsCircos',
  props: [],
  components: {
    VueSelect,
    DetailEvent,
    ModalErrorSearch,
    ModalCdb,
    ModalRegister
  },
  data () {
    return {
      showRegister: false,
      researched: [],
      searchCirco: '',
      optionsStates: [],
      stateSelected: null,
      optionsCitys: [],
      citySelected: null,
      showInputCity: false,
      showSearchInputCity: false,
      searchCircoInCity: {
        uf: '',
        allCitysOptions: [],
        searchCitySelected: ''
      },
      formContact: {
        nameCirco: '',
        name: '',
        email: '',
        cidade: '',
        phone: '',
        uf: ''
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('open-detail-event')
    this.$root.$off('open-modal-contact')
    this.$root.$off('circos-researched')
  },
  created () {
    this.addAdditionalGTMCircos()
  },
  async mounted () {
    const states = await cdbService.getState()

    this.optionsStates = states.map(state => { return { uf: state.sigla, name: state.nome } })
    this.optionsStates.sort((a, b) => {
      if(a.uf < b.uf) { return -1 }
      if(a.uf > b.uf) { return 1 }
    return 0
    })

    this.$root.$on('close-modal-register', () => {
      this.showRegister = false
    })

    this.$root.$on('circos-researched', payload => {
      const school = []
      this.researched = []
      payload.forEach(e => {
        if (e.tipo_equipamento.nome === 'CIRCOS') {
          this.researched.push(e)
        }
        if (e.tipo_equipamento.nome === 'CIRCO ESCOLA') {
          school.push(e)
        }
      })
      school.forEach(e => { this.researched.push(e) })
      const elementDiv = document.querySelector('.found-circos')
      elementDiv.scrollIntoView()
    })
  },
  computed: {
  },
  methods: {
    showModalRegister () {
      this.$root.$emit('open-modal-contact', true)
    },
    addAdditionalGTMCircos() {
      const additionalGtmId = 'GTM-TSPQD4M8'
  
      const additionalGtmScript = document.createElement('script');
      additionalGtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${additionalGtmId}');`

      const head = document.querySelector('head')
      head.appendChild(additionalGtmScript)

      const additionalGtmNoscript = document.createElement('noscript')
      additionalGtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${additionalGtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      
      head.appendChild(additionalGtmNoscript)
    },
    async searchItem () {
      if (this.searchCirco.length <= 0) { return false }
      const items = await cdbService.searchCirco(this.searchCirco)
      if (items.length > 0) {
        this.$root.$emit('circos-researched', items)
        this.searchCirco = ''
      } else {
        this.$root.$emit('open-modal-error-search', true)
        setTimeout(() => {
          this.searchCirco = ''
        }, 1500)
      }
    },
     async searchCircoWithCity () {
       if (this.searchCircoInCity.searchCitySelected.city.length > 0) {
        const result = await cdbService.searchCircoForCity(this.searchCircoInCity.searchCitySelected.city)
        if (result.length > 0) {
          this.researched = []
          const school = []
          result.forEach(e => {
            if (e.tipo_equipamento.nome === 'CIRCOS') {
              this.researched.push(e)
            }
            if (e.tipo_equipamento.nome === 'CIRCO ESCOLA') {
                school.push(e)
              }
          })
          school.forEach(e => { this.researched.push(e) })
        } else {
          this.$root.$emit('open-modal-error-search', true)
        }
        this.searchCircoInCity.searchCitySelected = ''
      }
    },
    goEvent (e) {
      this.$root.$emit('open-detail-event', e)
    },
    async setState (uf) {
      const citys = await cdbService.getCity(uf)
      this.optionsCitys = citys.map(c => { return { city: c.nome } })
      this.optionsCitys.length > 0 ? this.showInputCity = true : false
    },
    async searchState (uf) {
      const searchCitys = await cdbService.getCity(uf)
      this.searchCircoInCity.allCitysOptions = searchCitys.map(c => { return { city: c.nome } })
      this.searchCircoInCity.allCitysOptions.length > 0 ? this.showSearchInputCity = true : false
    },
    sendIndique() {
      if(this.formContact.nameCirco == '' || this.formContact.email == '' || this.formContact.phone == '' || this.formContact.name == '') {
        this.$swal({
          title: "É necessário informar os dados obrigatórios com *",
          type: "error",
          customClass: 'swal-alert-to-cdb',
          background: '#f4e9da',
          showConfirmButton: true,
          timer: 4000,
        })
      } else {
        sendService.indiqueCirco(this.formContact).then(
          response => {
            this.formContact = {
              nameCirco: '',
              name: '',
              email: '',
              cidade: '',
              phone: '',
              uf: ''
            }
            this.$swal({
              title: "Sua solicitação de indicação foi enviada com sucesso, agradecemos o seu contato.",
              type: "success",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          },
          error => {
            this.$swal({
              title: "Ocorreu um erro, tente novamente",
              type: "error",
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
.swal-alert-to-cdb {
  width: 500px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.12);
  .swal2-header {
    .swal2-title {
      margin: 20px 0 !important;
      color: #212425 !important;
    }
    .swal2-success {
      .swal2-success-circular-line-left, .swal2-success-circular-line-right, .swal2-success-fix {
        background: transparent !important;
      }
    }
  }
  .swal2-actions {
    .swal2-confirm {
      width: 150px;
      height: 40px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.content-register-cdb {
  font-family: 'Alegreya Sans', sans-serif;
  padding: 40px 60px !important;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
  position: relative;
  z-index: 10;
  width: 750px;
  height: 70%;
  background: #F4E9DA;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #212425;
  .header-register-cdb {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .title-register-cdb {
      font-size: 48px;
    }
    .name-register-cdb {
      width: 90%;
      text-align: center;
      font-size: 26px;
      background: #eba943;
      color: #FFFFFF;
      border-radius: 8px;
      padding: 0 10px !important;
    }
  }
  .form-register-cdb {
    width: 90%;
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3vw;
    .form-register-content-checkbox-cdb {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.3vw;
      .content-checkbox-cdb {
        background: #ffffff;
        width: 50%;
        height: 100%;
        border-radius: 8px;
        padding: 0 0.5vw !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5vw;
        .checkbox-cdb-select {
          transform: scale(1.1);
          cursor: pointer;
        }
        .label-checkbox-cdb {
          font-size: 21px;
          font-family: 'Alegreya Sans', sans-serif;
          color: #898988;
        }
      }
    }
    .form-register-input {
      font-size: 21px;
      font-family: 'Alegreya Sans', sans-serif;
      width: 100%;
      height: 40px;
      margin-bottom: 20px !important;
      padding-left: 10px !important;
      outline: none;
      border-radius: 8px;
      border: none;
    }
    .form-register-input::placeholder {
      color: #898988;
    }
    .form-register-input-user {
      font-size: 21px;
      font-family: 'Alegreya Sans', sans-serif;
      width: 100%;
      height: 40px;
      padding-left: 10px !important;
      outline: none;
      border-radius: 8px;
      border: none;
    }
    .form-register-input-user::placeholder {
      color: #898988;
    }
    .form-register-input-user:last-child {
      margin-bottom: 0.3vw !important;
    }
  }
  .send-info-register {
    width: 90%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    .btn-send-register, .btn-register-cdb {
      font-family: 'Alegreya Sans', sans-serif;
      min-width: 200px;
      height: 35px;
      border: none;
      border-radius: 8px;
      font-size: 20px;
      font-weight: bold;
      background: #eba943;
      cursor: pointer;
      color: #FFFFFF;
      outline: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .title-register-cdb {
    font-size: 25px !important;
  }
  .send-info-register {
    height: 25% !important;
  }
}
</style>