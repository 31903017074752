<template>
  <div class="a">
    <!-- <section class="to-block to-viewport bg-dark bg__main" style="" data-block-type="call_to_action" data-id="2" v-if="siteName == 'www.santosrockfestival.com.br'" @click="goto('event',{ uri: '/evento/cia_do_stand_up_teatro_west_plaza_33022'})">
      </section> -->
    <section
      class="to-block to-viewport bg-dark bg__main"
      onclick="window.location='https://blog.bilheteria.com/'"
      style=""
      data-block-type="call_to_action"
      data-id="2"
      v-if="
        siteName != 'cidadedasabelhas.bilheteria.com.br' &&
        siteName != 'corporativo.bilheteria.com.br'
      "
    >
      <div
        class="
          container
          justify-content-center
          align-items-center
          d-flex
          container__search--tickethub
          pt-5
          pb-4
        "
        v-if="siteName == 'ingressoparatodos.com.br'"
      >
        <div
          class="col-12 col-md-10 justify-content-center text-center"
          style="height: 380px"
          id="fundo_ingressaria"
        >
          <div></div>
        </div>
        <div
          class="col-12 col-md-10 justify-content-center text-center"
          v-if="
            siteName != 'ingressoparatodos.com.br' &&
            siteName != 'www.ingreshow.com.br'
          "
        >
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
      <div
        class="
          container
          justify-content-center
          align-items-center
          d-flex
          container__search--tickethub
          pt-5
          pb-4
        "
        v-if="siteName == 'www.ingreshow.com.br'"
      >
        <div
          class="col-12 col-md-10 justify-content-center text-center"
          style="height: 249px"
          id="fundo_ingressaria"
        >
          <div></div>
        </div>
        <div
          class="col-12 col-md-10 justify-content-center text-center"
          v-if="
            siteName != 'ingressoparatodos.com.br' &&
            siteName != 'www.ingreshow.com.br'
          "
        >
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
      <div
        class="
          container
          justify-content-center
          align-items-center
          d-flex
          p-4
          pt-5
          container__search--tickethub
        "
        v-if="
          siteName != 'ingressoparatodos.com.br' &&
          siteName != 'www.ingreshow.com.br'
        "
      >
        <div
          class="col-12 col-md-10 justify-content-center text-center"
          v-if="siteName != 'ingressoparatodos.com.br'"
        >
          <!-- <div class="" style="">
              <h2>Procure por experiências únicas.</h2>
            </div>-->
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="container-fluid container__select pb-0" style="border-bottom: 1px solid #e3e3e3" v-if="cityList.length > 1 "> -->
    <!--<div class="row pb-0">
          <div class="col-6 text-right select__city" style="border-right: 1px solid #e3e3e3;">
            <model-select :options="options" v-model="item" placeholder="Selecionar Cidade" @input="handleOnInput">
            </model-select>
          </div>
          <div class="col-6">
            <datepicker :clear-button=true :language="ptBR" @selected="selectDate" @cleared="resetEvents" placeholder="Data" :bootstrap-styling=true clear-button-icon="fa fa-sm fa-times-circle"></datepicker>
          </div>
        </div> -->
    <!-- </div> -->
    <!-- <div class="p-2 container-fluid container__select" v-else></div> -->

    <!-- Propaganda Bilheteria -->
    <div
      v-if="siteName != 'corporativo.bilheteria.com.br'"
      class="container-fluid container__select"
    >
      <div class="container p-0">
        <div class="row">
          <div class="col-12 col-xl-12 text-left">
            <div class="p-3">
              <img
                class="img-fluid rounded-0 discovery"
                src="../../../../public/assets/banner-comunidadocorona-PEQUENO.png"
                onclick="window.location='https://wa.me/5511996079544?text=Ol%C3%A1!%20Tenho%20interesse%20em%20um%20dos%20seus%20produtos'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fim Propaganda Bilheteria -->

    <!-- Propaganda principal corporativo -->
    <CorporateDefaultBanner
      v-if="siteName === 'corporativo.bilheteria.com.br'"
    />

    <!-- PROPAGANDA -->
    <div
      class="container-fluid container__select"
      v-if="discoveryBanner.length > 0"
    >
      <div class="container p-0">
        <div class="row">
          <div class="col-12 col-xl-12 text-left">
            <div :class="{'p-3': (siteName === 'corporativo.bilheteria.com.br' && !isMobile) || siteName !== 'corporativo.bilheteria.com.br' }">
              <div class="container__arrows" style="margin-top: auto">
                <div
                  class="swiper-button-prev swiper-button-prev-banner"
                  slot="button-prev"
                ></div>
                <div
                  class="swiper-button-next swiper-button-next-banner"
                  slot="button-next"
                ></div>
              </div>
              <swiper :options="swiperOptionBanner">
                <swiper-slide
                  v-for="(item, index) in discoveryBanner"
                  :key="index"
                >
                  <img
                    class="img-fluid rounded-0 discovery"
                    v-bind:src="item.imageURI"
                    :style="{'height': isMobile ? '4.2em' : 'auto' }"
                    :alt="item.title"
                    @click="goto('discovery', item.link)"
                  />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PROPAGANDA -->

    <!-- Destaque generos -->
    <section
      class="features"
      style="background: white"
      data-block-type="features"
      data-id="3"
      id="features"
      v-if="siteName == 'ingressoparatodos.com.br'"
    >
      <div class="container">
        <div class="row text-left pt-1 pb-1">
          <div class="col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">
              Nossos teatros
              <span style="font-size: 18.5px; color: #777">{{
                searchTerm !== "" ? "(" + searchTerm + ")" : ""
              }}</span>
            </h3>
            <p class="mt-3 mb-0 pb-0">
              Descubra eventos específicos de cada teatro
            </p>
          </div>
          <div class="col-12 p-0 mt-2 pt-3" style="" v-if="!genreListLoaded">
            <GenreFeaturesLoader
              :speed="2"
              :animate="true"
            ></GenreFeaturesLoader>
          </div>
          <div
            @click="goto('local', item.ds_nome_teatro)"
            class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0"
            style=""
            v-for="(item, index) in localsList"
            :key="index"
            v-else
          >
            <div>
              <div
                alt="image"
                class="img-fluid rounded card__home"
                :class="['card__home-' + index]"
              >
                <span
                  class="genre__ingressaria"
                  style="text-transform: uppercase"
                  >{{ item.ds_nome_teatro }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PROPAGANDA FIXA -->
    <section
      v-if="
        siteName != 'Bilheteria.com.br' &&
        siteName != 'febac.ingressoparatodos.com.br' &&
        siteName != 'live.bilheteria.com.br' &&
        siteName != 'ingressoparatodos.com.br' &&
        siteName != 'corporativo.bilheteria.com.br' &&
        siteName != 'games.bilheteria.com.br'
      "
      class="to-block team-1 mt-0 pt-0"
    >
      <div class="container">
        <div class="row row__events">
          <div class="col-12 col-sm-12 text-left mt-2 mb-2">
            <h3>
              Pelo Whatsapp
              <span style="font-size: 18.5px; color: rgb(119, 119, 119)"></span>
            </h3>
          </div>
          <div class="col-12 col-xl-4 col-md-6 p-2 text-left">
            <a
              href="https://api.whatsapp.com/send/?phone=5511996079544&text=Ol%C3%A1%21+Tenho+interesse+em+um+dos+seus+produtos&app_absent=0"
            >
              <div class="to-box p-0">
                <div
                  class="img-fluid rounded-0"
                  style="
                    background-size: cover;
                    background-image: url(https://www.ingressoparatodos.com.br/assets/laranjais.jpeg);
                  "
                ></div>
                <div
                  class="content to-box p-2 pt-0 pb-1"
                  style="
                    position: relative;
                    border-top-left-radius: 0px !important;
                    border-top-right-radius: 0px !important;
                  "
                >
                  <h4
                    class="event__title pb-1"
                    style="text-transform: uppercase !important"
                  >
                    <strong>THERMAS DOS LARANJAIS</strong>
                  </h4>
                  <p
                    class="p-0 pt-1 m-0 h-200 event__item"
                    style="font-size: 13px"
                  >
                    GARANTA JÁ A SUA RESERVA!
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-12 col-xl-4 col-md-6 p-2 text-left">
            <a href="https://www.thermas.com.br/ingresso/bilheteria5">
              <div class="to-box p-0">
                <div
                  class="img-fluid rounded-0"
                  style="
                    background-size: cover;
                    background-image: url(https://www.ingressoparatodos.com.br/assets/wpark.jpeg);
                  "
                ></div>
                <div
                  class="content to-box p-2 pt-0 pb-1"
                  style="
                    position: relative;
                    border-top-left-radius: 0px !important;
                    border-top-right-radius: 0px !important;
                  "
                >
                  <h4
                    class="event__title pb-1"
                    style="text-transform: uppercase !important"
                  >
                    <strong>THERMAS WATER PARK</strong>
                  </h4>
                  <p
                    class="p-0 pt-1 m-0 h-200 event__item"
                    style="font-size: 13px"
                  >
                    GARANTA JÁ A SUA RESERVA!
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- FIM PROPAGANDA FIXA -->

    <!-- mudança aqui BILHETERIA -->

    <div
      v-if="siteName != 'corporativo.bilheteria.com.br'"
      class="container__select to-block container__features"
      id="banner"
    >
      <div class="container pt-2 pb-0 text-left">
        <div class="col-12 col-sm-12 text-left mt-2 mb-2">
          <h3 class="">
            Explore nossas categorias
            <span style="font-size: 18.5px; color: #777">{{
              searchTerm !== "" ? "(" + searchTerm + ")" : ""
            }}</span>
          </h3>
          <p class="mt-3 mb-0 pb-0">
            Descubra eventos através das categorias mais procuradas
          </p>

          <div class="container__arrows">
            <div
              class="swiper-button-prev swiper-button-prev-genres"
              slot="button-prev"
            ></div>
            <div
              class="swiper-button-next swiper-button-next-genres"
              slot="button-next"
            ></div>
          </div>
        </div>
        <card-genre-list-ingressaria
          :genreList="genreList"
          :swiperOption="swiperOptionGenres"
        ></card-genre-list-ingressaria>
      </div>
    </div>

    <!-- fim mudança aqui BILHETERIA -->

    <!-- Banner -->
    <div
      :class="{
        'corporativo-trick-fix-margin':
          siteName == 'corporativo.bilheteria.com.br',
      }"
      class="card-categoria container__select to-block container__features"
      id="banner"
    >
      <!-- FLTRO CATEGORIA CORPORATIVO -->
      <!-- <div v-if="siteName == 'corporativo.bilheteria.com.br'" class="container pt-2 pb-0 text-left">
              <h3 v-if="siteName == 'corporativo.bilheteria.com.br'" class="">Explore nossas categorias <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
              <p v-if="siteName == 'corporativo.bilheteria.com.br'" class="mt-3 mb-0 pb-0">Descubra eventos através das categorias mais procuradas</p>
            </div>
            <div v-if="siteName == 'corporativo.bilheteria.com.br'" class="container">
             <div class="categoria-bilheteria box-categoria row text-left pb-3">
             <div style="display: inline-block" v-for="genre in genreNames" :key="genre.label" :onclick="`window.location = '/busca/genero/${genre.value}'`" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
                <div>
                  <div alt="image" class="img-fluid rounded card__home">
                    <img :src="genre.icon" width="38px" height="38px" class="mr-2"/>
                    <span class="genre__ingressaria" style="text-transform: uppercase">{{genre.label}}</span>
                </div>
                </div>
                </div>
              </div>
           </div> -->
      <!-- FILTRO CATEGORIAS -->
      <div
        v-if="
          categoriesList &&
          categoriesList.length > 0 &&
          siteName === 'corporativo.bilheteria.com.br'
        "
        class="container home pt-2 pb-0 text-left"
        style="position: sticky; top: 30px; z-index: 1004; background: #1f1f1f"
      >
        <div class="categories-title-container">
          <h3 style="display: inline-block">
            Explore nossas categorias e escolha o evento que mais lhe agrada
          </h3>
          <p
            v-if="
              filteredData &&
              filteredData.length > 0 &&
              ['corporativo.bilheteria.com.br'].indexOf(siteName) > -1
            "
            class="mb-0 pb-0"
            style="margin-top: 10px ;"
          >
            Eventos disponíveis: {{ filteredData.length }}
          </p>
        </div>
        <div
          class="row items-home"
          style="margin:  0px 0px 0px 0px !important; justify-content: center;"
        >
          <button
            v-for="(item, idx) in categoriesList"
            :key="idx"
            @click="redirectTo(item.name_cat)"
            style="
              margin: 12px;
              margin-left: 0px !important;
              padding: 0.5rem 2.2rem;
              color: rgb(0, 0, 0);
              width: auto;
            "
            class="text btn btn-primary shop_button_event"
          >
            {{ item.name_cat }}
          </button>
        </div>
      </div>
      <!-- FILTRO CATEGORIAS -->
      <div class="container pt-2 pb-0 text-left">
        <h3 class="">Experiências em destaque</h3>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0">
          Uma seleção de eventos para você
        </p>
        <div class="container__arrows">
          <div
            class="swiper-button-prev swiper-button-prev-featured"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next-featured"
            slot="button-next"
            style="width: 29px"
          ></div>
        </div>
        <card-event-featured
          :bannerEvents="bannerEvents"
          :swiperOption="swiperOption"
        ></card-event-featured>
      </div>

      <div
        v-if="
          partnerInfo.show_preferred_partners === 1 &&
          preferredPartners &&
          preferredPartners.length > 0 &&
          siteName === 'corporativo.bilheteria.com.br'
        "
        class="container pt-2 pb-0 text-left"
      >
        <h3 class="">Parceiros preferenciais</h3>
        <div class="container__arrows">
          <div
            class="swiper-button-prev swiper-button-prev-preferred-partner"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-next-preferred-partner"
            slot="button-next"
            style="width: 29px"
          ></div>
        </div>
        <card-preferred-partners
          class="card-partner"
          :bannerEvents="preferredPartners"
          :swiperOption="swiperPreferredPartner"
        ></card-preferred-partners>
      </div>
    </div>

    <!-- mudança aqui CORPORATIVO -->

    <!-- <div v-if="siteName == 'corporativo.bilheteria.com.br'" class="banner-categoria container__select to-block container__features" id="banner"  >
        <div class="container pt-2 pb-0 text-left">
          <div class="col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">Explore nossas categorias <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            <p class="mt-3 mb-0 pb-0">Descubra eventos através das categorias mais procuradas</p>
    
            <div class="container__arrows">
              <div class="swiper-button-prev swiper-button-prev-genres" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-next-genres" slot="button-next"></div>
            </div>
          </div>
          <card-genre-list-ingressaria :genreList="genreList" :swiperOption="swiperOptionGenres"></card-genre-list-ingressaria>
        </div>
      </div> -->

    <!-- fim mudança aqui CORPORATIVO -->
    <!-- <div class="container__select to-block container__features" id="banner" v-if="cityList.length > 1 ">
         <div class="container pt-2 pb-0 text-left">
          <h3 class="">Muito mais para você</h3>
    
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-cities" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-cities" slot="button-next"></div>
          </div>
          <card-cities :bannerEvents="bannerEvents" :swiperOption="swiperOptionCities"></card-cities>
        </div> 
      </div> -->

    <!-- Genêros em destaque -->
    <!--<div class="container__select to-block container__features" id="banner" v-if="siteName != 'Bilheteria.com.br' && siteName != 'convenia.bilheteria.com.br'">
        <div class="container pt-2 pb-0 text-left">
          <div class="col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">Explore nossas categorias <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            <p class="mt-3 mb-0 pb-0">Descubra eventos através das categorias mais procuradas</p>
    
            <div class="container__arrows">
              <div class="swiper-button-prev swiper-button-prev-genres" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-next-genres" slot="button-next"></div>
            </div>
          </div>
          <card-genre-list-ingressaria :genreList="genreList" :swiperOption="swiperOptionGenres"></card-genre-list-ingressaria>
        </div>
      </div>-->

    <section
      v-if="
        siteName !== 'corporativo.bilheteria.com.br' &&
        partnerInfo.show_partner_info == 0
      "
      class="to-block team-1 mt-0 pt-0"
      id="events"
    >
      <div class="container">
        <div class="row row__events">
          <div class="titulo-eventos col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">
              Próximos eventos {{ item.id_base
              }}<span style="font-size: 18.5px; color: #777">{{
                searchTerm !== "" ? "(" + searchTerm + ")" : ""
              }}</span>
            </h3>
            <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0">
              Encontre um evento com toda a facilidade que você precisa
            </p>
          </div>
          <p
            style="font-size: 16px; font-weight: bold"
            class="mt-3"
            v-if="filteredData.length == 0"
          >
            {{ filteredData.length == 0 ? "Nenhum evento encontrado" : "" }}
          </p>

          <card-event
            v-for="(item, index) in computedFilteredData"
            :key="index"
            :item="item"
          ></card-event>
        </div>
      </div>
    </section>

    <!-- listagem diferente para o corporativo -->
    <section
      v-if="
        siteName === 'corporativo.bilheteria.com.br' &&
        partnerInfo.show_partner_info == 0
      "
      class="to-block team-1 mt-0 pt-0"
      id="events"
    >
      <div class="container">
        <div class="row row__events">
          <div class="titulo-eventos col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">
              Próximos eventos {{ item.id_base
              }}<span style="font-size: 18.5px; color: #777">{{
                searchTerm !== "" ? "(" + searchTerm + ")" : ""
              }}</span>
            </h3>
            <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0">
              Encontre um evento com toda a facilidade que você precisa
            </p>
          </div>
          <p
            style="font-size: 16px; font-weight: bold"
            class="mt-3"
            v-if="filteredData.length == 0"
          >
            {{ filteredData.length == 0 ? "Nenhum evento encontrado" : "" }}
          </p>
          <card-genre-swiper
            :filteredData="filteredData"
            :getGenreNames="getGenreNames"
          ></card-genre-swiper>
        </div>
      </div>
    </section>

  </div>
</template>
    
    <script>
import $ from "jquery";
import Logo from "@/components/App-logo.vue";
import { func } from "@/functions";

import { citiesService } from "@/components/common/services/cities";
import { ModelSelect } from "vue-search-select";
import { serverBus } from "@/main";

import AppSearch from "@/components/App-search.vue";
import config from "@/config";
// import CarrouselLoader from '@/components/loaders/CarrouselLoader.vue';
import GenreFeaturesLoader from "@/components/loaders/GenreFeaturesLoader.vue";
// import CarrouselTextLoader from '@/components/loaders/CarrouselTextLoader.vue';

import CardGenreSwiper from "@/components/Card-genre-swiper.vue";
import CardEvent from "@/components/Card-event.vue";
import CardGenreList from "@/components/Card-genreList.vue";
import CardGenreListIngressaria from "@/components/Card-genreListIngressaria.vue";
import CardCityList from "@/components/Card-cityList.vue";
import CardCities from "@/components/Card-cities.vue";
import CardEventFeatured from "@/components/Card-eventFeatured.vue";
import CardPreferredPartners from "@/components/Card-preferred-partners.vue";
import BannerSlide from "@/components/Banner-slide.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { eventService } from "@/components/common/services/event";
import { discoveryService } from "@/components/common/services/discovery";
import CorporateDefaultBanner from "@/components/CorporateDefaultBanner";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";

import { partnerService } from "@/components/common/services/partner";
const partnersList = require.context("@/assets/partners");
export default {
  name: "Events",
  mixins: [func],
  data() {
    return {
      desc: "<h1>Teste</h1>",
      siteName: config.info.siteName,
      discoveryBanner: "",
      slideLoaded: false,
      cityList: [],
      genreListLoaded: false,
      discovery: [],
      slideData: [],
      ptBR: ptBR,
      format: "yyyy MM dd",
      cityList: [],
      localsList: [],
      genreList: [],
      date: "",
      nextEvents: [],
      bannerEvents: [],
      filteredData: [],
      preferredPartners: [],
      searchTerm: "",
      options: [],
      dateValue: "",
      optionsDate: [],
      item: {
        value: "",
        text: "",
      },
      itemDate: {
        value: "",
        text: "",
      },
      swiperOptionGenresWithoutImages: {
        autoplay: false,
        slidesPerView: 8,
        slidesPerGroup: 2,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-genres-without-text",
          prevEl: ".swiper-button-prev-genres-without-text",
        },
        breakpoints: {
          800: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
        },
      },
      swiperOption: {
        // loop: true,
        autoplay: true,
        // speed: 1000,
        // loopedSlides: 1,
        slidesPerView: 2,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-featured",
          prevEl: ".swiper-button-prev-featured",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperPreferredPartner: {
        // loop: true,
        autoplay: true,
        // speed: 1000,
        // loopedSlides: 1,
        slidesPerView: 4,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-preferred-partner",
          prevEl: ".swiper-button-prev-preferred-partner",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionGenres: {
        // loop: true,
        autoplay: true,
        // speed: 000,
        // loopedSlides: 1,
        slidesPerView: 3,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-genres",
          prevEl: ".swiper-button-prev-genres",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionCities: {
        // loop: true,
        // autoplay: true,
        // speed: 1000,
        // loopedSlides: 1,
        slidesPerView: 4,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-cities",
          prevEl: ".swiper-button-prev-cities",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      swiperOptionBanner: {
        //loop: true,
        autoplay: true,
        speed: 1000,
        //loopedSlides: 1,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next-banner",
          prevEl: ".swiper-button-prev-banner",
        },
        breakpoints: {
          800: {
            slidesPerView: 1,
          },
        },
      },
      partnerInfo: "",
      isMobile: false
    };
  },
  components: {
    CorporateDefaultBanner,
    ModelSelect,
    AppSearch,
    // CarrouselLoader,
    // CarrouselTextLoader,
    CardGenreSwiper,
    CardEvent,
    BannerSlide,
    GenreFeaturesLoader,
    CardGenreList,
    CardGenreListIngressaria,
    CardCityList,
    Datepicker,
    CardCities,
    CardEventFeatured,
    CardPreferredPartners,
  },

  methods: {
    redirectTo(to) {
      this.$router.push({ name: "search_bycategory", params: { input: to } });
    },
    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    reset() {
      this.item = {};
    },
    selectFromParentComponent1() {
      // select option from parent component
      this.item = this.options[0];
    },
    clearDate() {
      // console.log(this.dateValue);
    },

    next() {},
    prev() {},
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        //this.$refs.slick.reSlick();
      });
    },
    search(type, item) {
      this.$router.push("/busca/" + type + "/" + item);
    },
    goto(type, item) {
      if (item.notselectable != undefined && item.notselectable == 1) return;

      let where = item.type != undefined ? item.type : type;

      switch (where) {
        case "local":
          this.$router.push("/busca/local/" + item);
          break;
        case "genre":
          this.$router.push("/busca/genero/" + item);
          break;
        case "city":
          this.$router.push("/busca/cidade/" + item);
          break;
        case "state":
          this.$router.push("/busca/estado/" + item);
          break;
        case "event":
          this.$router.push(item.uri);
          break;
        case "discovery":
          window.location.href = item;
          break;
      }
    },

    selectDate(data) {
      // console.log(data);
      if (data == "1970-01-01") return;
      if (data == null) return;

      this.date = new Date(data).toISOString().split("T")[0];

      this.refreshEffect();

      this.getListResultsFiltered();
    },
    refreshEffect() {
      var banner = document.getElementById("banner");
      var features = document.getElementById("features");
      var events = document.getElementById("events");

      $("#banner").fadeOut();
      $("#features").fadeOut();
      $("#events").fadeOut();

      $("#banner").fadeIn();
      $("#features").fadeIn();
      $("#events").fadeIn();
    },
    handleOnInput($event) {
      this.item = $event;

      if (this.item.value == "all") {
        // this.filteredData = this.slideData;
        this.searchTerm = "";
        // return;
      } else {
        this.searchTerm = this.item.text;
      }

      this.refreshEffect();

      // console.log(this.searchTerm);
      this.getListResultsFiltered();
    },
    resetEvents() {
      this.date = "";

      this.getListResultsFiltered();
    },
    getListResultsFiltered() {
      // console.log('aqui', this.searchTerm);
      eventService
        .list(this.searchTerm, this.locale.state.name, this.date)
        .then(
          (response) => {
            this.filteredData = response;
            this.hideWaitAboveAll();
          },
          (error) => {
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
    },
    getListResultAgain() {
      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );
          this.hideWaitAboveAll();
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    removeDuplicatesBy(keyFn, array) {
      var mySet = new Set();
      return array.filter(function (x) {
        var key = keyFn(x).toUpperCase(),
          isNew = !mySet.has(key);
        if (isNew) mySet.add(key);
        return isNew;
      });
    },
    getCities() {
      citiesService.get().then(
        (response) => {
          if (response !== null) {
            let temp = response.filter(
              (x) => x.img !== undefined && x.img !== null && x.img !== ""
            );

            this.cityList = this.removeDuplicatesBy(
              (x) => x.ds_municipio,
              temp
            );
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getCityList() {
      this.cityList = this.removeDuplicatesBy(
        (x) => x.ds_municipio,
        this.slideData
      );
    },
    getGenreList() {
      let temp = this.removeDuplicatesBy((x) => x.genreName, this.filteredData);
      temp.push({ id_genre: "0002", genreName: "PROMOÇÕES" });
      this.genreList = this.shuffle(temp);

      this.genreListLoaded = true;
    },
    getLocalsList() {
      this.localsList = this.removeDuplicatesBy(
        (x) => x.ds_nome_teatro,
        this.filteredData
      ).slice(0, 6);

      // console.log(this.localsList);
    },
    getNextEvents() {
      this.nextEvents = this.slideData.slice(1, 5);
    },
    populateCityPicker() {
      this.options.push({
        value: "all",
        text: "Todas as Cidades",
      });
      this.cityList.map((x) => {
        this.options.push({
          value: x.ds_municipio,
          text: x.ds_municipio.toLowerCase().replace(/^\w/, (x) => {
            return x.toUpperCase();
          }),
        });
      });
    },
    getBanner() {
      eventService.banner(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.bannerEvents = response;
          this.slideLoaded = true;
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    getDiscovery() {
      // console.log(this.key);
      discoveryService.list(this.key).then(
        (response) => {
          // console.log(response);
          this.discovery = response.filter((x) => x.type !== "banner");
          this.discoveryBanner = response.filter((x) => x.type == "banner");
          response.forEach((x) => {
            if (x.type && x.type === "card") {
              x.img = x.imageURI;
              x.genreName = "PROMOÇÕES";
              x.id_genre = "0002";
              x.datas = "";
              x.ds_evento = x.title;
              this.slideData.push(x);
            }
          });
          //console.log("AQUI");
          //console.log(this.discoveryBanner.map(x => x.title));
          //console.log("AQUI");
        },
        (error) => {
          this.toastError("Falha na execução.");
        }
      );
    },
    getListResults(callback) {
      // this.getLocation(this.getListResultAgain);

      eventService.list(this.locale.city.name, this.locale.state.name).then(
        (response) => {
          this.slideData = response.filter(
            (x) => x.id_genre !== undefined && x.id_genre !== null
          );

          this.filteredData = this.slideData;

          // IMPLEMENTANDO FILTRO DE EVENTOS PRIORIDADE BASE BILHETERIA
          // this.filteredData = this.filteredData.sort(function (a) {
          //   if (a.partner === "BILHETERIA.COM (PROD)") return -1;
          //   return 0;
          // });
          // IMPLEMENTANDO FILTRO DE EVENTOS PRIORIDADE BASE BILHETERIA

          this.hideWaitAboveAll();
          this.isLoaded = true;

          this.getCityList();
          this.getGenreList();
          this.getLocalsList();
          this.getNextEvents();

          this.populateCityPicker();

          // console.log(this.slideData);

          if (callback !== null && callback !== undefined) {
            callback();
          }
        },
        (error) => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    scrollToTop() {
      // When arrow is clicked
      $("body,html").animate(
        {
          scrollTop: 0, // Scroll to top of body
        },
        500
      );
    },
    getPartnerInfo() {
      partnerService.getinfo().then((response) => {
        if (response.error) {
          console.log("responseerro", response.error);
        }
        if (this.validateJSON(response)) {
          this.partnerInfo = response;
        }
      });
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 760;
    if (
      ["corporativo.bilheteria.com.br", "Bilheteria.com.br"].indexOf(
        this.siteName
      ) > -1 &&
      partnersList &&
      partnersList.keys().length > 0
    ) {
      partnersList.keys().forEach((partner) => {
        const imagePath = partner.split("./")[1];
        this.preferredPartners.push({
          img: require("@/assets/partners/" + imagePath),
        });
      });
    }
    this.getPartnerInfo();
  },
  computed: {
    getGenreNames() {
      return [
        ...new Set(this.filteredData.map((item) => item.genreName)),
      ].sort();
    },
    categoriesList() {
      return this.$store.state.categoriesList;
    },
    computedFilteredData() {
      let ret = this.filteredData;

      this.discovery.map((x) => {
        if (x.index == 0) {
          const j = Math.floor(Math.random() * ret.length);
          ret.splice(j, 0, x);
        } else {
          if (x.index > ret.length) {
            ret.push(x);
          } else {
            ret.splice(x.index, 0, x);
          }
        }
      });

      // console.log(ret);

      return ret;
    },
  },
  created() {
    this.getListResults(this.getDiscovery);
    this.getBanner();
    this.getCities();

    // Fixa navbar ao ultrapassa-lo
    var navbar = $("#navbar"),
      distance = navbar.offset().top,
      $window = $(window);

    $window.scroll(function () {
      if ($window.scrollTop() >= 140) {
        navbar.removeClass("navbar-fixed-top").addClass("navbar-fixed-top");
      } else {
        navbar.removeClass("navbar-fixed-top");
      }

      if ($window.scrollTop() > 400) {
        $("#btn__scroll").css("opacity", 1);
      } else {
        $("#btn__scroll").css("opacity", 0);
      }
    });
  },
  beforeUpdate() {
    //if (this.$refs.slick) {
    //this.$refs.slick.destroy();
    //}
  },
  updated() {
    //if (this.$refs.slick && !this.$refs.slick.$el.classList.contains('slick-initialized')) {
    //this.$refs.slick.create();
    //}
  },
  filters: {
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
    replace: function (message, toReplace, replacement) {
      return message.replace(toReplace, replacement);
    },
    capitalize: function (value) {
      if (!value) return "";

      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>
    
<style lang="scss">

.to-block .to-box {
  box-shadow: none !important;
}
.corporativo-trick-fix-margin {
  margin-top: -1px !important;
}
.select__city {
  .dropdown {
    width: 140px;
    float: right;
  }
}

.swal2-container.swal2-top-end.swal2-fade.swal2-shown {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 200px !important;
  margin: 105px 5px 5px 5px !important;
}

.swal2-popup.swal2-toast.alert__tixsme.alert_error.swal2-show {
  width: 40% !important;
  position: relative !important;
  margin: auto !important;
  text-transform: uppercase !important;
  height: 200px !important;

  @media only screen and (max-width: 600px) {
    width: 90% !important;
  }
}

.swal2-popup.swal2-toast.alert__tixsme.alert__sucess.swal2-show {
  width: 40% !important;
  position: relative !important;
  margin: auto !important;
  text-transform: uppercase !important;
  height: 200px !important;

  @media only screen and (max-width: 600px) {
    width: 90% !important;
  }
}
.txt-filtro-cat {
  text-transform: uppercase;
}
.card__home {
  width: 100%;
}

.categories-title-container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 929px) {
  .categories-title-container {
    display: block !important;
  }
}

@media screen and (max-width: 515px) {
  .container.home {
    top: 0px !important;
    .categories-title-container {
      h3 {
        font-size: 17px;
      }
      p {
        font-size: 15px;
      }
    }
    .items-home {
      flex-direction: column;
      height: 60px;
      overflow: auto;
      .shop_button_event {
        width: auto !important;
        font-size: 9px !important;
        height: 35px !important;
        padding: 5px !important;
        margin: 5px 5px !important;
        font-weight: bold;
      }
    }
  }
}

.card-partner {
  .to-box.p-0 {
    background: transparent !important;
  }
}
</style>
    