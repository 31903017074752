import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const searchService = {
  autocomplete,
  searchPage,
  getInfoLocal
}

function autocomplete(input, id_genre, id_municipio, date_when, amount) {
  let url = config.api + `/v1/search/autocomplete?input=${input}&id_genre=${id_genre}&id_municipio=${id_municipio}&date_when=${date_when}&amount=${amount}`;
  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        });
      });
    }
  );
  return ret;
}

function searchPage(type = null, input = null, startAt = null, howMany = null, city = null, state = null, id_genre = null, id_municipio = null, date_when = null, amount = null) {
  let url = config.api + `/v1/search/result?input=${input}&type=${type}&id_genre=${id_genre}&id_municipio=${id_municipio}&date_when=${date_when}&amount=${amount}`;
  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        });
      });
    }
  );
  return ret;
}

function getInfoLocal(search) {
  let url = config.api + `/v1/search/info_local?search=${search}`;
  var ret = new Promise(
    function (resolve, reject) {
      Vue.http.get(url).then(res => {
        resolve(res.body);
      }, err => {
        reject({
          error: true,
          msg: err
        });
      });
    }
  );
  return ret;
}
