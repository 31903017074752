<template>
<div class="col-12 col-md-2 container__logo">
  <div class="logo">
    <router-link to='/'>
      <div class="logo-img"></div>
    </router-link>
  </div>
</div>
</template>

<script>
export default {
  name: "AppLogo",
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
</style>
