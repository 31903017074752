import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';
import axios from 'axios';

Vue.use(VueResource);

config.setapikey();

export const corporateService = {
    auth,
    reenviarAcesso,
    enviarConvenio,
    validarCodigo,
    verifyAccess,
    requestRegister,
    cadastrarEmpresa,
    getCnpjInfo,
    notifyTriggers
}

function auth(code) {
    let url = config.api + `/v1/corporate/auth`;

    let obj = {
        code
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function validarCodigo(code) {
    let url = config.api + `/v1/corporate/checkcode`;
    let obj = {
        code
    };

    const ret = new Promise((resolve, reject) => {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                console.log('validarCodigo: ');
                console.log(res.body);
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

async function getCnpjInfo(cnpj) {
    let url = config.api + `/v1/sintegra/validar_cnpj.php?cnpj=${cnpj}`;
    const res = await Vue.http.get(url, { emulateJSON: true });
    return res.body;
}
    
async function cadastrarEmpresa(dadosEmpresa) {
    const url = 'https://bws.bilheteria.com/api/cadastrarEmpresa';
    const headers = {
      'Cookie': 'PHPSESSID=dvb3ils81qg3mdvf532vi51f5f',
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    let formData = '';
    for (const key in dadosEmpresa) {
      if (formData !== '') {
        formData += '&';
      }
      formData += `${encodeURIComponent(key)}=${encodeURIComponent(dadosEmpresa[key])}`;
    }
  
    try {
      const response = await axios.post(url, formData, { headers });
      return response;
    } catch (error) {
      console.error('Erro ao fazer requisição:', error.message);
      throw error;
    }
  }
async function notifyTriggers(form) {
    let url = config.api + `/v1/corporate/notificar_solicitacao`;
    const res = await Vue.http.post(url, form, { emulateJSON: true });
    return res.body;
}

async function verifyAccess(form) {
    let url = config.api + `/v1/corporate/verificar-acesso`;

    let obj = {
        searchTerm: form.pass,
        ip: form.ip,
    };

    const res = await Vue.http.post(url, obj, { emulateJSON: true });
    return res.body;
}

async function requestRegister(form) {
    let url = config.api + `/v1/corporate/solicitar-registro`;

    const res = await Vue.http.post(url, form, { emulateJSON: true });
    return res.body;
}
function reenviarAcesso(form) {
    let url = config.api + `/v1/corporate/reenviaracesso`;

    let obj = {
        nome: form.nome_n,
        email: form.email_e,
        corporate_id: form.corporate_id,
        nome_empresa: form.company_c,
        ddd: form.ddd_d,
        celular: form.phone_p,
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}

function enviarConvenio(form) {
    let url = config.api + `/v1/corporate/enviarconvenio`;

    let obj = {
        nome: form.requester,
        cargo: form.office,
        nome_empresa: form.company,
        email: form.email,
        ddd: form.ddd,
        celular: form.phone,
        numero_colaboradores: form.contributors,
        cep: form.cep,
        endereco: form.address,
        cidade: form.city,
        estado: form.city_state,
        filiais_em: form.branches,
        como_soube_programa: form.question1,
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });
        }
    );
    return ret;
}