<template>
    <div class="modal-register-cdb">
      <div class="content-register-cdb" :style="{'padding': screen > 500 ? '40px 60px !important' : '8px !important'}">
        <span class="material-symbols-outlined close-icon-small-register" @click="closeModalRegister()">close</span>
        <div class="header-register-cdb">
          <h4 class="name-register-cdb">{{ name }}</h4>
          <h2 class="title-register-cdb">RESPONSÁVEL PELO CIRCO</h2>
        </div>
        <div class="form-register-cdb forgot-password-container-cdb">
          <input type="text" class="form-register-input" v-model="formRegister.email_responsavel" placeholder="Digite seu e-mail para enviarmos uma nova senha">
        </div>
        <div class="send-info-register">
          <button class="btn-send-register" @click="nextStep()">Solicitar nova senha</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { cdbService } from "@/components/common/services/cdb_service"
  
  export default {
    name: 'ModalForgotPassword',
    components: {},
    props: ['name','id'],
    data () {
      return {
        formRegister: {
          email_responsavel: '',
          equipamento_id: null
        },
      }
    },
    mounted () {},
    computed: {
      screen () { return screen.availWidth }
    },
    methods: {
      nextStep () {
        //fazer login 
         this.formRegister.equipamento_id=this.id;
         if(this.formRegister.email == '') {
          this.$swal({
            title: "É necessário informar os dados de acesso",
            type: "error",
            customClass: 'swal-alert-to-cdb',
            background: '#f4e9da',
            showConfirmButton: true,
            timer: 4000
          })
        } else {
         
          cdbService.forgotPassword(this.formRegister).then(
            response => {
                this.$swal({
                  title: "Caso você tenha um cadastro, enviaremos um e-mail com as instruções.",
                  type: "success",
                  customClass: 'swal-alert-to-cdb',
                  background: '#f4e9da',
                  showConfirmButton: false,
                  timer: 4000,
                })
            },
            error => {
              this.$swal({
                title: "Ocorreu um erro, tente novamente",
                type: "error",
                customClass: 'swal-alert-to-cdb',
                background: '#f4e9da',
                showConfirmButton: false,
                timer: 4000,
              })
            }
          )
        }
       
      },
      closeModalRegister () {
        this.$root.$emit('close-modal-forgot-password')
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap');
  .modal-register-cdb {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #42424280;
    .content-localization-cdb {
      font-family: 'Alegreya Sans', sans-serif;
      padding: 40px 60px !important;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
      position: relative;
      z-index: 10;
      width: 750px;
      min-height: 60%;
      background: #F4E9DA;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #212425;
      .header-localization-cdb {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .title-localization-cdb {
          font-size: 48px;
        }
        .name-localization-cdb {
          width: 90%;
          text-align: center;
          font-size: 26px;
          background: #eba943;
          color: #FFFFFF;
          border-radius: 8px;
          padding: 0 10px !important;
        }
      }
      .form-localization-cdb {
        width: 90%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 8px;
        .state-and-city-localization {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .vs__dropdown-toggle {
            height: 40px;
            border-radius: 8px;
            border: none;
            .vs__selected-options {
              padding-left: 10px !important;
              .vs__selected {
                font-size: 21px;
              }
            }
            .vs__actions {
              padding-right: 10px !important;
              .vs__clear {
                display: none;
              }
            }
          }
          .input-state-localization-cdb {
            width: 150px;
          }
          .input-city-localization-cdb {
            width: 360px;
          }
        }
        .form-localization-input {
          font-size: 21px;
          font-family: 'Alegreya Sans', sans-serif;
          width: 100%;
          height: 40px;
          padding-left: 10px !important;
          outline: none;
          border-radius: 8px;
          border: none;
        }
        .period-localization {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title-period {
            font-size: 21px;
          }
          .date-pickers-cdb {
            padding: 0 5px !important;
            display: flex;
            flex: 1;
            justify-content: space-between;
            .input-date-cdb {
              width: 150px;
              font-size: 21px;
              border-radius: 8px;
              border: none;
              color: #000000;
              background: #ffffff;
              text-align: center;
              cursor: pointer;
            }
            .calendar-date-cdb-initial, .calendar-date-cdb-final {
              background: #ffffff;
              border-radius: 8px;
              left: -50%;
              border-color: #eba943;
              .selected {
                background: #eba943;
                border-radius: 8px;
              }
              .cell:hover {
                border: 1px solid #eba943;
                border-radius: 8px;
              }
            }
          }
        }
      }
      .send-info-localization {
        width: 90%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0 !important;
        .btn-send-localization, .btn-localization-cdb {
          width: 200px;
          height: 35px;
          border: none;
          border-radius: 8px;
          font-size: 21px;
          background: #eba943;
          cursor: pointer;
          color: #FFFFFF;
          outline: none;
        }
        .btn-localization-cdb {
          width: 280px;
        }
      }
    }
    .forgot-password-container-cdb {
        height: 20% !important;
    }
    .content-others-cdb {
      font-family: 'Alegreya Sans', sans-serif;
      padding: 40px 60px !important;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
      position: relative;
      z-index: 10;
      width: 750px;
      min-height: 540px;
      max-height: 95%;
      background: #F4E9DA;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #212425;
      .header-others-cdb {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .title-others-cdb {
          text-align: center;
          font-size: 38px;
        }
        .name-others-cdb {
          width: 90%;
          text-align: center;
          font-size: 26px;
          background: #eba943;
          color: #FFFFFF;
          border-radius: 8px;
          padding: 0 10px !important;
        }
      }
      .form-others-cdb {
        width: 90%;
        height: 600px;
        overflow-y: auto;
        padding-top: 10px !important;
        .form-others-input {
          width: 100%;
          height: 30px;
          font-size: 16px;
          padding-left: 10px !important;
          border-radius: 8px;
          outline: none;
          border: none;
          margin-bottom: 5px !important;
        }
        .current-period-form {
          height: 60px;
          .title-period-form {
            padding-left: 5px !important;
            font-size: 17px;
            color: rgb(77, 77, 77);
          }
          .period-form {
            display: flex;
            justify-content: space-between;
            .form-others-input-period {
              width: 49%;
              height: 30px;
              font-size: 16px;
              padding-left: 10px !important;
              border-radius: 8px;
              outline: none;
              border: none;
              margin-bottom: 5px !important;
            }
          }
        }
      }
      .send-info-others {
        width: 90%;
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .btn-send-others {
          width: 200px;
          height: 35px;
          border: none;
          border-radius: 8px;
          font-size: 21px;
          background: #eba943;
          cursor: pointer;
          color: #FFFFFF;
        }
        .title-suport {
          font-size: 21px;
        }
      }
    }
    .label-info-register {
      color: black;
      font-size: 35px;
      font-weight: 600;
      margin-top: 10px !important;
      text-align: center;
    }
    .close-icon-small-register {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      color: #BF3D26;
      border: 2px solid #BF3D26;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .content-localization-cdb {
      .header-localization-cdb {
        justify-content: center !important;
        .title-localization-cdb {
          font-size: 25px !important;
          margin-bottom: 10px !important;
        }
        .name-localization-cdb {
          font-size: 20px !important;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .form-localization-cdb {
        gap: 10px;
        .state-and-city-localization {
          .input-state-localization-cdb {
            width: 60px !important;
            margin-right: 5px !important;
          }
          .input-city-localization-cdb {
            width: 200px !important;
          }
        }
        .period-localization {
          flex-direction: column !important;
          align-items: flex-start !important;
          height: 90px !important;
          .date-pickers-cdb {
            width: 100% !important;
            align-items: center;
            .input-date-cdb {
              width: 130px !important;
              font-size: 17px !important;
            }
            .calendar-date-cdb-initial {
              left: 23px !important;
            }
            .calendar-date-cdb-final {
              left: -195px !important;
            }
          }
        }
      }
      .send-info-localization {
        width: 100% !important;
        .btn-send-localization {
          width: 90px !important;
          font-size: 17px !important;
        }
        .btn-localization-cdb {
          width: 180px !important;
          font-size: 15px !important;
        }
      }
    }
    .content-others-cdb {
      height: 95vh;
      .header-others-cdb {
        height: 13% !important;
        justify-content: center !important;
        .title-others-cdb {
          font-size: 20px !important;
          margin-bottom: 10px !important;
        }
        .name-others-cdb {
          font-size: 20px !important;
          height: 30px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .form-others-cdb {
        overflow-y: auto !important;
        height: 74% !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: space-between !important;
        padding-top: 5px !important;
        .form-others-input {
          margin-bottom: 5px !important;
        }
      }
      .send-info-others {
        height: 13% !important;
        .btn-send-others {
          width: 130px !important;
          font-size: 17px !important;
        }
        .title-suport {
          font-size: 17px !important;
        }
      }
    }
    .label-info-register {
      font-size: 20px !important;
      margin-top: 15px !important;
    }
  }
  </style>