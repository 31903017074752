<template>
  <div class="row p-3">
    <swiper :options="swiperOption" class="col-12 pb-0 mb-0">
      <swiper-slide
        v-for="(item, index) in bannerEvents"
        :key="index"
        class="col-xl-3 col-md-3 col-sm-12 p-0 pb-0 pt-0 text-left"
      >
        <div class="pr-1">
          <div class="to-box p-0">
            <div
              style="background-size: cover;height: 170px !important;background-repeat: no-repeat;background-position: center;"
              :style="{ backgroundImage: `url(${item.img})` }"
            ></div>
            <!-- <div class="partner__badge"><i class="fa fa-lg fa-handshake"></i>Compreingressos</div> -->
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import config from "@/config";
import VueAwesomeSwiper from "vue-awesome-swiper";

export default {
  name: "CardPreferredPartners",
  computed: {
    classFilterWhite() {
      if (this.siteName == "ingressoparatodos.com.br") return "filter-white";
      return "";
    },
    siteName() {
      return config.info.siteName;
    },
    getDayForCache() {
      const date = new Date();
      return date.getDate() + "" + (date.getMonth() + 1);
    },
  },
  methods: {
    goto(type, item) {
      if (item.notselectable != undefined && item.notselectable == 1) return;

      let where = item.type != undefined ? item.type : type;

      switch (where) {
        case "local":
          this.$router.push("/busca/local/" + item);
          break;
        case "genre":
          this.$router.push("/busca/genero/" + item);
          break;
        case "city":
          this.$router.push("/busca/cidade/" + item);
          break;
        case "state":
          this.$router.push("/busca/estado/" + item);
          break;
        case "event":
          this.$router.push(item.uri);
          break;
        case "discovery":
          window.location.href = item;
          break;
      }
    },
  },
  props: ["bannerEvents", "swiperOption"],

  filters: {
    truncate: function (text, length, clamp) {
      if (text == null || text == undefined) return;

      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content;
      // return content.length > length ? content.slice(0, length) + clamp : content;
    },
    replace: function (message, toReplace, replacement) {
      if (message == null || message == undefined) return;
      return message.replace(toReplace, replacement);
    },
    capitalize: function (value) {
      if (value == null || value == undefined) return;

      if (!value) return "";

      value = value.toString();
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
