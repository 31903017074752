import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const sendService = {
  indiqueCirco,
  sendFaleConosco
 }

function   indiqueCirco(obj) {
  let url = config.api + `/v1/help/indiquecirco?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, { circo_uf: obj.uf, circo_cidade: obj.cidade, circo_email: obj.email, circo_name: obj.nameCirco, circo_phone: obj.phone, circo_contactName: obj.name }, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}

function sendFaleConosco(obj) {
  let url = config.api + `/v1/help/faleconsoco?apikey=${config.apikey}`;

  var ret = new Promise(
      function (resolve, reject) {
          Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
              resolve(res.body);
          }, err => {
              reject({
                  error: true,
                  msg: err
              });
          });    
      }
  );
  return ret;
}