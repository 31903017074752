<template>
    <div class="status-bar-container" :class="{'just-floating-container': !allowUserContinueScrolling}">
        <div class="status-bar-message" :class="{ 'showBanner': showBanner, 'hideBanner': !showBanner }">
            <i v-if="showClose" @click="hideMessage" class="fa fa-times-circle icon-close"></i>
            <div style="width: 80%;">
                <p class='bar-title'>{{ title }}</p>
                <p class="bar-message">{{ message }}
                </p>
            </div>
            <div v-if='showButton' @click="hideMessage" class="exit-button">OK</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Messager',
    data() {
        return {
            showBanner: true,
        };
    },
    props: {
        message: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        showButton: {
            type: Boolean,
            default: false,
        },
        showClose: {
            type: Boolean,
            default: true
        },
        allowUserContinueScrolling: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        hideMessage() {
            this.showBanner = false;
            this.$emit('closed');
        },
    },
};
</script>
<style scoped lang='scss'>
.status-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: hsla(0, 0%, 0%, 0.72);
    z-index: 999999;
    height: 100%;
    width: 100%;
    & .icon-close {
        position: absolute; 
        top: 6px;  
        font-size: 16px; 
        cursor: pointer;
        color: black;
        left: 10px;
    }
    & .bar-title {
        margin: 0px; 
        font-size: 0.9rem; 
        color: white;
        font-weight: bold;
    }
    & .bar-message {
        margin: 0;
        font-size: 0.8rem;
        color: white;
    }
}
.just-floating-container {
    top: 20%  !important;
    height: auto !important;
    background: none !important;
}
.status-bar-message {
    position: relative;
    top: 25%;
    padding: 10px;
    background: hsla(0, 0%, 0%, 0.72);
    color: #fff;
    text-align: center;
    z-index: 999999;
    display: flex;
    height: auto;
    background: linear-gradient(90deg, rgb(243, 143, 43) 0%, rgb(232, 65, 105) 100%);
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    margin: 0 auto;
    box-shadow: 5px 5px 0px 0px #f38d2c;
    margin-bottom: 18px;
    width: 40vw;
    
}

.showBanner {
    animation: slideDown 0.5s forwards;
}

.hideBanner {
    animation: slideUp 1s forwards;
}

.exit-button {
    width: 90px;
    background: white;
    border: medium;
    font-size: 0.8rem;
    font-weight: 200;
    color: black;
    border-radius: 0.3rem;
    padding: 0.3rem;
    box-shadow: 4px 5px 0px 0px #212121;
    border: 2px solid #040404;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .status-bar-message {
        width: 90vw;
    }
}

@keyframes slideDown {
    from {
        top: -100px;
        /* Start offscreen */
    }

    to {
        top: 10;
        /* Slide down to top of viewport */
    }
}

@keyframes slideUp {
    from {
        top: 10;
        /* Start offscreen */
    }

    to {
        top: -200px;
        position: relative;
        /* Slide down to top of viewport */
    }
}
</style>