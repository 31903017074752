<template>
  <div class="container-modal-cdb" v-if="showModal">
    <div class="section-modal-cdb" :style="{'padding': screen > 500 ? '30px 60px !important' : '8px !important'}">
      <div class="header-modal-cdb">
        <h2 class="title-modal-cdb">RESPONSÁVEL PELO CIRCO</h2>
        <p class="text-modal-cdb">Donos e responsáveis por circos, incluam seus circos neste site de divulgação e usufruam das vantagens oferecidas por este programa de apoio à arte Circense.</p>
      </div>
      <div class="info-modal-cdb">
        <label class="label-cdb-form" for="form-cdb-name-circo">
          <p class="name-label-cdb-form">Nome do circo *</p>
          <input type="text" id="form-cdb-name-circo" class="form-input-cdb" v-model="formContact.name_circo" placeholder="Nome do circo">
        </label>
        <label class="label-cdb-form" for="form-cdb-cnpj-circo">
          <p class="name-label-cdb-form">CNPJ do circo *</p>
          <input type="text" id="form-cdb-cnpj-circo" class="form-input-cdb" v-model="formContact.cnpj_circo" @input="maskCNPJ" placeholder="CNPJ do circo">
        </label>
        <div class="cdb-form-content-checkbox">
          <p class="name-label-cdb-form">Tipo *</p>
          <div class="section-checkbox-cdb-form">
            <label for="owner-checkbox" class="label-cdb-form">
              <input
                type="checkbox"
                class="checkbox-cdb-select"
                name="owner-checkbox"
                id="owner-checkbox"
                v-model="isOwner"
                @change="onCheckboxChange('owner')"
                >
              <p class="label-checkbox-cdb">Dono</p>
            </label>
            <label for="admin-checkbox" class="label-cdb-form">
              <input
                type="checkbox"
                class="checkbox-cdb-select"
                name="admin-checkbox"
                id="admin-checkbox"
                v-model="isAdmin"
                @change="onCheckboxChange('admin')"
                >
              <p class="label-checkbox-cdb">Administrador</p>
            </label>
          </div>
        </div>
        <label class="label-cdb-form" for="form-cdb-name">
          <p class="name-label-cdb-form">Nome *</p>
          <input type="text" id="form-cdb-name" class="form-input-cdb" v-model="formContact.name" placeholder="Digite seu nome">
        </label>
        <label class="label-cdb-form" for="form-cdb-mail">
          <p class="name-label-cdb-form">E-mail *</p>
          <input type="text" id="form-cdb-mail" class="form-input-cdb" v-model="formContact.email" placeholder="Digite seu e-mail">
        </label>
        <label class="label-cdb-form" for="form-cdb-phone">
          <p class="name-label-cdb-form">Telefone *</p>
          <input type="text" id="form-cdb-phone" class="form-input-cdb" v-model="formContact.phone" @input="maskPhone" placeholder="Digite seu telefone">
        </label>
        <label class="label-cdb-form" for="form-cdb-password">
          <p class="name-label-cdb-form">Senha *</p>
          <input type="password" id="form-cdb-password" class="form-input-cdb" v-model="formContact.password" placeholder="Senha">
        </label>
        <label class="label-cdb-form" for="form-cdb-confirm-password">
          <p class="name-label-cdb-form">Confirmar senha *</p>
          <input type="password" id="form-cdb-confirm-password" class="form-input-cdb" v-model="formContact.confirm_password" placeholder="Confirmar senha">
        </label>
        <label class="label-cdb-form-text" for="form-cdb-message">
          <p class="name-label-cdb-form">Mensagem *</p>
          <textarea class="form-input-cdb-text" id="form-cdb-message" v-model="formContact.content" />
        </label>
      </div>
      <div class="confirm-contact-cdb">
        <button class="confirm-info-cdb" @click="sendFaleConosco" :disabled="disableButton">OK</button>
        <button class="close-info-cdb" @click="closeModalCdb()">
          <span class="material-symbols-outlined close-icon-small">close</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { sendService} from "@/components/common/services/send"

export default {
  name: 'ModalContactCdb',
  components: {},
  props: [],
  data () {
    return {
      disableButton: false,
      isOwner: false,
      isAdmin: false,
      showModal: false,
      formContact: {
        name_circo: '',
        cnpj_circo: '',
        type_owner: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: '',
        content: ''
      }
    }
  },
  mounted () {
    this.$root.$on('open-modal-contact', payload => {
      this.showModal = payload
    })
  },
  computed: {
    screen () { return screen.availWidth }
  },
  methods: {
    removeSpecialCharacters(str) {
      return str.replace(/[^a-zA-Z0-9]/g, '')
    },
    maskPhone (event) {
      let phone = event.target.value.replace(/\D/g, '')
      if (phone.length > 11) { phone = phone.substring(0, 11) }

      phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d{5})(\d)/, "$1-$2")

      this.formContact.phone = phone
    },
    maskCNPJ(event) {
      let cnpj = event.target.value.replace(/\D/g, '')
      if (cnpj.length > 14) { cnpj = cnpj.substring(0, 14) }

      cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
                  .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                  .replace(/\.(\d{3})(\d)/, ".$1/$2")
                  .replace(/(\d{4})(\d)/, "$1-$2")

      this.formContact.cnpj_circo = cnpj
    },
    closeModalCdb () {
      this.showModal = false
      Object.keys(this.formContact).forEach(key => { this.formContact[key] = '' })
    },
    onCheckboxChange(role) {
      if (role === 'owner') {
        this.isOwner = true
        this.isAdmin = false
        this.formContact.type_owner = 'dono'
      } else if (role === 'admin') {
        this.isAdmin = true
        this.isOwner = false
        this.formContact.type_owner = 'administrador'
      } else {
        this.isOwner = false
        this.isAdmin = false
        this.formContact.type_owner = ''
      }
    },
    sendFaleConosco() {
      this.disableButton = true

      if (!Object.keys(this.formContact).filter(key => key !== 'content').every(key => this.formContact[key] !== '')) {
        this.$swal({
          title: 'É necessário informar todos os dados',
          type: 'error',
          showConfirmButton: true,
          background: '#F4E9DA',
          timer: 4000,
        })
        return this.disableButton = false
      } else if (this.formContact.password !== this.formContact.confirm_password) {
        this.$swal({
          title: 'As senhas não conferem',
          type: 'error',
          showConfirmButton: true,
          background: '#F4E9DA',
          timer: 4000,
        })
        return this.disableButton = false
      }

      Object.keys(this.formContact).forEach(key => {
        if (typeof this.formContact[key] === 'string') {
          this.formContact[key] = this.formContact[key].trim()
        }
      })

      this.formContact.phone = this.removeSpecialCharacters(this.formContact.phone)
      this.formContact.cnpj_circo = this.removeSpecialCharacters(this.formContact.cnpj_circo)

      sendService.sendFaleConosco(this.formContact).then(
        response => {
          if (response.result) {
            this.formContact={
                name: '',
                email: '',
                phone: '',
                content: ''
              }
              this.isOwner = false
              this.isAdmin = false
              this.$swal({
                title: 'Sua solicitação foi enviada com sucesso entraremos em contato o mais breve possível.',
                type: 'success',
                showConfirmButton: false,
                background: '#F4E9DA',
                timer: 4000,
              })

              this.disableButton = false
          } else {
            this.$swal({
              title: 'Ocorreu um erro, tente novamente',
              type: 'error',
              showConfirmButton: false,
              background: '#F4E9DA',
              timer: 4000,
            })

            this.disableButton = false
          }
        },
        error => {
          this.$swal({
            title: 'Ocorreu um erro, tente novamente',
            type: 'error',
            showConfirmButton: false,
            background: '#F4E9DA',
            timer: 4000,
          })
    
          this.disableButton = false
        }
      )

    }
  }
}
</script>

<style lang="scss">
.swal2-popup {
  padding: 15px !important;
  .swal2-actions {
    .swal2-confirm {
      padding: 5px 10px !important;
    }
  }
  .swal2-title {
    color: #595959 !important;
    padding: 15px !important;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;700&display=swap');
.container-modal-cdb {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42424240;
  .section-modal-cdb {
    position: relative;
    z-index: 10;
    width: 750px;
    height: 75%;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    background: #F4E9DA;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-modal-cdb {
      font-family: 'Alegreya Sans', sans-serif;
      width: 100%;
      height: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title-modal-cdb {
        font-size: 2.5vw;
        color: #212425;
        font-weight: 700;
      }
      .text-modal-cdb {
        font-size: 1vw;
        color: #212425;
        letter-spacing: 0;
      }
    }
    .info-modal-cdb {
      width: 100%;
      height: 70%;
      padding: 5px 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      gap: 3px;
      .cdb-form-content-checkbox {
        width: 90%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1vw;
        .name-label-cdb-form {
          font-size: 1vw;
          letter-spacing: 0;
          font-family: 'Alegreya Sans', sans-serif;
          flex: 1;
          text-align: right;
        }
        .section-checkbox-cdb-form {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          .label-cdb-form {
            background: #ffffff;
            border-radius: 8px;
            padding: 0 0.5vw !important;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5vw;
            .checkbox-cdb-select {
              transform: scale(1.1);
              cursor: pointer;
            }
            .label-checkbox-cdb {
              font-size: 1vw;
              font-family: 'Alegreya Sans', sans-serif;
              color: #898988;
            }
          }
        }
      }
      .label-cdb-form {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 1.7vw;
        gap: 1vw;
        .name-label-cdb-form {
          font-size: 1vw;
          letter-spacing: 0;
          font-family: 'Alegreya Sans', sans-serif;
          flex: 1;
          text-align: right;
        }
        .form-input-cdb {
          font-family: 'Alegreya Sans', sans-serif;
          width: 70%;
          height: 100%;
          font-size: 1vw;
          padding-left: 5px !important;
          border: none;
          border-radius: 8px;
          outline: none;
          color: #212425;
        }
        .form-input-cdb::placeholder {
          color: #898988;
        }
      }
      .label-cdb-form-text {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        gap: 1vw;
        .name-label-cdb-form {
          font-size: 1vw;
          letter-spacing: 0;
          font-family: 'Alegreya Sans', sans-serif;
          flex: 1;
          text-align: right;
        }
        .form-input-cdb-text {
          font-family: 'Alegreya Sans', sans-serif;
          width: 70%;
          height: 70px;
          font-size: 1vw;
          padding-left: 5px !important;
          border: none;
          border-radius: 8px;
          outline: none;
          color: #212425;
          padding: 5px !important;
          resize: none;
        }
      }
    }
    .confirm-contact-cdb {
      width: 35%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Alegreya Sans', sans-serif;
      .confirm-info-cdb {
        background: #eba943;
        color: #FFFFFF;
        border: none;
        font-weight: bold;
        letter-spacing: 0;
        padding: 0.5vw 2vw !important;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1vw;
        outline: none;
      }
      .close-info-cdb {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        color: #BF3D26;
        border: 2px solid #BF3D26;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .close-icon-small {
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-modal-cdb {
    width: 95% !important;
    height: 75% !important;
    .header-modal-cdb {
      .title-modal-cdb {
        font-size: 17px !important;
      }
      .text-modal-cdb {
        font-size: 15px !important;
      }
    }
    .info-modal-cdb {
      .cdb-form-content-checkbox {
        width: 95% !important;
        height: 25px !important;
        .name-label-cdb-form {
          font-size: 16px !important;
          text-align: left !important;
          width: 80px !important;
        }
        .section-checkbox-cdb-form {
          width: 65% !important;
          .label-cdb-form {
            gap: 1vw !important;
            border-radius: 5px !important;
            .checkbox-cdb-select {
              transform: scale(0.95) !important;
            }
            .label-checkbox-cdb {
              font-size: 14px !important;
              font-family: 'Alegreya Sans', sans-serif;
              color: #898988;
            }
          }
        }
      }
      .label-cdb-form {
        width: 95% !important;
        height: 25px !important;
        .name-label-cdb-form {
          font-size: 16px !important;
          text-align: left !important;
          width: 80px !important;
        }
        .form-input-cdb {
          width: 65% !important;
          height: 25px !important;
          font-size: 14px !important;
          border-radius: 5px !important;
        }
      }
      .label-cdb-form-text {
        width: 95% !important;
        justify-content: space-between !important;
        .name-label-cdb-form {
          font-size: 16px !important;
          width: 80px !important;
          text-align: left !important;
        }
        .form-input-cdb-text {
          width: 65% !important;
          font-size: 14px !important;
          border-radius: 5px !important;
        }
      }
    }
    .confirm-contact-cdb {
      width: 100% !important;
      justify-content: center !important;
      .confirm-info-cdb {
        font-size: 17px !important;
        width: 70px !important;
        height: 35px !important;
      }
    }
  }
}
</style>