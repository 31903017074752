<template>
    <div class="container container__alert form">
    <form>
      <div class="row">
        <div class="col-12">
          <h1 class="mt-3 mb-0 pb-0 title__alert">
           {{ alert_title }}<span
            ></span>
            <i
            @click="closemodalfather"
              class="fa fa-times-circle icon-close"
              style="
                position: absolute;
                right: 0;
                margin-right: 50px;
                font-size: 16px;
                cursor: pointer;
              "
            ></i>
          </h1>
          <hr style="width: 92%" />
        </div>
      </div>
      <div class="contentform mt-0 row pb-1 pt-1">
        <div class="leftcontact col-12 col-md-12">
            {{ alert_description }}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            type="button"
            @click="checkIfHasRedirect"
            class="btn btn-success mt-3 mb-4"
            style="
              display: block;
              min-width: 40% !important;
              width: auto !important;
            "
          >
            <span class="txt-btn-entrar"> {{ alert_button }} </span>
          </button>
        </div>
      </div>
    </form>
  </div>
  </template>
  
  <script>
  import Vue from "vue";
  import VModal from "vue-js-modal";
  import config from "@/config";
  import VueMask from "v-mask";
  
  import { func } from "@/functions";
  
  Vue.use(VueMask);
  Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
  });
  
  export default {
    mixins: [func],
    props: {
      alert_title: {
        type: String,
        default: "Aviso importante!",
      },
      alert_description: {
        type: String,
        default: "Este evento está esgotado.",
      },
      alert_button: {
        type: String,
        default: "OK!",
      },
      alert_uri: {
        type: String,
        default: "",
      },
    },
    name: "modal-alert",
    components: {},
    computed: {
     
    },
    mounted() {
     
    },
    methods: {
        closemodalfather() {
        this.$route.matched[0].instances.default.$parent.modal_close_modalalert();
        },
        checkIfHasRedirect () {
            if (this.alert_uri) {
                let newUri = this.alert_uri
                if (!newUri.includes('http')) {
                    newUri = 'https://' + newUri
                }
                window.open(newUri, '_blank')
            }
            this.closemodalfather()
        }
    },
    data() {
      return {};
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container__alert {
  
    .info p {
      text-align: center;
      color: #999;
      text-transform: none;
      font-weight: 600;
      font-size: 15px;
      margin-top: 2px;
    }
  
    .info i {
      color: #f6aa93;
    }
  
    form h1 {
      font-size: 18px;
      background: #000 none repeat scroll 0% 0%;
      color: rgb(255, 255, 255);
      padding: 5px 5px;
      border-radius: 1px 1px 0px 0px;
      margin: auto;
      text-shadow: none;
      text-align: left;
    }
  
    form {
      border-radius: 5px;
      width: 100%;
      margin: 5% auto;
      background-color: #ffffff;
      overflow: hidden;
    }
  
    p span {
      color: #f00;
    }
  
    p {
      margin: 0px;
      font-weight: 500;
      line-height: 2;
      color: #333;
    }
  
    h1 {
      text-align: center;
      color: #666;
      text-shadow: 1px 1px 0px #fff;
      margin: 50px 0px 0px 0px;
    }
    .icon-case {
      width: 35px;
      float: left;
      border-radius: 5px 0px 0px 5px;
      height: 40px;
      position: relative;
      text-align: center;
      line-height: 40px;
    }
  
    i {
      color: #555;
    }
  
    .contentform {
      padding: 40px 30px;
    }
  
    .button-alert {
      // background-color: #81bda4;
      // color: #fff;
      text-align: center;
      width: 100%;
      border: 0;
      padding: 17px 25px;
      border-radius: 0px 0px 5px 5px;
      cursor: pointer;
      margin-top: 40px;
      font-size: 18px;
    }
  
    .leftcontact {
    }
  
    .rightcontact {
    }
    .to-block {
      background: none;
      padding-bottom: 0 !important;
    }
  
    .to-box {
      cursor: default !important;
    }
  
    a,
    a:hover {
      color: #008489;
    }
    .title__alert {
      background: none;
      color: #555;
      text-align: center;
      padding-bottom: 0;
      font-weight: normal;
      margin-top: 20px;
      font-size: 18px !important;
      font-weight: 600 !important;
      line-height: 1.28571em !important;
      color: rgb(72, 72, 72);
    }
  
    .icon-case {
      background: none !important;
      position: absolute;
      right: 0;
      margin-right: 10px;
  
      i {
        font-size: 0.9em !important;
      }
    }
  
    input {
      font-size: 0.85em !important;
      padding: 0.45rem !important;
  
      @media screen and (max-width: 720px) {
        padding: 0.5rem !important;
      }
    }
  
    .icon-close {
      @media screen and (max-width: 720px) {
        margin-right: 30px !important;
      }
    }
  }
  
  .container__alert form {
    padding: 3.75rem 2.5rem;
    overflow: hidden;
    color: #444;
    padding: 0;
    border-radius: 0.25rem;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  }
  </style>
  