<template>
<div class="content-footer-cdb">
  <div class="section-supporters-cdb">
    <div class="content-supporters-cdb">
      <h2 class="title-supporters-cdb">Apoiadores</h2>
      <div class="icons-supporters-cdb">
        <img src="./assets/logo_ubci.png" class="image-supporters-cdb" alt="logo-ubci">
      </div>
    </div>
  </div>
  <div class="section-contacts-cdb">
    <div class="who-we-are-cdb">
      <h2 class="title-who-we">Quem somos</h2>
      <p class="text-who-we-are">Uma equipe de apoio aos circos</p>
    </div>
    <div class="contacts-cdb">
      <h2 class="title-contact-cdb">Contatos</h2>
      <p class="text-contact-cdb">Entre em contato por WhatsApp
        <a class="redirect-wpp-cdb" href="https://api.whatsapp.com/message/EBA3D7Y6ITMUL1?autoload=1&app_absent=0" target="_blank">
          <img class="icon-wpp-cdb" src="./assets/whatsapp-icon.png">
        </a>
      </p>
    </div>
  </div>
  <div class="copyright-cdb">
    <div class="copy-cdb">
      <p class="title-copy-cdb">CIRCOS DO BRASIL</p>
      <img class="circo-icon-cdb" src="./assets/Circo.png" alt="">
      <p class="text-copy-cdb">2023. Todos os direitos reservados.</p>
    </div>
    <p class="powered-cdb">POWERED BY <a href="https://www.bilheteria.com.br/" target="_blank" class="redirect-bilheteria-cdb">BILHETERIA.COM</a></p>
  </div>
</div>
</template>

<script>
export default {
  name: 'FooterCircos',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: { },
  methods: { }
}
</script>

<style lang="scss">
</style>