<template>
  <div class="container-detail-event" v-if="showDetail">
    <div class="section-detail-cdb">
      <div class="content-details-cdb">
        <div class="image-detail-circo">
          <div class="content-image-detail">
            <img class="img-selected-cdb" v-if="event.images[0].path" :src="event.images[0].path" alt="">
            <p class="text-no-image" @click="openRegister()" v-else >Clique para inserir uma imagem</p>
            <p class="id-image">Card principal</p>
          </div>
        </div>
        <div class="details-circo-cdb">
          <div class="title-and-infos">
            <div class="box-logo">
              <img class="icon-circo-details-cdb" v-if="event.images[3].path" :src="event.images[3].path" alt="Logo">
              <p class="text-no-image" @click="openRegister()" v-else >Clique para inserir uma imagem</p>
              <p class="id-image">Logo</p>
            </div>
            <div class="info-detail-cdb">
              <p class="title-event-cdb">{{ event.nome }}</p>
              <p class="address-event-cdb" v-if="event.localizacao_cidade && event.localizacao_estado">{{ event.localizacao_cidade }} / {{ event.localizacao_estado }}</p>
              <p class="address-event-cdb" v-if="event.localizacao">{{ event.localizacao }}<span v-if="event.localizacao_bairro">, {{ event.localizacao_bairro }}</span><span v-if="event.localizacao_referencia">, {{ event.localizacao_referencia }}</span></p>
              <p class="phone-event-cdb" v-if="event.telefone_equipamento">Telefone: {{ event.telefone_equipamento }}</p>
              <p class="site-event-cdb" v-if="event.site">Site: {{ event.site }}</p>
            </div>
          </div>
          <div class="social-media-detaiil-cdb">
            <div class="item-social-cdb" v-for="(item, idx) in socialMediaItems" :key="idx" @click="goToMedia(item.value, item.media)">
              <img class="img-social-cdb" :src="item.icon" alt="">
              <p class="label-item-social-media-cdb">{{ item.label }}</p>
            </div>
          </div>
          <div class="details-event">
            <p class="detail-label-cdb" v-if="event.detalhes">{{ event.detalhes }}</p>
          </div>
        </div>
        <button class="close-detail-event" @click="showDetail = false">
          <span class="material-symbols-outlined close-icon-small">close</span>
        </button>
      </div>
      <div class="content-detail-img-cdb">
        <div class="responsible-cdb" @click="openRegister()">
          <p class="responsible-label-cdb">DONO OU RESPONSÁVEL DESTE CIRCO, MATENHA A SUA LOCALIZAÇÃO SEMPRE ATUALIZADA, CLICANDO AQUI.</p>
        </div>
        <div class="carousel-detail-cdb">
          <div class="item-img-and-video-cdb">
            <p class="id-image">Vídeo 1</p>
            <iframe
              v-if="event.link_video1 && event.link_video1.length > 0"
              width="100%"
              height="100%"
              style="border-radius: 8px"
              :src="event.link_video1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
          <div class="item-img-and-video-cdb">
            <p class="id-image">Vídeo 2</p>
            <iframe
              v-if="event.link_video2 && event.link_video2.length > 0"
              width="100%"
              height="100%"
              style="border-radius: 8px"
              :src="event.link_video2"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </div>
          <div class="item-img-and-video-cdb">
            <p class="id-image">Imagem 1</p>
            <img class="image-or-video-test" v-if="event.images[1].path.length > 0" :src="event.images[1].path" @click="zoomImage()">
          </div>
          <div class="item-img-and-video-cdb">
            <p class="id-image">Imagem 2</p>
            <img class="image-or-video-test" v-if="event.images[2].path.length > 0" :src="event.images[2].path" @click="zoomImage()">
          </div>
        </div>
        <div class="redirect-tickets">
          <p class="title-tickets">INGRESSOS</p>
          <button class="body-tickets-detail" style="cursor: default;">
            <p class="link-ticket-cdb-detail">NO CIRCO</p>
          </button>
          <button class="online-tickets-detail" v-if="event.link_ingresso_online">
            <a class="link-ticket-cdb-detail" target="_blank" :href="event.link_ingresso_online">ONLINE</a>
          </button>
        </div>
      </div>
      <modal-gallery />
    </div>
    <ModalForgotPassword v-if="showModalForgotPassword" :id='event.id' :name="event.nome" @close-modal-forgot-password="showModalForgotPassword = false" />
    <modal-register v-if="showRegister" :id='event.id' :name="event.nome" />
  </div>
</template>

<script>
import ModalGallery from './ModalGallery.vue'
import ModalRegister from './ModalRegister.vue'
import ModalForgotPassword from './ModalForgotPassword.vue'

export default {
  name: 'DetailCircos',
  props: [],
  components: {
    ModalGallery,
    ModalRegister,
    ModalForgotPassword
  },
  data () {
    return {
      showDetail: false,
      showRegister: false,
      showModalForgotPassword: false,
      event: null,
      socialMediaItems: [
        { label: 'Whatsapp', media: 'whatsapp', value: '', icon: 'https://iconarchive.com/download/i82978/limav/flat-gradient-social/Whatsapp.ico' },
        { label: 'Facebook', media: 'facebook', value: '', icon: 'https://iconarchive.com/download/i103484/paomedia/small-n-flat/social-facebook.ico' },
        { label: 'Instagram', media: 'instagram', value: '', icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/769px-Instagram-Icon.png' },
        { label: 'TikTok', media: 'link_tiktok', value: '', icon: 'https://cdn-icons-png.flaticon.com/512/5968/5968812.png' },
        { label: 'E-mail', media: 'email_equipamento', value: '', icon: 'https://techcommunity.microsoft.com/t5/image/serverpage/image-id/172206i70472167E79B9D0F/image-size/large?v=v2&px=999' },
        { label: 'YouTube', media: 'link_canal_youtube', value: '', icon: 'https://cdn.iconscout.com/icon/free/png-256/youtube-104-432560.png' }
      ]
    }
  },
  beforeDestroy () {
    this.$root.$off('open-detail-event')
  },
  mounted () {
    this.$root.$on('close-modal-register', () => {
      this.showRegister = false
    })

    this.$root.$on('open-modal-forgot-password', () => {
      this.showModalForgotPassword = true
      this.showRegister = false
    })
    this.$root.$on('open-detail-event', payload => {
      this.event = payload
      this.showDetail = true
      if (payload.whatsapp) {
        this.socialMediaItems[0].value = payload.whatsapp
      }
      if (payload.facebook) {
        this.socialMediaItems[1].value = payload.facebook
      }
      if (payload.instagran) {
        this.socialMediaItems[2].value = payload.instagran
      }
      if (payload.link_tiktok) {
        this.socialMediaItems[3].value = payload.link_tiktok
      }
      if (payload.email_equipamento) {
        this.socialMediaItems[4].value = payload.email_equipamento
      }
      if (payload.link_canal_youtube) {
        this.socialMediaItems[5].value = payload.link_canal_youtube
      }
    })
  },
  computed: {},
  methods: {
    zoomImage () {
      const assets = [ this.event.images[1].path, this.event.images[2].path]
    
      if (this.event.link_video1.length > 0) {
        assets.push(this.event.link_video1)
      }

      if (this.event.link_video2.length > 0) {
        assets.push(this.event.link_video2)
      }

      this.$root.$emit('open-modal-gallery', assets)
    },
    openContact () {
      this.$root.$emit('open-modal-contact', true)
    },
    openRegister () {
      this.showRegister = true
    },
    goToMedia (e, media) {
      if (media === 'whatsapp') {
        return window.open(`https://wa.me/55${e}`, '_blank')
      }
      if (media === 'email_equipamento') {
        return window.open(`mailto:${e}`, '_blank')
      }
      if (e.length > 0) {
        window.open(e, '_blank')
      }
    }
  }

}
</script>

<style lang="scss">
.container-detail-event {
  font-family: 'Alegreya', serif;
  background: #42424280;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .section-detail-cdb {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    font-family: 'Alegreya Sans', sans-serif;
    background: #F4E9DA;
    position: relative;
    width: 1040px;
    height: 80%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #212425;
    .content-details-cdb {
      width: 100%;
      height: 80%;
      display: flex;
      .image-detail-circo {
        width: 50%;
        height: 100%;
        padding: 15px !important;
        display: flex;
        align-items: center;
        cursor: pointer;
        .content-image-detail {
          position: relative;
          background: #F2D5A7;
          box-shadow: 0 0px 10px 0 #e0e0e0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          .text-no-image {
            height: 100%;
            width: 100%;
            border-radius: 8px;
            color: #424242;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .img-selected-cdb {
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
            height: 100%;
            width: 100%;
            border-radius: 8px;
            object-fit: fill;
          }
          .id-image {
            position: absolute;
            color: #000000;
            font-size: 14px;
            bottom: -20px;
            left: 5px;
            font-weight: bold;
          }
        }
      }
      .close-detail-event {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background: transparent;
        color: #BF3D26;
        border: 2px solid #BF3D26;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
      }
      .details-circo-cdb {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .details-event, .responsible-cdb {
          width: 80%;
        }
        .social-media-detaiil-cdb {
          width: 90%;
          height: 35px;
          margin: 10px 0 !important;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .item-social-cdb {
            width: 2.5vw;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img-social-cdb {
              width: 2.5vw;
              object-fit: cover;
            }
            .label-item-social-media-cdb {
              font-size: 0.7vw;
            }
          }
        }
        .title-and-infos {
          width: 100%;
          height: 190px;
          display: flex;
          .box-logo {
            position: relative;
            width: 30%;
            height: 75%;
            border-radius: 8px;
            .text-no-image {
              height: 100%;
              width: 100%;
              border-radius: 8px;
              font-size: 14px;
              text-align: center;
              background: #F2D5A7;
              color: #424242;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .icon-circo-details-cdb {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
            .id-image {
              position: absolute;
              bottom: -20px;
              color: #000000;
              left: 3px;
              font-size: 12px;
              font-weight: bold;
            }
          }
          .info-detail-cdb {
            width: 70%;
            height: 100%;
            font-size: 1.2em;
            padding-left: 5px !important;
            .title-event-cdb {
              cursor: pointer;
              font-size: 1.4em;
              font-weight: bold;
            }
          }
        }
        .details-event {
          height: 35%;
          overflow-y: auto;
          .detail-label-cdb {
            font-size: 1em;
          }
        }
      }
    }
    .content-detail-img-cdb {
      width: 100%;
      height: 20%;
      padding: 15px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .responsible-cdb {
        text-align: center;
        background: #eba943;
        color: #FFFFFF;
        width: 20%;
        height: 100%;
        padding: 3px !important;
        border-radius: 8px;
        border: 1px solid #ffffff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .responsible-label-cdb {
          font-weight: bold;
          font-size: 13px;
        }
      }
      .carousel-detail-cdb {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .item-img-and-video-cdb {
          position: relative;
          height: 100%;
          width: 24%;
          border-radius: 8px;
          background: #F2D5A7;
          display: flex;
          align-items: center;
          justify-content: center;
          .id-image {
            position: absolute;
            color: #000000;
            top: -20px;
            left: 5px;
            font-size: 10px;
            font-weight: bold;
          }
          .text-no-media-cdb-detail {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #424242;
            cursor: pointer;
          }
          .image-or-video-test {
            width: 100%;
            height: 100%;
            object-fit: fill;
            cursor: pointer;
            border-radius: 8px;
          }
          .video-cdb-detail {
            width: 100%;
            height: 100%;
          }
        }
      }
      .redirect-tickets {
        width: 10%;
        height: 100%;
        background: #eba943;
        padding: 8px 0 !important;
        border: 1px solid #ffffff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-tickets {
          color: #ffffff;
          font-weight: bold;
          font-size: 17px;
        }
        .body-tickets-detail, .online-tickets-detail {
          height: 30px;
          width: 90%;
          margin: 3px 0 7px 0!important;
          background: #BF3D26;
          border-radius: 8px;
          font-size: 15px;
          font-weight: bold;
          border: none;
          cursor: pointer;
          outline: none;
          .link-ticket-cdb-detail {
            color: #FFFFFF;
            text-decoration: none;
          }
        }
        .online-tickets-detail {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-detail-event {
    height: 100vh;
    width: 100vw;
    .section-detail-cdb {
      width: 95%;
      height: 95%;
      overflow-y: auto;
      .content-details-cdb {
        position: relative;
        padding-top: 150px !important;
        height: auto;
        flex-direction: column;
        .image-detail-circo {
          width: 100%;
          height: 190px;
          display: flex;
          justify-content: center;
          .content-image-detail {
            width: 335px;
          }
        }
        .details-circo-cdb {
          width: 100%;
          height: 80%;
          justify-content: flex-start;
          .title-and-infos {
            width: 100%;
            height: fit-content;
            flex-direction: column;
            align-items: center;
            padding: 0 2px !important;
            .box-logo {
              position: absolute;
              top: 35px;
              width: 120px;
              height: 100px;
            }
            .info-detail-cdb {
              width: 60%;
              height: fit-content;
              padding-left: 10px !important;
              margin: 10px 0 !important;
              .title-event-cdb {
                text-align: center;
              }
            }
          }
          .social-media-detaiil-cdb {
            width: 100%;
            margin: 15px 0 !important;
            .item-social-cdb {
              width: 50px;
              .img-social-cdb {
                width: 5vh;
              }
              .label-item-social-media-cdb {
                font-size: 1.5vh;
              }
            }
          }
          .details-event {
            width: 90%;
            text-align: center;
            height: auto;
            overflow: initial;
          }
        }
      }
      .content-detail-img-cdb {
        width: 60%;
        height: auto;
        padding: 2px !important;
        margin-top: 20px !important;
        flex-direction: column;
        align-items: center;
        .responsible-cdb {
          width: 95%;
          height: 75px;
          margin-bottom: 5px !important;
        }
        .carousel-detail-cdb {
          width: 100%;
          padding: 2px !important;
          flex-direction: column;
          .item-img-and-video-cdb {
            height: 150px;
            width: 95%;
            margin-bottom: 5px !important;
          }
        }
        .redirect-tickets {
          width: 95%;
          height: 10%;
          display: flex;
          flex-direction: initial;
          justify-content: space-around;
          margin-bottom: 10px !important;
          .body-tickets-detail, .online-tickets-detail {
            width: 30%;
            height: 70%;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .content-image-detail {
    width: 100% !important;
  }
  .info-detail-cdb {
    width: 100% !important;
  }
  .content-detail-img-cdb {
    width: 100% !important;
  }
}

</style>