<template>
  <div
    :class="defaultClasses"
    @click="goto('discovery', item.link)"
    v-if="item.isdiscovery == 1"
  >
    <div class="to-box p-0">
      <div
        class="img-fluid rounded-0"
        :style="{
          backgroundImage: 'url(\'' + item.imageURI + '\')',
        }"
        style="background-size: cover"
      ></div>

      <div
        class="content to-box p-2 pt-0 pb-1"
        style="
          position: relative;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        "
      >
        <h4
          class="event__title pb-1"
          style="text-transform: uppercase !important"
        >
          <strong>{{ item.title | truncate(35, " ...") }}</strong>
        </h4>
        <p class="p-0 pt-1 m-0 h-200 event__item" style="font-size: 13px">
          {{ item.content | truncate(110, " ...") }}
        </p>
      </div>
    </div>
  </div>
  <div v-else :class="defaultClasses">
    <div
      class="ad__badge-it"
      :class="{'fix-badge-top-overlay-image': siteName == 'ingressoparatodos.com.br'}"
      v-if="
        siteName == 'ingressoparatodos.com.br' ||
        siteName == 'peixeurbano.ticketoffice.me' ||
        (siteName == 'www.ingreshow.com.br' &&
          item.minAmount !== null &&
          item.minAmount !== '')
      "
    >
      <!-- <i class="far fa-money-bill-alt" style=""></i> -->

      <!-- mudança selo ingressoparatodos -->

      <div v-if="siteName == 'ingressoparatodos.com.br'" class="selo-badge">
        <div class="selo-texto">
          <span
            class
            v-if="
              siteName == 'ingressoparatodos.com.br' &&
              item.minAmount !== null &&
              item.minAmount !== ''
            "
            style="margin-top: 6px; font-size: 7px !important; color: #c11e15"
          >
            <template v-if="item.mmAmountIsPer == 0">
              <!-- A partir de <br /> -->
              <div class="valor-badge" style="margin-top: 34%">
                <span
                  style="
                    font-weight: bold !important;
                    font-size: 14px !important;
                  "
                  >{{ item.minAmount }}</span
                >
              </div>
            </template>
            <template v-if="item.mmAmountIsPer != 0">
              <!-- Desconto de <br /> -->
              <div class="valor-badge" style="margin-top: 23%">
                <span
                  style="
                    font-weight: bold !important;
                    font-size: 26px !important;
                  "
                  >{{ item.percentage }}%</span
                >
              </div>
            </template>
          </span>
        </div>
      </div>
      <div class="titulo-badge" v-if="siteName == 'ingressoparatodos.com.br'">
        <template v-if="item.mmAmountIsPer == 0">
          <!-- A partir de <br /> -->
          <span
            class="valor-card"
            style="font-weight: bold !important; font-size: 35px !important"
            >{{ item.minAmount }}</span
          >
        </template>
        <template v-if="item.mmAmountIsPer != 0">
          <!-- Desconto de <br /> -->
          <span
            class="valor-card"
            style="font-weight: bold !important; font-size: 35px !important"
            >{{ item.minAmount }}</span
          >
        </template>
      </div>

      <!-- fim mudança selo ingressoparatodos -->

      <span
        class
        v-else-if="item.minAmount !== null && item.minAmount !== ''"
        style="margin-top: 6px"
      >
        <template v-if="item.mmAmountIsPer == 0">
          A partir de
          <span
            style="font-weight: bold !important; font-size: 12px !important"
            >{{ item.minAmount }}</span
          >
        </template>
        <template v-if="item.mmAmountIsPer != 0">
          Desconto de
          <span style="font-weight: bold !important; font-size: 12px !important"
            >{{ item.minAmount }}%</span
          >
        </template>
      </span>
    </div>
    <div class="to-box p-0">
      <a @click="validateAlertAndRedirectUri" style="text-decoration: none"
      :class="{'container-hover-with-price': siteName === 'ingressoparatodos.com.br' || siteName === 'corporativo.bilheteria.com.br'}"
      >
        <!-- implementando esse bloco do if para carregar as imagens sem o v-lazy quando usado o swipper -->
        <!-- gera conflito com o v-lazy do proprio swipper -->
        <div
        v-if="classes && (siteName === 'corporativo.bilheteria.com.br' || siteName === 'Bilheteria.com.br')"
          class="hasImage img-fluid rounded-0 corporate-and-bilheteria-event-image"
          :style="{ backgroundImage: 'url(\'' + item.img + '\')' }"
          style="background-size: cover"
        ></div>
        <div v-else
          class="hasImage img-fluid rounded-0"
          :class="{'ingressoparatodos-event-image': siteName === 'ingressoparatodos.com.br'}"
          v-lazy:background-image="item.img"
          style="background-size: cover"
        ></div>

        <div v-if="eventNextDate.length > 0 && siteName == 'ingressoparatodos.com.br'" class="container-middle-card-event">
          <div v-if="!eventFullDetail.active_external_buy_button">
            <div>Próxima apresentação</div>
            <div class="font-overlay-event" v-if="!eventNextDate[0].istoday">{{eventNextDate[0].weekdayName | filterWeekDay}}</div>
            <div class="font-overlay-event" v-else>Hoje</div>
            <div class="font-overlay-event">{{eventNextDate[0].day}}</div>
            <div class="font-overlay-event">{{eventNextDate[0].HorSessao}}</div>
          </div>
          <button  @click.prevent="buy(eventNextDate[0].id_apresentacao)" class="text shop_button_event btn btn-secondary mt-3 mb-4"><i class="fa fa-sm fa-shopping-cart"></i> {{ eventFullDetail.name_btn_buy || 'Comprar' }}</button>
          <div class="text"></div>
        </div>

        <div v-if="siteName === 'corporativo.bilheteria.com.br'" style="display: flex;align-items: center;" class="container-middle-card-event">
          <div style="width: 100%;">
            <div v-if="!eventFullDetail.active_external_buy_button && eventFullDetail.TipPeca !== 'games'"  class="font-overlay-event">{{item.ds_evento}}</div>
            <div v-if="item.percentage && item.percentage !== '0' && !eventFullDetail.active_external_buy_button  && eventFullDetail.TipPeca !== 'games'" class="font-overlay-event">Desconto de {{item.percentage}}%</div>
            <div class="font-overlay-event" v-if="item.minAmount !== 'R$ 0,00' && !eventFullDetail.active_external_buy_button  && eventFullDetail.TipPeca !== 'games'">A partir de {{item.minAmount}}</div>
              <button style="margin: 12px !important; background: #fdc80a;"  class="text  btn mt-3 mb-4"><i class="fa fa-sm fa-shopping-cart"></i> Saiba Mais e Comprar</button>
          </div>
        </div>

        <div
          v-if="
            siteName == 'ingressoparatodos.com.br' &&
            item.genreName == 'INFANTIL'
          "
          class="content to-box p-2 pt-0 pb-1"
          style="
            position: relative;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            background: #f5bfcd !important;
          "
        >
          <h4 class="titulocardevent event__title pb-1">
            <strong>{{ item.ds_evento | truncate(35, " ...") }}</strong>
          </h4>
          <div class="txt-yeseva" v-if="eventNextDate.length > 0 && !eventFullDetail.active_external_buy_button">
              <div class="txts-evento" style="font-weight: bold;font-size: 20px;display: flex;justify-content:center;">
                <!-- <div>Próxima apresentação -&nbsp;</div> -->
                <div v-if="!eventNextDate[0].istoday">{{eventNextDate[0].weekdayName | filterWeekDay}}</div>
                <div v-else>Hoje</div>
                <div>&nbsp;- {{eventNextDate[0].day}} - às {{eventNextDate[0].HorSessao}}</div>
                <!-- <div style="font-weight: bold;"> às {{eventNextDate[0].HorSessao}}</div> -->
              </div>
          </div>
          <p
            v-if="siteName != 'games.bilheteria.com.br'"
            class="txt-yeseva p-0 m-0 event__item event__item-date"
            style="margin: 0 auto !important;width: fit-content;"
          >
            <span class="bold">{{ item.datas | replace("-", "á") }}</span>
            <span class="ml-1 mr-2" v-if="item.showPartnerInfo == 1">-</span>
            <span class v-if="item.showPartnerInfo == 1">
              <i class="far fa-handshake" style="padding-right: 6px"></i>
              {{ item.partner }}
            </span>
          </p>
          <p
            v-if="siteName != 'games.bilheteria.com.br'"
            class="txt-yeseva p-0 pt-1 m-0 h-200 event__item event__item-local"
            style="margin: 0 auto !important;width: fit-content;"
          >
            <span class="bold" style="text-transform: capitalize !important"
              >{{ item.ds_nome_teatro | capitalize() }} -
              {{ item.ds_municipio | capitalize() }},</span
            >
            {{ item.sg_estado }}
          </p>
          <button @click.prevent="buy(eventNextDate[0].id_apresentacao)" class="text  btn btn-primary mt-3 mb-4 shop_button_event"><i class="fa fa-sm fa-shopping-cart"></i> {{eventFullDetail.name_btn_buy || 'Comprar'}}</button>
          <!-- <p class="p-0 m-0 mt-0 event__item"  style="font-size: 13px">
        <i class="far fa-money-bill-alt" style="margin-right: 6px; margin-top: 4px;"></i>
        
        <span class="bold" style="margin-top: 6px">{{item.valores}}</span>
          </p>-->
        </div>
        <div
          v-if="
            siteName == 'ingressoparatodos.com.br' &&
            item.genreName != 'INFANTIL'
          "
          class="content to-box p-2 pt-0 pb-1"
          style="
            position: relative;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            background: #b4e8f5;
          "
        >
          <h4 class="titulocardevent event__title pb-1">
            <strong>{{ item.ds_evento | truncate(35, " ...") }}</strong>
          </h4>
          <div class="txt-yeseva" v-if="eventNextDate.length > 0 && !eventFullDetail.active_external_buy_button">
              <div class="txts-evento" style="font-weight: bold;font-size: 20px;display: flex;justify-content:center;">
                <!-- <div>Próxima apresentação -&nbsp;</div> -->
                <div v-if="!eventNextDate[0].istoday">{{eventNextDate[0].weekdayName | filterWeekDay}}</div>
                <div v-else>Hoje</div>
                <div>&nbsp;- {{eventNextDate[0].day}} - às {{eventNextDate[0].HorSessao}}</div>
                <!-- <div style="font-weight: bold;"> às {{eventNextDate[0].HorSessao}}</div> -->
              </div>
          </div>
          <p
            v-if="siteName != 'games.bilheteria.com.br' && !eventFullDetail.active_external_buy_button"
            class="txt-yeseva p-0 m-0 event__item event__item-date"
            style="margin: 0 auto !important;width: fit-content;"
          >
            <span class="bold">{{ item.datas | replace("-", "á") }}</span>
            <span class="ml-1 mr-2" v-if="item.showPartnerInfo == 1">-</span>
            <span class v-if="item.showPartnerInfo == 1">
              <i class="far fa-handshake" style="padding-right: 6px"></i>
              {{ item.partner }}
            </span>
          </p>
          <p
            v-if="siteName != 'games.bilheteria.com.br'"
            class="txt-yeseva p-0 pt-1 m-0 h-200 event__item event__item-local"
            style="margin: 0 auto !important;width: fit-content;"
          >
            <span class="bold" style="text-transform: capitalize !important"
              >{{ item.ds_nome_teatro | capitalize() }} -
              {{ item.ds_municipio | capitalize() }},</span
            >
            {{ item.sg_estado }}
          </p>
          <button @click.prevent="buy(eventNextDate[0].id_apresentacao)" class="text  btn btn-primary mt-3 mb-4 shop_button_event"><i class="fa fa-sm fa-shopping-cart"></i> {{eventFullDetail.name_btn_buy || 'Comprar'}}</button>

          <!-- <p class="p-0 m-0 mt-0 event__item"  style="font-size: 13px">
        <i class="far fa-money-bill-alt" style="margin-right: 6px; margin-top: 4px;"></i>
        
        <span class="bold" style="margin-top: 6px">{{item.valores}}</span>
          </p>-->
        </div>
        <div
          v-if="siteName != 'ingressoparatodos.com.br'"
          class="cardevent-footer content to-box p-2 pt-0 pb-1"
          style="
            position: relative;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          "
        >
          <h4 style="text-align: center;" class="titulocardevent event__title pb-1">
            <strong>{{ item.ds_evento | truncate(35, " ...") }}</strong>
          </h4>

          <div class="txt-yeseva" v-if="eventNextDate.length > 0 && !eventFullDetail.active_external_buy_button && eventFullDetail.TipPeca !== 'games'">
              <div class="txts-evento" style="font-weight: bold;font-size: 20px;display: flex;justify-content:center;">
                <!-- <div>Próxima apresentação -&nbsp;</div> -->
                <div v-if="!eventNextDate[0].istoday">{{eventNextDate[0].weekdayName | filterWeekDay}}</div>
                <div v-else>Hoje</div>
                <div>&nbsp;- {{eventNextDate[0].day}} - às {{eventNextDate[0].HorSessao}}</div>
                <!-- <div style="font-weight: bold;"> às {{eventNextDate[0].HorSessao}}</div> -->
              </div>
          </div>
          <p
            style="margin: 0 auto !important;width: fit-content;"
            v-if="siteName != 'games.bilheteria.com.br' && !eventFullDetail.active_external_buy_button  && eventFullDetail.TipPeca !== 'games'"
            class="p-0 m-0 event__item event__item-date"
          >
            <span class="bold">{{ item.datas | replace("-", "a") }}</span>
            <span class="ml-1 mr-2" v-if="item.showPartnerInfo == 1">-</span>
            <span class v-if="item.showPartnerInfo == 1">
              <i class="far fa-handshake" style="padding-right: 6px"></i>
              {{ item.partner }}
            </span>
          </p>
          <p
            style="margin: 0 auto !important;width: fit-content;"
            v-if="siteName != 'games.bilheteria.com.br'  && eventFullDetail.TipPeca !== 'games'"
            :style="{'display': siteName === 'corporativo.bilheteria.com.br' && eventFullDetail.active_external_buy_button ? 'none': 'inherit'}"
            class="p-0 pt-1 m-0 h-200 event__item event__item-local"
          >
            <span class="bold" style="text-transform: capitalize !important"
              >{{ item.ds_nome_teatro | capitalize() }} -
              {{ item.ds_municipio | capitalize() }},</span
            >
            {{ item.sg_estado }}
          </p>
          <button style="width: 100%;" 
          :style="customColorButton()" 
            @click.prevent="isToBuyOrOpenDetails()" 
            class="text  btn btn-primary mt-3 mb-4 shop_button_event">
            <i class="fa fa-sm fa-shopping-cart"></i> 
            {{ eventFullDetail.name_btn_buy || (eventFullDetail.TipPeca !== 'games' ? 'Comprar' : 'COMPRAR ACESSÓRIOS')}}
          </button>
          <!-- <p class="p-0 m-0 mt-0 event__item"  style="font-size: 13px">
        <i class="far fa-money-bill-alt" style="margin-right: 6px; margin-top: 4px;"></i>
        
        <span class="bold" style="margin-top: 6px">{{item.valores}}</span>
          </p>-->
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import { eventService } from "@/components/common/services/event";
import { func } from "@/functions";

export default {
  name: "CardEvent",
  mixins: [func],
  data () {
    return {
      eventNextDate: {},
      defaultClasses: 'col-12 col-xl-4 col-md-6 p-2 text-left',
      eventFullDetail: {}
    }
  },
  computed: {
    siteName() {
      return config.info.siteName;
    },
    getDayForCache() {
      const date = new Date();
      return date.getDate() + "" + (date.getMonth() + 1);
    },
  },
  methods: {
    customColorButton () {
      // usefull for custom color button that scss style not work
      if (this.siteName === 'corporativo.bilheteria.com.br') return { color: '#000 !important' }
      if (['www.teatroruthescobar.com.br', 'teatroruthescobar.com.br'].indexOf(this.siteName) > -1) return { color: '#fff !important' }
      return { color: '' }
    },
    validateAlertAndRedirectUri() {
      if (this.checkEventAlert(this.eventFullDetail)) return;
      window.location.href = this.item.uri
    },
    isToBuyOrOpenDetails() {
      if (this.checkEventAlert(this.eventFullDetail)) return;
      if(this.siteName !== 'teatrocastromendes.com.br') { // redirect to buy page
        return this.buy(this.eventNextDate[0].id_apresentacao)
      }
      return this.goto('discovery', this.item.uri) // redirect to event description
    },
    buy(id_apresentacao) {
      if (this.checkEventAlert(this.eventFullDetail)) return;
      // start of role: flux only for bilheteria.com
      // must open url from event detail or external url if active
      if (this.siteName === 'Bilheteria.com.br') {
        if (!this.eventFullDetail.active_external_buy_button) {
          return this.$router.push(this.item.uri);
        }
        if (this.eventFullDetail.external_buy_uri) {
          let newUri = this.eventFullDetail.external_buy_uri
          if (!newUri.includes('http')) {
            newUri = 'https://' + external_buy_uri
          }
          return window.open(newUri, '_blank')
        }
        return this.$router.push(this.item.uri); // default to load event page detail
      }
      // end of this role
      if (this.eventFullDetail.active_external_buy_button) {
        return this.$router.push(this.item.uri);
      }
      return this.gotoLegacy(id_apresentacao, "shopping");
    },
    getEvent(key) {
      this.processing = true;
      let isCI = false;
      let eventKey = key;
      const self = this
      eventService.description(eventKey, isCI).then(
       async (response) => {
          if (response.error) {
            console.log('responseerro',  response.error)
          }
          if (this.validateJSON(response)) {
            // console.log('response', response)
            this.eventFullDetail = response
            this.eventNextDate = await eventService.presentation(response.id_base, response.CodPeca)
          }
        },
        (error) => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );
    },
    goto(type, item) {
      if (item.notselectable != undefined && item.notselectable == 1) return;

      let where = item.type != undefined ? item.type : type;

      switch (where) {
        case "local":
          this.$router.push("/busca/local/" + item);
          break;
        case "genre":
          this.$router.push("/busca/genero/" + item);
          break;
        case "city":
          this.$router.push("/busca/cidade/" + item);
          break;
        case "state":
          this.$router.push("/busca/estado/" + item);
          break;
        case "event":
          this.$router.push(item.uri);
          break;
        case "discovery":
          window.location.href = item;
          break;
      }
    },
  },
  props: ["item", "classes"],
  mounted () {
    // if (this.siteName == 'ingressoparatodos.com.br') {
      if (this.item.uri) {
        const key = this.item.uri.split('evento/')[1]
        this.getEvent(key)
      }
    // }
    if (this.classes && (this.siteName === 'corporativo.bilheteria.com.br' || this.siteName === 'Bilheteria.com.br')) {
      this.defaultClasses = this.classes
    }
  },
  filters: {
    truncate: function (text, length, clamp) {
      if (text == null || text == undefined) return;

      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content;
      // return content.length > length ? content.slice(0, length) + clamp : content;
    },
    filterWeekDay: (day) => {
      switch (day) {
        case 'sab':
          return 'Sábado'
        case 'dom':
          return 'Domingo'
        case 'seg':
          return 'Segunda-Feira'
        case 'ter':
          return 'Terça-Feira'
        case 'qua':
          return 'Quarta-Feira'
        case 'qui':
          return 'Quinta-Feira'
        case 'sex':
          return 'Sexta-feira'
        default:
          return day
      }
    },
    replace: function (message, toReplace, replacement) {
      if (message == null || message == undefined) return;
      return message.replace(toReplace, replacement);
    },
    capitalize: function (value) {
      if (value == null || value == undefined) return;

      if (!value) return "";

      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>
<style>
.container-middle-card-event {
  color: white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  z-index: 1003;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  height: 100%;
  padding: 40px;
  width: 100%;
  background-color: #000000e3;
}
.hasImage {
  transition: transform 1s;
}

.container-hover-with-price:hover .hasImage {
  transform: scale(1.1);
}

.container-hover-with-price:hover .container-middle-card-event {
  opacity: 1;
}
.fix-badge-top-overlay-image {
  position: relative;
  top: 0px;
}
.font-overlay-event {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: inherit;
  font-size: 1.5rem;
}

@media screen and (max-width: 515px) {
  .corporate-and-bilheteria-event-image {
    height: calc(105px * (600 / 242)) !important;
  }
  .ingressoparatodos-event-image {
    height: calc(105px * (600 / 337)) !important;
  }
}
</style>