<template>
  <div class="a">
    <section class="to-block to-viewport bg-dark bg__main" style="" data-block-type="call_to_action" data-id="2" v-if="siteName == 'www.santosrockfestival.com.br'" @click="goto('event',{ uri: '/evento/cia_do_stand_up_teatro_west_plaza_33022'})">
    </section>
  
    <!-- Mudança Cidade das Abelhas-->
  <section class="to-block to-viewport bg-dark bg__main" onclick="window.location='https://cidadedasabelhas.com.br/index.php'" style="" data-block-type="call_to_action" data-id="2" v-if="siteName == 'cidadedasabelhas.bilheteria.com.br'">
    </section>
    <!-- Mudança Cidade das Abelhas-->
  
  <!-- Mudança Bilheteria -->
  
  <section class="to-block to-viewport bg-dark bg__main" onclick="window.location='https://corporativo.bilheteria.com.br/askCode'" v-if="['ingressoparatodos.com.br', 'www.apetesp.org.br', 'demo.bilheteria.com.br', 'games.bilheteria.com.br'].indexOf(siteName) === -1">
      <!-- <div class="container justify-content-center align-items-center d-flex container__search--tickethub pt-5 pb-4" v-if="siteName == 'ingressoparatodos.com.br'">
        <div class="col-12 col-md-10 justify-content-center text-center" style="height: 380px;" id="fundo_ingressaria">
          <div>
          </div>
        </div>
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br' && siteName != 'www.ingreshow.com.br'">
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div> -->
      <div class="container justify-content-center align-items-center d-flex container__search--tickethub pt-5 pb-4" v-if="siteName == 'www.ingreshow.com.br'">
        <div class="col-12 col-md-10 justify-content-center text-center" style="height: 249px;" id="fundo_ingressaria">
          <div>
          </div>
        </div>
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br' && siteName != 'www.ingreshow.com.br'">
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
      <div class="container justify-content-center align-items-center d-flex p-4  pt-5 container__search--tickethub" v-if="siteName != 'ingressoparatodos.com.br' && siteName != 'www.ingreshow.com.br'">
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br'">
          <div class="" v-if="siteName != 'Bilheteria.com.br' && siteName != 'teatro.bilheteria.com.br' && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'vivaparque.bilheteria.com.br' && siteName != 'parquedamonica.bilheteria.com.br' && siteName != 'colegiopalmares.bilheteria.com.br' && siteName != 'kidzania.bilheteria.com.br' && siteName != 'amanas.bilheteria.com.br' && siteName != 'cidadedasabelhas.bilheteria.com.br'" style="">
            <h2>Procure por experiências únicas.</h2>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
    </section>
  
    <section :style="{'margin-top': siteName === 'games.bilheteria.com.br' && !isMobile ? '40px !important' : ''}" class="to-block to-viewport bg-dark bg__main" style="" data-block-type="call_to_action" data-id="2" v-if="siteName != 'Bilheteria.com.br' && siteName != 'www.apetesp.org.br' && siteName != 'www.bilheteriaesportiva.com.br' && siteName != 'demo.bilheteria.com.br'" >
  
      <!-- TESTE MUDANÇA LAYOUT LIVE -->
      <div v-if="siteName =='live.bilheteria.com.br'" class="bannerLive">
        <img  src="/assets/banner-live.png" alt="">
        <!-- <button class="btn">Randon</button> -->
        <div class="btn col-2">
          <div onclick="window.location='https://blog.bilheteria.com/calendario-de-lives/'" alt="image" class="img-fluid rounded card__home">
            <span class="genre__ingressaria" style="text-transform: uppercase">Música</span>
          </div>
        </div>
        
      </div>
      <!-- FIM TESTE MUDANÇA LAYOUT LIVE -->
     
      <div class="container justify-content-center align-items-center d-flex container__search--tickethub pt-5 pb-4" v-if="siteName == 'ingressoparatodos.com.br'">
        <div v-if="siteName != 'ingressoparatodos.com.br'" class="col-12 col-md-10 justify-content-center text-center" style="height: 380px;" id="fundo_ingressaria">
          <div>
          </div>
        </div>
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br' && siteName != 'www.ingreshow.com.br'">
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
      <div class="container justify-content-center align-items-center d-flex container__search--tickethub pt-5 pb-4" v-if="siteName == 'www.ingreshow.com.br'">
        <div class="col-12 col-md-10 justify-content-center text-center" style="height: 249px;" id="fundo_ingressaria">
          <div>
          </div>
        </div>
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br' && siteName != 'www.ingreshow.com.br'">
          <div class="" style="">
            <h1>Procure eventos</h1>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search></app-search>
          </div>
        </div>
      </div>
      <div class="container justify-content-center align-items-center d-flex p-4  pt-5 container__search--tickethub" v-if="['ingressoparatodos.com.br', 'www.ingreshow.com.br', 'demo.bilheteria.com.br', 'www.teatroruthescobar.com.br', 'www.teatromariadellacosta.com.br'].indexOf(siteName) < 0">
        <div class="col-12 col-md-10 justify-content-center text-center" v-if="siteName != 'ingressoparatodos.com.br'">
          <div class="" v-if="siteName != 'Bilheteria.com.br' && siteName != 'teatro.bilheteria.com.br' && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'vivaparque.bilheteria.com.br' && siteName != 'parquedamonica.bilheteria.com.br' && siteName != 'colegiopalmares.bilheteria.com.br' && siteName != 'kidzania.bilheteria.com.br' && siteName != 'amanas.bilheteria.com.br' && siteName != 'cidadedasabelhas.bilheteria.com.br'" style="">
            <h2 v-if="siteName != 'vivaparque.bilheteria.com.br' && siteName != 'demo.bilheteria.com.br' && siteName != 'www.apetesp.org.br' && siteName != 'www.bilheteriaesportiva.com.br'" >Procure por experiências únicas.</h2>
          </div>
          <div class="input-group mt-0 mb-3 p-2 w-100">
            <app-search v-if="siteName != 'vivaparque.bilheteria.com.br' && siteName != 'demo.bilheteria.com.br' && siteName != 'www.apetesp.org.br' && siteName != 'www.bilheteriaesportiva.com.br'"></app-search>
          </div>
        </div>
      </div>
    </section>
    
  <!-- CARROSSEL BILHETERIAESPORTIVA-->
  <vueper-slides v-if="siteName == 'www.bilheteriaesportiva.com.br'" 
     slide-image-inside
     fade 
     :touchable="false"
     autoplay
     fixed-height="470px"
     :duration="3000">
      <vueper-slide
      v-for="(slide, i) in slideb" 
      :key="i"
      :image="slide.imageCarrossel"
      :title="slide.titleCarrossel"
      :content="slide.contentCarrossel"
      />
    </vueper-slides>
  <!-- FIM CARROSSEL BILHETERIAESPORTIVA-->
    
  <!-- CARROSSEL APETESP-->
  <!-- <vueper-slides v-if="siteName == 'www.apetesp.org.br'" 
     slide-image-inside
     fade 
     :touchable="false"
     autoplay
     fixed-height="470px"
     :duration="3000">
      <vueper-slide
      v-for="(slide, i) in slidess" 
      :key="i"
      :image="slide.imageCarrossel"
      :title="slide.titleCarrossel"
      :content="slide.contentCarrossel"
      />
    </vueper-slides> -->
  <!-- FIM CARROSSEL APETESP-->
  
   <!-- CARROSSEL INGRESSOPARATODOS-->
   <div class="main-slider">
    <vueper-slides v-if="siteName == 'ingressoparatodos.com.br'" 
    slide-image-inside
     fade :touchable="false"
     autoplay
     :duration="3000">
      <!-- <vueper-slide 
      v-for="i in 2" 
      :key="i" 
      :style="'background-color: ' + ['#ff5252', '#42b983'][i % 2]"
      /> -->
      <vueper-slide
      v-for="(slide, i) in slides" 
      :key="i"
      :image="slide.imageCarrossel"
      :title="slide.titleCarrossel"
      :content="slide.contentCarrossel"
      />
    </vueper-slides>
  <!-- FIM CARROSSEL INGRESSOPARATODOS-->
  
  <div>
    <div class="container-fluid container__select" v-if="siteName == 'ingressoparatodos.com.br'">
      <div class="container p-0">
        <div class="row">
          <div class="col-12 col-xl-12 text-left">
            <div class="p-3">
              <img class="img-fluid rounded-0 discovery" src="/assets/bannermasteringressoparatodos.jpeg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- PROPAGANDA -->
        <div class="ads-apetesp container-fluid container__select" v-if="discoveryBanner.length > 0">
          <div class="container p-0">
            <div class="row">
              <div class="col-12 col-xl-12 text-left">
                <div class="p-3">
                  <div class="container__arrows" style="margin-top: auto">
                    <div class="swiper-button-prev swiper-button-prev-banner" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-next-banner" slot="button-next"></div>
                  </div>
                  <swiper :options="swiperOptionBanner">
                    <swiper-slide v-for="(item, index) in discoveryBanner" :key="index">
                      <img class="img-fluid rounded-0 discovery" v-bind:src="(item.imageURI)" :alt="item.title" @click="goto('discovery', item.link)">
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- PROPAGANDA -->
  
  <!-- MOVENDO EXP EM DESTAQUE BILHETERIA -->
  <section :class="classContainerBackground">
  
  <!-- FILTRO CATEGORIAS -->
  <div v-if="['Bilheteria.com.br', 'demo.bilheteria.com.br'].indexOf(siteName) > -1 && categoriesList.length > 0"
    class="container__select home-bilheteria container pt-2 pb-0 text-left"
    style="position: sticky; top: 0px; z-index: 1004; background: #F3F3F3">
    <div class="categories-title-container">
      <h3 style="display: inline-block;">Explore nossas categorias e escolha o evento que mais lhe agrada</h3>
      <p v-if="filteredData && filteredData.length > 0 && siteName === 'Bilheteria.com.br'"
        class="mb-0 pb-0"
        style="margin-top: 10px;;">
        Eventos disponíveis: {{filteredData.length}}
      </p>
    </div>
    <div class="row items-home" style="margin: 0px; justify-content: center;">
      <button
      onclick="window.location='https://corporativo.bilheteria.com.br/askCode '"
      style="margin: 12px 12px 12px 0px;padding: 0.5rem 1.9rem;width: auto;font-size: 14px;" 
      class="text  btn btn-primary shop_button_event">CORPORATIVO</button>
      <button v-for="(item, idx) in categoriesList" :key="idx"
      @click="redirectTo(item.name_cat)"
      style="margin: 12px 12px 12px 0px;padding: 0.5rem 1.9rem;width: auto;font-size: 14px;" 
      class="text  btn btn-primary shop_button_event">{{item.name_cat}}</button>
    </div>
  </div>
    <!-- Banner -->
    <div v-if="siteName == 'Bilheteria.com.br'" class="container__select to-block container__features" :style="stylesBackground" id="banner">
      <div class="container pt-2 pb-0 text-left">
        <h3 v-if="siteName != 'games.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'" class="dest-xp">Experiências em destaque</h3>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br' && siteName != 'www.apetesp.org.br'">Uma seleção de eventos para você</p>
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-featured" :class="classFilterWhite" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-featured" :class="classFilterWhite" slot="button-next"></div>
          </div>
          <card-event-featured :bannerEvents="bannerEvents" :swiperOption="swiperOption"></card-event-featured>
      </div>
    </div>
    <div v-if="partnerInfo.show_preferred_partners === 1 && preferredPartners && preferredPartners.length > 0 && siteName === 'Bilheteria.com.br'" class="container pt-2 pb-0 text-left">
          <h3 class="">Parceiros preferenciais</h3>
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-preferred-partner" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-preferred-partner" slot="button-next" style="width: 29px;"></div>
          </div>
          <card-preferred-partners :bannerEvents="preferredPartners" :swiperOption="swiperPreferredPartner"></card-preferred-partners>
        </div>
  <!-- FILTRO CATEGORIAS -->
  </section>
  <!-- MOVENDO EXP EM DESTAQE BILHETERIA -->
  
  <!-- MUDANÇA APETESP -->
    <div v-if="siteName == 'www.apetesp.org.br'" class="apetesp-xp container__select to-block container__features"  :style="stylesBackground" id="banner">
      <div class="container pt-2 pb-0 text-left">
        <h3 class="dest-xp">Experiências em destaque</h3>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0" style="color: #fff">Uma seleção de eventos para você</p>
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-featured" :class="classFilterWhite" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-featured" :class="classFilterWhite" slot="button-next"></div>
          </div>
          <card-event-featured :bannerEvents="bannerEvents" :swiperOption="swiperOption"></card-event-featured>
      </div>
    </div>
  <!-- FIM MUDANÇA APETESP -->
  
    <!-- Propaganda Extreia/Anuncio -->
    <div class="container-fluid container__select" >
      <div class="container p-0 pt-4 pb-4">
        <div class="row px-3 pb-2 justify-content-center">
          <div class="col-8 col-lg-3 col-xl-3 text-left pt-4" v-if="discoveryBannerAnuncio.length > 0 && discoveryBannerAnuncio[0]">
            <div class="p-1">
              <img class="img-fluid rounded-0 discovery" style="height: 160px" v-bind:src="discoveryBannerAnuncio[0].imageURI" :alt="discoveryBannerAnuncio[0].title" @click="goto('discovery', discoveryBannerAnuncio[0].link)">
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-6 text-left pt-4" v-if="discoveryBannerEstreia.length > 0 && discoveryBannerEstreia[0]">
            <div class="p-1">
              <div>
                <div style="width: 100%; justify-content: center; display: flex;" v-if="siteName === 'ingressoparatodos.com.br'">
                  <div style="background:#DDB963;padding-top: 4px;padding-bottom:4px;position:absolute;padding-left:6px;padding-right:6px;margin-top: -5%;font-family: cursive;letter-spacing: 1px;border-radius: 6px;"><h3 class="pb-3" style="color:#BF5C7D">estreias</h3></div>
                </div>
                <img class="img-fluid rounded-0 discovery" style="height: 160px" v-bind:src="discoveryBannerEstreia[0].imageURI" :alt="discoveryBannerEstreia[0].title" @click="goto('discovery', discoveryBannerEstreia[0].link)">
              </div>
            </div>
          </div>
          <div class="col-8 col-lg-3 col-xl-3 text-left pt-4" v-if="discoveryBannerAnuncio.length > 1 && discoveryBannerAnuncio[1]">
            <div class="p-1">
              <img class="img-fluid rounded-0 discovery" style="height: 160px" v-bind:src="discoveryBannerAnuncio[1].imageURI" :alt="discoveryBannerAnuncio[1].title" @click="goto('discovery', discoveryBannerAnuncio[1].link)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fim Propaganda Extreia/Anuncio -->
  </div>
    <!-- Filtro Categoria -->
    <section class="features" tyle="background: white" data-block-type="features" data-id="3" id="features" v-if="siteName == 'live.bilheteria.com.br'">
      <div class="col-12 col-sm-12 text-left mt-2 mb-2">
              <h3 style="text-align: center;" class="">Clique na categoria preferida e Divirta-se <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            </div>
        <div class="container">
          <div class="box-categoria row text-left pt-1 pb-1">
            <div class="col-12 p-0 mt-2 pt-3" style="" v-if="!genreListLoaded">
              <GenreFeaturesLoader :speed="2" :animate="true"></GenreFeaturesLoader>
            </div>
            <div @click="goto('genre',item.genreName)" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0" style="" v-for="(item, index) in genreList" :key='index' v-else>
              <p>
                <div alt="image" class="img-fluid rounded card__home" :class="['card__home-' + index]">
                  <span class="genre__ingressaria" style="text-transform: uppercase">{{ item.genreName }}</span>
                </div>
              </p>
            </div>
          </div>
        </div>
        <!-- Botão Fixado -->
         <div class="container">
           <div class="box-categoria row text-left pt-1 pb-1">
           <div onclick="window.location='https://blog.bilheteria.com/calendario-de-lives/'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <span class="genre__ingressaria" style="text-transform: uppercase">Música</span>
              </div>
              </p>
            </div>
            </div>
         </div>
            <!-- Fim Botão Fixado -->
      </section>
      <!-- Fim Filtro Categoria -->
  
  
    <!-- Destaque generos -->
    <!-- <section class="features" style="background: white" data-block-type="features" data-id="3" id="features" v-if="siteName == 'ingressoparatodos.com.br'">
      <div class="container">
        <div class="row text-left pt-1 pb-1">
          <div class="col-12 col-sm-12 text-left mt-2 mb-2">
            <h3 class="">Nossos teatros <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            <p class="mt-3 mb-0 pb-0">Descubra eventos específicos de cada teatro</p>
          </div>
          <div class="col-12 p-0 mt-2 pt-3" style="" v-if="!genreListLoaded">
            <GenreFeaturesLoader :speed="2" :animate="true"></GenreFeaturesLoader>
          </div>
          <div @click="goto('local',item.ds_nome_teatro)" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0" style="" v-for="(item, index) in localsList" :key='index' v-else>
            <p>
              <div alt="image" class="img-fluid rounded card__home" :class="['card__home-' + index]">
                <span class="genre__ingressaria" style="text-transform: uppercase">{{ item.ds_nome_teatro }}</span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </section> -->
  
    <!-- Filtro de cidade -->
  <!-- <div style="background-color: #f3f3f3 !important">
    <div class="container-fluid container__select pb-0" style="border-bottom: 1px solid #e3e3e3" v-if="siteName == 'ingressoparatodos.com.br'">
      <div class="row pb-0">
        <div class="seleciona-cidade col-6 text-right select__city" style="border-right: 1px solid #e3e3e3;">
          <model-select :options="options" v-model="item" placeholder="Selecionar Cidade" @input="handleOnInput">
          </model-select>
        </div>
        <div style="color: #000 !important" class="col-6">
          <datepicker :clear-button=true :language="ptBR" @selected="selectDate" @cleared="resetEvents" placeholder="Data" :bootstrap-styling=true clear-button-icon="fa fa-sm fa-times-circle"></datepicker>
        </div>
      </div>
    </div>
    <div class="p-2 container-fluid container__select" v-else></div>
  </div>  -->
    <!-- Fim filtro de cidade -->
  
  <!-- Filtro Categoria -->
    <section class="features container-filtros-categorias" tyle="background: white" data-block-type="features" data-id="3" id="features" v-if="siteName == 'ingressoparatodos.com.br'">
        <div class="container">
          <div class="box-categoria row text-left pt-1 pb-1">
            <div class="col-12 p-0 mt-2 pt-3" style="" v-if="!genreListLoaded">
              <GenreFeaturesLoader :speed="2" :animate="true"></GenreFeaturesLoader>
            </div>
            <!-- <div @click="goto('genre',item.genreName)" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0" style="" v-for="(item, index) in genreList" :key='index' v-else>
              <p>
                <div alt="image" class="img-fluid rounded card__home" :class="['card__home-' + index]">
                  <span class="genre__ingressaria" style="text-transform: uppercase">{{ item.genreName }}</span>
                </div>
              </p>
            </div> -->
          </div>
        </div>
        <!-- Botão Fixado -->
         <div class="container">
           <div class="box-categoria row text-left pb-3">
            <div style="display: inline-block" onclick="window.location='/busca/genero/INFANTIL'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/infantil_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">infantil</span>
              </div>
              </p>
            </div>
           <div style="display: inline-block" onclick="window.location='/busca/genero/ADULTO'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/teatro_adulto_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">teatro adulto</span>
              </div>
              </p>
            </div>
            <div style="display: inline-block" onclick="window.location='/busca/genero/STAND%20UP'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/microfone_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">stand up</span>
              </div>
              </p>
            </div>
          
            <div style="display: inline-block" onclick="window.location='/busca/genero/festival%20de%20férias'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/festival_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">festival de férias</span>
              </div>
              </p>
            </div>
            <div style="display: inline-block" onclick="window.location='/busca/genero/EVENTOS'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/eventos_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">Eventos</span>
              </div>
              </p>
            </div>
            <div style="display: inline-block" onclick="window.location='/busca/genero/CURSOS#'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/cursos_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">Cursos</span>
              </div>
              </p>
            </div>
            <!-- <div style="display: inline-block" onclick="window.location='/busca/genero/LOCAL#'" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <p>
                <div alt="image" class="img-fluid rounded card__home">
                  <img src="/assets/infantil_icon.png" width="38px" height="38px" class="mr-2"/>
                  <span class="genre__ingressaria" style="text-transform: uppercase">Local</span>
              </div>
              </p>
            </div> -->
            </div>
         </div>
            <!-- Fim Botão Fixado -->
      </section>
      <!-- Fim Filtro Categoria -->
  <SearchByPrice v-if="siteName == 'ingressoparatodos.com.br'"></SearchByPrice>
  
  <!-- mudança aqui BILHETERIA -->
  <div v-if="siteName != 'ingressoparatodos.com.br' && siteName == 'Bilheteria.com.br'">
   <div class="container__select to-block container__features" id="banner" v-if="siteName == 'teatro.bilheteria.com.br' || siteName == 'games.bilheteria.com.br' || siteName == 'vivaparque.bilheteria.com.br' || siteName == 'ingressoparatodos.com.br' || siteName == 'parquedamonica.bilheteria.com.br' || siteName == 'colegiopalmares.bilheteria.com.br' || siteName == 'kidzania.bilheteria.com.br' || siteName == 'amanas.bilheteria.com.br' || siteName == 'cidadedasabelhas.bilheteria.com.br'" >
      <div class="container pt-2 pb-0 text-left">
        <div class="col-12 col-sm-12 text-left mt-2 mb-2">
          <h3 v-if="siteName == 'games.bilheteria.com.br'" class="">Explore nossos jogos <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
          <h3 v-if="siteName != 'games.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'" class="">Explore nossas categorias <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
          <p v-if="siteName == 'games.bilheteria.com.br'" class="mt-3 mb-0 pb-0">Descubra jogos através das categorias mais procuradas</p>
          <p v-if="siteName != 'games.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'" class="mt-3 mb-0 pb-0">Descubra eventos através das categorias mais procuradas</p>
  
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-genres" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-genres" slot="button-next"></div>
          </div>
        </div>
        <card-genre-list-ingressaria :genreList="genreList" :swiperOption="swiperOptionGenres"></card-genre-list-ingressaria>
      </div>
    </div>
  </div>
  
  <!-- fim mudança aqui BILHETERIA -->
  <section :class="classContainerBackground">
    <!-- Banner -->
    <div v-if="siteName != 'live.bilheteria.com.br' && siteName != 'www.apetesp.org.br' && siteName != 'Bilheteria.com.br'" class="container__select to-block container__features" :style="stylesBackground" id="banner">
      <div class="container pt-2 pb-0 text-left">
        <div v-if="siteName == 'ingressoparatodos.com.br'" class="col text-center" style="height: 35px; display: flex; flex-direction: row; justify-content: center;">
          <div class="" style="background: #be9650; border-radius: 3px;">
            <h3 style="color: #78040A">DESTAQUES DA SEMANA</h3>
          </div>
        </div>
        <h3 v-if="siteName == 'games.bilheteria.com.br'" class="">Jogos em destaque</h3>
        <h3 v-if="siteName != 'games.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'" class="dest-xp">Experiências em destaque</h3>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'games.bilheteria.com.br'">Uma seleção de jogos para você</p>
        <p class="mt-3 col text-center"  v-if="filteredData.length > 0 && siteName == 'ingressoparatodos.com.br'">Uma seleção de espetáculos para você</p>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br' && siteName != 'www.apetesp.org.br'">Uma seleção de eventos para você</p>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'www.apetesp.org.br'" style="color: #fff">Uma seleção de eventos para você</p>
        <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'live.bilheteria.com.br'">Uma seleção de lives para você</p>
          <div class="container__arrows">
            <p v-if="filteredData && filteredData.length > 0 && siteName === 'demo.bilheteria.com.br'"
              class="mb-0 pb-0"
              style="height: 44px;margin-top: -10px;margin-right: 8px;">
              Eventos disponíveis: {{filteredData.length}}
            </p>
            <div class="swiper-button-prev swiper-button-prev-featured" :class="classFilterWhite" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-featured" :class="classFilterWhite" slot="button-next"></div>
          </div>
          <card-event-featured :bannerEvents="bannerEvents" :swiperOption="swiperOption"></card-event-featured>
      </div>
    </div>
    <div class="container__select to-block container__features" id="banner" v-if="cityList.length > 1 && siteName != 'Bilheteria.com.br' && siteName != 'teatro.bilheteria.com.br' && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'vivaparque.bilheteria.com.br' && siteName != 'parquedamonica.bilheteria.com.br' && siteName != 'colegiopalmares.bilheteria.com.br' && siteName != 'kidzania.bilheteria.com.br' && siteName != 'amanas.bilheteria.com.br' && siteName != 'cidadedasabelhas.bilheteria.com.br'">
      <div class="container pt-2 pb-0 text-left">
        <h3 class="">Muito mais para você</h3>
  
        <div class="container__arrows">
          <div class="swiper-button-prev swiper-button-prev-cities" :class="classFilterWhite" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-next-cities" :class="classFilterWhite" slot="button-next"></div>
        </div>
        <card-cities :bannerEvents="bannerEvents" :swiperOption="swiperOptionCities"></card-cities>
      </div>
    </div>
  
    <!-- Genêros em destaque -->
    <div class="container__select to-block container__features" id="banner" v-if="siteName != 'Bilheteria.com.br' && siteName != 'teatro.bilheteria.com.br' && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'vivaparque.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br' && siteName != 'parquedamonica.bilheteria.com.br' && siteName != 'colegiopalmares.bilheteria.com.br' && siteName != 'kidzania.bilheteria.com.br' && siteName != 'amanas.bilheteria.com.br' && siteName != 'cidadedasabelhas.bilheteria.com.br'">
      <div class="container pt-2 pb-0 text-left">
        <div class="col-12 col-sm-12 text-left mt-2 mb-2">
          <h3 class="cat-explore">Explore nossas categorias <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
          <p class="mt-3 mb-0 pb-0" v-if="siteName != 'www.apetesp.org.br'">Descubra eventos através das categorias mais procuradas</p>
          <p class="mt-3 mb-0 pb-0" v-if="siteName == 'www.apetesp.org.br'" style="color: #fff">Descubra eventos através das categorias mais procuradas</p>
  
          <div class="container__arrows">
            <div class="swiper-button-prev swiper-button-prev-genres" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-next-genres" slot="button-next"></div>
          </div>
        </div>
        <card-genre-list-ingressaria :genreList="genreList" :swiperOption="swiperOptionGenres"></card-genre-list-ingressaria>
      </div>
    </div>
  
    <section v-if="siteName !== 'Bilheteria.com.br' && partnerInfo.show_partner_info == 0" 
    class="to-block team-1 mt-0 pt-0" 
    id="events" 
    :style="customBrackgroundGames()">
      <div class="container">
        <div class="row row__events">
          <div id="proximos-eventos" class="col-12 col-sm-12 text-left mt-2 mb-2" 
          :style="{'margin-top': siteName === 'games.bilheteria.com.br' && !isMobile ? '200px !important' : ''}">
            <h3 v-if="siteName == 'games.bilheteria.com.br'" class="">Jogos <span style="margin-top: 30px;font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            <div v-if="siteName == 'ingressoparatodos.com.br'" class="col text-center" style="height: 35px; display: flex; flex-direction: row; justify-content: center;">
              <div class="" style="background: #be9650; border-radius: 3px;">
                <h3 style="color: #78040A">EM CARTAZ <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
              </div>
            </div>
            <!-- <h3 v-if="siteName == 'ingressoparatodos.com.br'" class="" style="margin-left: auto !important; margin-right: auto !important; width: 7em !important;">EM CARTAZ <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3> -->
            <h3 v-if="siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br'" class="next-event">Próximos eventos <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
            <!-- <h3 v-if="siteName == 'live.bilheteria.com.br'" class="">TEATROS AO VIVO <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3> -->
            <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'games.bilheteria.com.br'">Encontre um jogo com toda a facilidade que você precisa</p>
            <p class="mt-3 col text-center" v-if="filteredData.length > 0 && siteName == 'ingressoparatodos.com.br'">Encontre um evento com toda a facilidade que você precisa</p>
            <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName != 'games.bilheteria.com.br' && siteName != 'live.bilheteria.com.br' && siteName != 'ingressoparatodos.com.br' && siteName != 'www.apetesp.org.br'">Encontre um evento com toda a facilidade que você precisa</p>
            <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'www.apetesp.org.br'" style="color:#fff">Encontre um evento com toda a facilidade que você precisa</p>
            <!-- <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0 && siteName == 'live.bilheteria.com.br'">Selecione o espetáculo ao vivo e valide o seu acesso gratuito ou pago.</p> -->
            <section class="features" style="background: white" data-block-type="features" data-id="3" id="features"      v-if="siteName == 'live.bilheteria.com.br'">
              <!-- <div class="container">
                <div class="row text-left pt-1 pb-1"> -->
                <!-- <div class="col-12 col-sm-12 text-left mt-2 mb-2">
                <h3 class="">Nossos teatros <span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
                <p class="mt-3 mb-0 pb-0">Descubra eventos específicos de cada teatro</p>
                </div> -->
                    <!-- <div class="col-12 p-0 mt-2 pt-3" style="" v-if="!genreListLoaded">
                      <GenreFeaturesLoader :speed="2" :animate="true"></GenreFeaturesLoader>
                    </div>
                    <div @click="goto('local',item.ds_nome_teatro)" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0" style="" v-for="(item, index) in localsList" :key='index' v-else>
                      <p>
                      <div alt="image" class="img-fluid rounded card__home" :class="['card__home-' + index]">
                        <span class="genre__ingressaria" style="text-transform: uppercase">{{ item.ds_nome_teatro }}</span>
                      </div>
                    </p>
                    </div>
                    </div> -->
              <!-- </div> -->
            </section>
          </div>
          <p style="font-size: 16px; font-weight: bold" class="mt-3" v-if="filteredData.length == 0">{{filteredData.length == 0 ? 'Nenhum evento encontrado' : ''}}</p>
  
          <card-event v-for="(item, index) in computedFilteredData" :key='index' :item="item"></card-event>
        </div>
      </div>
    </section>
    <section v-if="siteName === 'Bilheteria.com.br' && partnerInfo.show_partner_info == 0" class="to-block team-1 mt-0 pt-0" id="events">
          <div class="container">
            <div class="row row__events">
              <div class="titulo-eventos col-12 col-sm-12 text-left mt-2 mb-2">
                <h3 class="">Próximos eventos {{item.id_base}}<span style="font-size: 18.5px; color: #777;">{{searchTerm !== "" ? "(" + searchTerm + ")" : ""}}</span></h3>
                <p class="mt-3 mb-0 pb-0" v-if="filteredData.length > 0">Encontre um evento com toda a facilidade que você precisa</p>
              </div>
              <p style="font-size: 16px; font-weight: bold" class="mt-3" v-if="filteredData.length == 0">{{filteredData.length == 0 ? 'Nenhum evento encontrado' : ''}}</p>
              <card-genre-swiper :filteredData="filteredData" :getGenreNames="getGenreNames"></card-genre-swiper>
            </div>
          </div>
        </section>
  </section>
  
    <whats-app v-if="siteName == 'ingressoparatodos.com.br'" :siteName="siteName" :redirectUrl="'https://wa.me/551133848045'"></whats-app>
  </div>
  </template>
  
  <script>
  import $ from "jquery";
  import Logo from "@/components/App-logo.vue";
  import { func } from "@/functions";
  
  import WhatsApp from '../../WhatsApp.vue'
  import { ModelSelect } from "vue-search-select";
  import { serverBus } from "@/main";
  
  import AppSearch from "@/components/App-search.vue";
  import config from "@/config";
  // import CarrouselLoader from '@/components/loaders/CarrouselLoader.vue';
  import GenreFeaturesLoader from "@/components/loaders/GenreFeaturesLoader.vue";
  // import CarrouselTextLoader from '@/components/loaders/CarrouselTextLoader.vue';
  
  import CardEvent from "@/components/Card-event.vue";
  import SearchByPrice from "@/components/Search-by-price.vue"
  import CardGenreSwiper from "@/components/Card-genre-swiper.vue";
  import CardGenreList from "@/components/Card-genreList.vue";
  import CardGenreListIngressaria from "@/components/Card-genreListIngressaria.vue";
  import CardPreferredPartners from "@/components/Card-preferred-partners.vue";
  
  import CardCityList from "@/components/Card-cityList.vue";
  import CardCities from "@/components/Card-cities.vue";
  import CardEventFeatured from "@/components/Card-eventFeatured.vue";
  import BannerSlide from "@/components/Banner-slide.vue";
  import VueAwesomeSwiper from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  import { eventService } from "@/components/common/services/event";
  import { discoveryService } from "@/components/common/services/discovery";
  import Datepicker from "vuejs-datepicker";
  import { ptBR } from "vuejs-datepicker/dist/locale";
  // CARROSSEL
  import { VueperSlides, VueperSlide } from "vueperslides";
  import "vueperslides/dist/vueperslides.css";
  //CARROSSEL
  
  const partnersList = require.context('@/assets/partners')
  import { partnerService } from "@/components/common/services/partner";
  
  export default {
    name: "Events",
    mixins: [func],
    data() {
      return {
        desc: "<h1>Teste</h1>",
        siteName: config.info.siteName,
        discoveryBanner: "",
        discoveryBannerAnuncio: "",
        discoveryBannerEstreia: "",
        slideLoaded: false,
        genreListLoaded: false,
        discovery: [],
        slideData: [],
        ptBR: ptBR,
        format: "yyyy MM dd",
        localsList: [],
        genreList: [],
        date: "",
        nextEvents: [],
        bannerEvents: [],
        filteredData: [],
        preferredPartners: [],
        searchTerm: "",
        options: [],
        dateValue: "",
        optionsDate: [],
        item: {
          value: "",
          text: "",
        },
        itemDate: {
          value: "",
          text: "",
        },
        swiperOption: {
          // loop: true,
          autoplay: true,
          // speed: 1000,
          // loopedSlides: 1,
          slidesPerView: 2,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-featured",
            prevEl: ".swiper-button-prev-featured",
          },
          breakpoints: {
            800: {
              slidesPerView: 1,
            },
          },
        },
        swiperPreferredPartner: {
          // loop: true,
          autoplay: true,
          // speed: 1000,
          // loopedSlides: 1,
          slidesPerView: 4,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-preferred-partner",
            prevEl: ".swiper-button-prev-preferred-partner",
          },
          breakpoints: {
            800: {
              slidesPerView: 1,
            },
          },
        },
        swiperOptionGenresWithoutImages: {
          autoplay: false,
          slidesPerView: 8,
          slidesPerGroup: 2,
          rewind: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-genres-without-text",
            prevEl: ".swiper-button-prev-genres-without-text",
          },
          breakpoints: {
            800: {
              slidesPerView: 4,
            },
            600: {
              slidesPerView: 3,
            }
          }
        },
        swiperOptionGenres: {
          // loop: true,
          autoplay: true,
          // speed: 000,
          // loopedSlides: 1,
          slidesPerView: 3,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-genres",
            prevEl: ".swiper-button-prev-genres",
          },
          breakpoints: {
            800: {
              slidesPerView: 1,
            },
          },
        },
        swiperOptionCategorys: {
          // loop: true,
          autoplay: true,
          // speed: 000,
          // loopedSlides: 1,
          slidesPerView: 3,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-categorys",
            prevEl: ".swiper-button-prev-categorys",
          },
          breakpoints: {
            800: {
              slidesPerView: 1,
            },
          },
        },
        // CARROSSEL INGRESSOPARATODOS
        slides: [
          // {
          //   titleCarrossel: "",
          //   contentCarrossel: "",
          //   imageCarrossel: "/assets/bannermasteringressoparatodos.jpeg",
          // },
  
          {
            titleCarrossel: "",
            contentCarrossel: "",
            imageCarrossel: "/assets/marquee.gif",
          },
          //  {
          //   titleCarrossel: "",
          //   contentCarrossel: "",
          //   imageCarrossel:
          //     "./assets/bannerprincipal.png",
          // },
          // {
          //   titleCarrossel: 'slide-3',
          //   contentCarrossel: 'laga',
          //   imageCarrossel: 'https://images.pexels.com/photos/355241/pexels-photo-355241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
          // }
        ],
        // FIM CARROSSEL INGRESSOPARATODOS
  
        // CARROSSEL APETESP
        // slidess: [
        //   {
        //     titleCarrossel: " ",
        //     contentCarrossel: "",
        //     imageCarrossel:
        //       "https://www.ingressoparatodos.com.br/assets/bannerapetesp.jpg",
        //   },
  
        //   {
        //     titleCarrossel: " ",
        //     contentCarrossel: "",
        //     imageCarrossel:
        //       "https://www.ingressoparatodos.com.br/assets/bannerapetesp.jpeg ",
        //   },
        //   {
        //     titleCarrossel: 'slide-3',
        //     contentCarrossel: '',
        //     imageCarrossel: 'https://images.pexels.com/photos/355241/pexels-photo-355241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
        //   }
        // ],
        // FIM CARROSSEL APETESP
  
        // CARROSSEL BILHETERIA ESPORTIVA
        slideb: [
          {
            titleCarrossel: " ",
            contentCarrossel: "",
            imageCarrossel:
              "https://www.ingressoparatodos.com.br/assets/bannerbilheteriaesportiva1.png",
          },
  
          {
            titleCarrossel: " ",
            contentCarrossel: "",
            imageCarrossel:
              "https://www.ingressoparatodos.com.br/assets/bannerbilheteriaesportiva2.png",
          },
        ],
        // FIM CARROSSEL BILHETERIA ESPORTIVA
  
        swiperOptionCities: {
          // loop: true,
          // autoplay: true,
          // speed: 1000,
          // loopedSlides: 1,
          slidesPerView: 4,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next-cities",
            prevEl: ".swiper-button-prev-cities",
          },
          breakpoints: {
            800: {
              slidesPerView: 1,
            },
          },
        },
        swiperOptionBanner: {
              //loop: true,
              autoplay: true,
              speed: 1000,
              //loopedSlides: 1,
              slidesPerView: 1,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".swiper-button-next-banner",
                prevEl: ".swiper-button-prev-banner",
              },
              breakpoints: {
                800: {
                  slidesPerView: 1,
                },
              },
            },
            partnerInfo: ''
      };
    },
    components: {
      CardPreferredPartners,
      CardGenreSwiper,
      // CARROSSEL
      VueperSlides,
      VueperSlide,
      WhatsApp,
      // CARROSSEL
      ModelSelect,
      AppSearch,
      // CarrouselLoader,
      // CarrouselTextLoader,
      CardEvent,
      BannerSlide,
      GenreFeaturesLoader,
      CardGenreList,
      CardGenreListIngressaria,
      CardCityList,
      Datepicker,
      CardCities,
      CardEventFeatured,
      SearchByPrice
    },
  
    methods: {
      redirectTo (to) {
        this.$router.push({name: 'search_bycategory', params: {input: to}})
      },
      shuffle(array) {
        var currentIndex = array.length,
          temporaryValue,
          randomIndex;
  
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
  
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
  
        return array;
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      reset() {
        this.item = {};
      },
      customBrackgroundGames() {
        if (this.siteName === 'games.bilheteria.com.br' && !this.isMobile) {
          return {
            //https://img.hype.games/cdn/47972faff7e64850b69568daa301653d.png
            //https://iili.io/HSCCnNR.png
            //https://iili.io/HSnMgyJ.png
            'background-image': "url(/assets/game-background.png) !important",
            'background-repeat': 'no-repeat !important',
            'background-color': '#000 !important',
            'background-size': 'contain !important'
            
          }
        }
        return this.stylesBackground
      },
      selectFromParentComponent1() {
        // select option from parent component
        this.item = this.options[0];
      },
      clearDate() {
        // console.log(this.dateValue);
      },
  
      next() {},
      prev() {},
      reInit() {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$nextTick(() => {
          //this.$refs.slick.reSlick();
        });
      },
      search(type, item) {
        this.$router.push("/busca/" + type + "/" + item);
      },
      goto(type, item) {
        if (item.notselectable != undefined && item.notselectable == 1) return;
  
        let where = item.type != undefined ? item.type : type;
  
        switch (where) {
          case "local":
            this.$router.push("/busca/local/" + item);
            break;
          case "genre":
            this.$router.push("/busca/genero/" + item);
            break;
          case "city":
            this.$router.push("/busca/cidade/" + item);
            break;
          case "state":
            this.$router.push("/busca/estado/" + item);
            break;
          case "event":
            this.$router.push(item.uri);
            break;
          case "discovery":
            window.location.href = item;
            break;
        }
      },
  
      selectDate(data) {
        // console.log(data);
        if (data == "1970-01-01") return;
        if (data == null) return;
  
        this.date = new Date(data).toISOString().split("T")[0];
  
        this.refreshEffect();
  
        this.getListResultsFiltered();
      },
      refreshEffect() {
        var banner = document.getElementById("banner");
        var features = document.getElementById("features");
        var events = document.getElementById("events");
  
        $("#banner").fadeOut();
        $("#features").fadeOut();
        $("#events").fadeOut();
  
        $("#banner").fadeIn();
        $("#features").fadeIn();
        $("#events").fadeIn();
      },
      handleOnInput($event) {
        this.item = $event;
  
        if (this.item.value == "all") {
          // this.filteredData = this.slideData;
          this.searchTerm = "";
          // return;
        } else {
          this.searchTerm = this.item.text;
        }
  
        this.refreshEffect();
  
        // console.log(this.searchTerm);
        this.getListResultsFiltered();
      },
      resetEvents() {
        this.date = "";
  
        this.getListResultsFiltered();
      },
      getListResultsFiltered() {
        // console.log('aqui', this.searchTerm);
        eventService
          .list(this.searchTerm, this.locale.state.name, this.date)
          .then(
            (response) => {
              this.filteredData = response;
              this.hideWaitAboveAll();
            },
            (error) => {
              this.hideWaitAboveAll();
              this.toastError("Falha na execução.");
            }
          );
      },
      getListResultAgain() {
        eventService.list(this.locale.city.name, this.locale.state.name).then(
          (response) => {
            this.slideData = response.filter(
              (x) => x.id_genre !== undefined && x.id_genre !== null
            );
            this.hideWaitAboveAll();
          },
          (error) => {
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      },
      removeDuplicatesBy(keyFn, array) {
        var mySet = new Set();
        return array.filter(function (x) {
          var key = keyFn(x).toUpperCase(),
            isNew = !mySet.has(key);
          if (isNew) mySet.add(key);
          return isNew;
        });
      },
      getGenreList() {
        let temp = this.removeDuplicatesBy((x) => x.genreName, this.filteredData);
        temp.push({id_genre: '0002', genreName: 'PROMOÇÕES'})
        this.genreList = this.shuffle(temp);
  
        this.genreListLoaded = true;
      },
      getLocalsList() {
        this.localsList = this.removeDuplicatesBy(
          (x) => x.ds_nome_teatro,
          this.filteredData
        ).slice(0, 6);
  
        // console.log(this.localsList);
      },
      getNextEvents() {
        this.nextEvents = this.slideData.slice(1, 5);
      },
      populateCityPicker() {
        this.options.push({
          value: "all",
          text: "Todas as Cidades",
        });
        this.cityList.map((x) => {
          this.options.push({
            value: x.ds_municipio,
            text: x.ds_municipio.toLowerCase().replace(/^\w/, (x) => {
              return x.toUpperCase();
            }),
          });
        });
      },
      getBanner() {
        eventService.banner(this.locale.city.name, this.locale.state.name).then(
          (response) => {
            this.bannerEvents = response;
            this.slideLoaded = true;
          },
          (error) => {
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      },
      getDiscovery() {
        // console.log(this.key);
        discoveryService.list(this.key).then(
          (response) => {
            // console.log(response);
            this.discovery = response.filter(
              (x) =>
                x.type !== "banner" &&
                x.type !== "anuncio" &&
                x.type !== "estreia"
            );
            this.discoveryBanner = response.filter((x) => x.type == "banner");
            this.discoveryBannerAnuncio = response.filter(
              (x) => x.type == "anuncio"
            );
            this.discoveryBannerEstreia = response.filter(
              (x) => x.type == "estreia"
            );
  
            response.forEach(x => {
              if (x.type && x.type === 'card') {
                x.img = x.imageURI
                x.genreName = 'PROMOÇÕES'
                x.id_genre = '0002'
                x.datas = ''
                x.ds_evento = x.title
                this.slideData.push(x)
              }
            });
            // console.log(this.discoveryBanner);
          },
          (error) => {
            this.toastError("Falha na execução.");
          }
        );
      },
      getListResults(callback) {
        // this.getLocation(this.getListResultAgain);
  
        eventService.list(this.locale.city.name, this.locale.state.name).then(
          (response) => {
            this.slideData = response.filter(
              (x) => x.id_genre !== undefined && x.id_genre !== null
            );
  
            this.filteredData = this.slideData;
  
            this.hideWaitAboveAll();
            this.isLoaded = true;
  
            this.getGenreList();
            this.getLocalsList();
            this.getNextEvents();
  
            this.populateCityPicker();
  
            // console.log(this.slideData);
  
            if (callback !== null && callback !== undefined) {
              callback();
            }
          },
          (error) => {
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
      },
      scrollToTop() {
        // When arrow is clicked
        $("body,html").animate(
          {
            scrollTop: 0, // Scroll to top of body
          },
          500
        );
      },
      getPartnerInfo() {
        partnerService.getinfo().then(
          (response) => {
            if (response.error) {
              console.log('responseerro', response.error)
            }
            if (this.validateJSON(response)) {
              this.partnerInfo = response
            }
          }
        )
      },
    },
    mounted() {
      if (this.siteName === 'Bilheteria.com.br' && partnersList && partnersList.keys().length > 0) {
        partnersList.keys().forEach(partner => {
          const imagePath = partner.split('./')[1]
          this.preferredPartners.push({img: require('@/assets/partners/' + imagePath)})
        })
      }
      this.getPartnerInfo();
    },
    computed: {
      isMobile() {
        return window.innerWidth <= 760;
      },
      getGenreNames() {
        return [...new Set(this.genreList.map(item => item.genreName))].sort()
      },
      categoriesList() {
        return this.$store.state.categoriesList
      },
      cityList() {
        return this.$store.state.cityList;
      },
      classFilterWhite() {
        if (this.siteName == "ingressoparatodos.com.br") return "filter-white";
        return "";
      },
      classContainerBackground() {
        if (this.siteName == "ingressoparatodos.com.br") {
          return "container-background";
        }
        if (this.siteName == "demo.bilheteria.com.br") {
          return "container__select";
        }
        return "";
      },
      stylesBackground() {
        if (this.siteName == "ingressoparatodos.com.br")
          return "background: transparent !important;";
        return "";
      },
      computedFilteredData() {
        let ret = this.filteredData;
        this.discovery.map((x) => {
          const findedItem = ret.findIndex(item => item.id === x.id)
          if (findedItem < 0) {
            ret.push(x);
          }
          // filtro antigo, removi pq ele gerava evento duplicado
          // if (x.index == 0) {
          //   const j = Math.floor(Math.random() * ret.length);
          //   ret.splice(j, 0, x);
          // } else {
          //   if (x.index > ret.length) {
          //     ret.push(x);
          //   } else {
          //     ret.splice(x.index, 0, x);
          //   }
          // }
        });
  
        // console.log(ret);
  
        return ret;
      },
    },
    created() {
      this.getListResults(this.getDiscovery);
      this.getBanner();
  
      // Fixa navbar ao ultrapassa-lo
      var navbar = $("#navbar"),
        distance = navbar.offset().top,
        $window = $(window);
  
      $window.scroll(function () {
        if ($window.scrollTop() >= 140) {
          navbar.removeClass("navbar-fixed-top").addClass("navbar-fixed-top");
        } else {
          navbar.removeClass("navbar-fixed-top");
        }
  
        if ($window.scrollTop() > 400) {
          $("#btn__scroll").css("opacity", 1);
        } else {
          $("#btn__scroll").css("opacity", 0);
        }
      });
    },
    beforeUpdate() {
      //if (this.$refs.slick) {
      //this.$refs.slick.destroy();
      //}
    },
    updated() {
      //if (this.$refs.slick && !this.$refs.slick.$el.classList.contains('slick-initialized')) {
      //this.$refs.slick.create();
      //}
    },
    filters: {
      truncate: function (text, length, clamp) {
        clamp = clamp || "...";
        var node = document.createElement("div");
        node.innerHTML = text;
        var content = node.textContent;
        return content.length > length
          ? content.slice(0, length) + clamp
          : content;
      },
      replace: function (message, toReplace, replacement) {
        return message.replace(toReplace, replacement);
      },
      capitalize: function (value) {
        if (!value) return "";
  
        return value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      },
    },
  };
  </script>
  
  <style lang="scss">
  .select__city {
    .dropdown {
      width: 140px;
      float: right;
    }
  }
  
  /* .swal2-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 200px !important;
    margin: 105px 5px 5px 5px !important;
  }
  
  .swal2-popup {
    width: 40% !important;
    position: relative !important;
    margin: auto !important;
    text-transform: uppercase !important;
    height: 200px !important;
  
    @media only screen and (max-width: 600px) {
      width: 90% !important;
    }
  } */
  
  .swal2-container.swal2-top-end.swal2-fade.swal2-shown {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 200px !important;
    margin: 105px 5px 5px 5px !important;
  }
  
  .swal2-popup.swal2-toast.alert__tixsme.alert_error.swal2-show {
    width: 40% !important;
    position: relative !important;
    margin: auto !important;
    text-transform: uppercase !important;
    height: 200px !important;
  
    @media only screen and (max-width: 600px) {
      width: 90% !important;
    }
  }
  
  .swal2-popup.swal2-toast.alert__tixsme.alert__sucess.swal2-show {
    width: 40% !important;
    position: relative !important;
    margin: auto !important;
    text-transform: uppercase !important;
    height: 200px !important;
  
    @media only screen and (max-width: 600px) {
      width: 90% !important;
    }
  }
  .categories-title-container {
      display: flex;justify-content: space-between;
    }
  
  @media screen and (max-width: 929px) {
      .categories-title-container {
        display: block !important;
      }
  }
  
  @media screen and (max-width: 515px) {
    .container.home-bilheteria {
      top: 0px !important;
      .categories-title-container {
        h3 {
          font-size: 17px;
        }
        p {
          font-size: 15px;
        }
      }
      .items-home {
        flex-direction: column;
        height: 60px;
        overflow: auto;
        .shop_button_event {
          width: auto !important;
          font-size: 9px !important;
          height: 35px !important;
          padding: 5px !important;
          margin: 5px 5px !important;
          font-weight: bold;
        }
      }
    }
  }
  </style>
  