var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getCorporate.id)?_c('div',{key:_vm.defaultBannerKey,staticClass:"container-fluid container__select"},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-12 text-left"},[_c('div',{staticClass:"p-3"},[_c('div',{},[_c('div',{staticClass:"mainBannerContainer",staticStyle:{"background-image":"url('../../../assets/corporate-banner-default.jpeg')"}},[_c('div',{staticClass:"mainBannerTextsContainer"},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-12 text-left"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
                          _vm.getCorporate.imageURIOriginal
                        ),expression:"\n                          getCorporate.imageURIOriginal\n                        ",arg:"background-image"}],staticClass:"mainBannerBackgroundlogo p3"}),_c('p',{staticClass:"mainBannerTitle"},[_vm._v("\n                        TEMOS UMA NOVIDADE PARA VOCÊ!\n                      ")]),_c('p',{staticClass:"mainBannerDescription"},[_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.getCorporate.messagepromo.replace(
                              /(?:\r\n|\r|\n)/g,
                              '<br>'
                            )
                          )}})])])])])])]),_c('div',{staticClass:"mainBannerCompanyContainer"},[_c('div',{staticClass:"container p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-12 text-left"},[_c('h3',{staticClass:"mainBannerCompanyName"},[_vm._v("\n                      "+_vm._s(_vm.getCorporate.name)+"\n                    ")])])])])])])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }