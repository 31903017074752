import { render, staticRenderFns } from "./App-resetpass.vue?vue&type=template&id=2c757232&scoped=true&"
import script from "./App-resetpass.vue?vue&type=script&lang=js&"
export * from "./App-resetpass.vue?vue&type=script&lang=js&"
import style0 from "./App-resetpass.vue?vue&type=style&index=0&id=2c757232&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c757232",
  null
  
)

component.options.__file = "App-resetpass.vue"
export default component.exports