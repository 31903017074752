<template>
      <section class="features container-filtros-categorias" tyle="background: white" data-block-type="features" data-id="3" id="features">
       <div class="container">
         <div class="box-categoria row text-left pb-3" style="justify-content: center;padding: 7px 0px;">
          <div @click="redirectAndFilter(15)" style="display: inline-block" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <div alt="image" class="img-fluid rounded card__home">
                <img src="/assets/tag_price.png" width="38px" height="38px" class="mr-2"/>
                <span class="genre__ingressaria" style="text-transform: uppercase">Até R$ 15,00</span>
            </div>
          </div>
         <div @click="redirectAndFilter(25)" style="display: inline-block" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <div alt="image" class="img-fluid rounded card__home">
                <img src="/assets/tag_price.png" width="38px" height="38px" class="mr-2"/>
                <span class="genre__ingressaria" style="text-transform: uppercase">Até R$ 25,00</span>
            </div>
          </div>
          <div @click="redirectAndFilter(30)" style="display: inline-block" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <div alt="image" class="img-fluid rounded card__home">
                <img src="/assets/tag_price.png" width="38px" height="38px" class="mr-2"/>
                <span class="genre__ingressaria" style="text-transform: uppercase">Até R$ 30,00</span>
            </div>
          </div>
        
          <div @click="redirectAndFilter(50)" style="display: inline-block" class="col-6 col-md-2 col-sm-2 p-0 card__container mt-0">
              <div alt="image" class="img-fluid rounded card__home">
                <img src="/assets/tag_price.png" width="38px" height="38px" class="mr-2"/>
                <span class="genre__ingressaria" style="text-transform: uppercase">Até R$ 50,00</span>
            </div>
          </div>
          </div>
       </div>
    </section>
</template>
<script>

export default{
    name: 'SearchByPrice',
    methods: {
        redirectAndFilter (amount) {
            this.$router.push({
                name: 'search',
                params: {
                input: null
                },
                query: {
                id_genre: null,
                id_municipio: null,
                date_when: null,
                amount: amount
                }
            })
        }
    }
}
</script>
