<template>
  <div class="container-modal-register-circo">
    <div class="content-register-cdb" :style="{'padding': screen > 500 ? '40px 60px !important' : '8px !important'}">
      <span class="material-symbols-outlined close-icon-small-register" @click="$emit('close-modal-register')">close</span>
      <div class="header-register-cdb">
        <!-- <h4 class="name-register-cdb">{{ name }}</h4> -->
        <h2 class="title-register-cdb">RESPONSÁVEL PELO CIRCO</h2>
      </div>
      <div class="form-register-cdb">
        <input type="text" class="form-register-input-user" v-model="formRegister.name_circo" placeholder="Nome do circo">
        <input type="text" class="form-register-input-user" v-model="formRegister.cnpj_circo" placeholder="CNPJ do circo">
        <div class="form-register-content-checkbox-cdb">
          <label for="owner-checkbox" class="content-checkbox-cdb">
            <input
              type="checkbox"
              class="checkbox-cdb-select"
              name="owner-checkbox"
              id="owner-checkbox"
              v-model="isOwner"
              @change="onCheckboxChange('owner')"
              >
            <p class="label-checkbox-cdb">Dono</p>
          </label>
          <label for="admin-checkbox" class="content-checkbox-cdb">
            <input
              type="checkbox"
              class="checkbox-cdb-select"
              name="admin-checkbox"
              id="admin-checkbox"
              v-model="isAdmin"
              @change="onCheckboxChange('admin')"
              >
            <p class="label-checkbox-cdb">Administrador</p>
          </label>
        </div>
        <input type="text" class="form-register-input-user" v-model="formRegister.name" placeholder="Nome">
        <input type="text" class="form-register-input-user" v-model="formRegister.cpf_cnpj" placeholder="CPF/CNPJ">
        <input type="text" class="form-register-input-user" v-model="formRegister.email" placeholder="E-mail">
        <input type="text" class="form-register-input-user" v-model="formRegister.confirm_email" placeholder="Confirme seu e-mail">
        <input type="text" class="form-register-input-user" v-model="formRegister.phone" placeholder="Celular">
        <input type="password" class="form-register-input-user" v-model="formRegister.password" placeholder="Senha">
        <input type="password" class="form-register-input-user" v-model="formRegister.confirm_password" placeholder="Confirmar senha">
      </div>
      <div class="send-info-register" style="flex-direction: column;">
        <button class="btn-send-register" @click="sendInfosCadastro()">Cadastrar</button>
        <p class="label-info-register">Mantenha sua localização SEMPRE atualizada!!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { cdbService } from '@/components/common/services/cdb_service'

export default {
  name: 'ModalRegisterCirco',
  props: ['idCirco', 'name'],
  components: {},
  data () {
    return {
      isOwner: false,
      isAdmin: false,
      formRegister: {
        name_circo: this.name,
        cnpj_circo: '',
        type_owner: '',
        name: '',
        email: '',
        cpf_cnpj: '',
        phone: '',
        password: '',
        confirm_password: '',
        confirm_email: '',
        id: this.idCirco
      }
    }
  },
  mounted () {
  },
  computed: {
    screen () { return screen.availWidth }
  },
  methods: {
    onCheckboxChange(role) {
      if (role === 'owner') {
        this.isOwner = true
        this.isAdmin = false
        this.formRegister.type_owner = 'dono'
      } else if (role === 'admin') {
        this.isAdmin = true
        this.isOwner = false
        this.formRegister.type_owner = 'administrador'
      } else {
        this.isOwner = false
        this.isAdmin = false
        this.formRegister.type_owner = ''
      }
    },
    sendInfosCadastro () {
      if (!Object.values(this.formRegister).every(field => field !== '')) {
        return this.$swal({
          title: 'É necessário informar todos os dados',
          type: 'error',
          showConfirmButton: true,
          timer: 4000,
        })
      } else if (this.formRegister.password !== this.formRegister.confirm_password) {
        return this.$swal({
          title: 'As senhas não conferem',
          type: 'error',
          showConfirmButton: true,
          timer: 4000,
        })
      } else if (this.formRegister.email !== this.formRegister.confirm_email) {
        return this.$swal({
          title: 'A confirmação do e-mail está diferente do e-mail informado',
          type: 'error',
          showConfirmButton: true,
          timer: 4000,
        })
      }

      Object.keys(this.formRegister).forEach(key => {
        if (typeof this.formRegister[key] === 'string') {
          this.formRegister[key] = this.formRegister[key].trim()
        }
      })

      cdbService.sendCircoCadatroResponsavel(this.formRegister).then(
        response => {
          if (response.result==true) {
            this.$swal({
              title: 'Obrigado, registramos sua solicitação e está em análise para aprovação, após aprovado você terá acesso para atualizar as informações.',
              type: 'success',
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
            this.$root.$emit('close-modal-register')
            this.step = 0
          } else {
            this.$swal({
              title: 'Não foi possível gravar os dados',
              type: 'error',
              customClass: 'swal-alert-to-cdb',
              background: '#f4e9da',
              showConfirmButton: false,
              timer: 4000,
            })
          }
        }, error => {
          this.$swal({
            title: 'Ocorreu um erro, tente novamente',
            type: 'error',
            customClass: 'swal-alert-to-cdb',
            background: '#f4e9da',
            showConfirmButton: false,
            timer: 4000,
          })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.container-modal-register-circo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-register-cdb {
    font-family: 'Alegreya Sans', sans-serif;
    padding: 40px 60px !important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
    position: relative;
    z-index: 10;
    width: 750px;
    height: 70%;
    background: #F4E9DA;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    color: #212425;
    .close-icon-small-register {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      color: #BF3D26;
      border: 2px solid #BF3D26;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .header-register-cdb {
      width: 100%;
      height: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .title-register-cdb {
        font-size: 48px;
      }
      .name-register-cdb {
        width: 90%;
        text-align: center;
        font-size: 26px;
        background: #eba943;
        color: #FFFFFF;
        border-radius: 8px;
        padding: 0 10px !important;
      }
    }
    .form-register-cdb {
      width: 90%;
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.3vw;
      .form-register-content-checkbox-cdb {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.3vw;
        .content-checkbox-cdb {
          background: #ffffff;
          width: 50%;
          height: 100%;
          border-radius: 8px;
          padding: 0 0.5vw !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5vw;
          .checkbox-cdb-select {
            transform: scale(1.1);
            cursor: pointer;
          }
          .label-checkbox-cdb {
            font-size: 21px;
            font-family: 'Alegreya Sans', sans-serif;
            color: #898988;
          }
        }
      }
      .form-register-input {
        font-size: 21px;
        font-family: 'Alegreya Sans', sans-serif;
        width: 100%;
        height: 40px;
        margin-bottom: 20px !important;
        padding-left: 10px !important;
        outline: none;
        border-radius: 8px;
        border: none;
      }
      .form-register-input::placeholder {
        color: #898988;
      }
      .form-register-input-user {
        font-size: 21px;
        font-family: 'Alegreya Sans', sans-serif;
        width: 100%;
        height: 40px;
        padding-left: 10px !important;
        outline: none;
        border-radius: 8px;
        border: none;
      }
      .form-register-input-user::placeholder {
        color: #898988;
      }
      .form-register-input-user:last-child {
        margin-bottom: 0.3vw !important;
      }
    }
    .send-info-register {
      width: 90%;
      height: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      .btn-send-register, .btn-register-cdb {
        font-family: 'Alegreya Sans', sans-serif;
        min-width: 200px;
        height: 35px;
        border: none;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
        background: #eba943;
        cursor: pointer;
        color: #FFFFFF;
        outline: none;
      }
      .label-info-register {
        color: black;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-modal-register-circo {
    .content-register-cdb {
      gap: 5px;
      .header-register-cdb {
        height: 10%;
        justify-content: center !important;
        .title-register-cdb {
          font-size: 25px !important;
          margin-bottom: 10px !important;
        }
        .name-register-cdb {
          font-size: 20px !important;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .form-register-cdb {
        height: 70%;
        gap: 4px;
        .form-register-input-user {
          font-size: 16px;
        }
        .form-register-content-checkbox-cdb {
          .content-checkbox-cdb {
            padding: 0 5px !important;
            gap: 5px;
            .label-checkbox-cdb {
              font-size: 16px;
            }
          }
        }
      }
      .send-info-register {
        width: 100% !important;
        height: 20%;
        .btn-send-register, .btn-register-cdb {
          width: 130px !important;
          font-size: 17px !important;
        }
        .label-info-register {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
