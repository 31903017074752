<template>
  <div class="container-circos-cdb">
    <div class="section-circos-cdb">
      <div class="image-header-circos-cdb">
        <img src="./assets/logo_circos_do_brasil.png" alt="">
      </div>
      <div class="count-circos-header-cdb">
        <p class="count-total-cdb">Já somos <strong>{{ circosRegistered.length }}</strong> circos cadastrados</p>
      </div>
      <div class="star-icons-circos-cdb">
        <span class="material-symbols-outlined star-icon-small">star</span>
        <span class="material-symbols-outlined star-icon-large">star</span>
        <span class="material-symbols-outlined star-icon-small">star</span>
      </div>
    </div>
  </div>
</template>

<script>
import { cdbService } from "@/components/common/services/cdb_service"

export default {
  name: 'HeaderCircosDoBrasil',
  components: {},
  props: [],
  data () {
    return {
      showModalContact: false,
      searchCirco: '',
      circosRegistered: ''
    }
  },
  beforeDestroy () {
    this.$root.$off('circos-researched')
    this.$root.$off('open-modal-error-search')
  },
  async mounted () {
    this.circosRegistered = await cdbService.getAllCircos()

    this.$root.$on('go-to-search', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTimeout(() => {
        document.querySelector('.input-search-circo').focus()
      }, 1000)
    })
  },
  computed: { },
  methods: {
    openModalContact () {
      this.$root.$emit('open-modal-contact', true)
    }
  }
}
</script>

<style lang="scss">
.section-circos-cdb {
  background-image: url('./assets/raios.png') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>